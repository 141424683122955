import React from 'react'
import ReactDOM from 'react-dom'
import { DefaultTheme } from 'react-jss'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

import { Button, BUTTON_FILL_TYPE, BUTTON_SIZE, BUTTON_VARIANT } from '../ButtonSMB'
import PopupCloseIcon from '../Icons/PopupCloseIcon'
import useStyles from './styles'
import { IModalSMBProps } from './types'

const Modal = (props: IModalSMBProps & { theme?: DefaultTheme }) => {
  const {
    isOpen,
    closeCallback,
    shouldOutClickClose = false,
    headerContent,
    bodyContent,
    footerContent,
    fullHeight,
    closeButton,
    className,
    hasCloseButton = true,
  } = props

  const classes = useStyles(props)

  const closeHandler = () => {
    if (closeCallback) {
      closeCallback()
    }
  }

  if (!isOpen) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      className={cn(
        classes.root,
        {
          [classes.isOpen]: isOpen,
          [classes.fullHeight]: fullHeight,
        },
        className
      )}
    >
      <OutsideClickHandler onOutsideClick={shouldOutClickClose ? closeHandler : () => null} display="contents">
        <div className={classes.modal}>
          {closeButton ? (
            closeButton
          ) : hasCloseButton ? (
            <Button
              onClick={closeHandler}
              variant={BUTTON_VARIANT.tertiary}
              size={BUTTON_SIZE.icon32}
              fillIconType={BUTTON_FILL_TYPE.stroke}
              iconLeft={<PopupCloseIcon />}
              className={classes.closeButton}
            />
          ) : null}
          {headerContent && <div className={classes.header}>{headerContent}</div>}
          {bodyContent && <div className={classes.body}>{bodyContent}</div>}
          {footerContent && <div className={classes.footer}>{footerContent}</div>}
        </div>
      </OutsideClickHandler>
    </div>,
    document.body
  )
}

export { Modal }
