export enum NotificationsActionTypes {
  SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
  UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION',
  SET_TELEGRAM_CODE = 'SET_TELEGRAM_CODE',
}

export interface INotificationsState {
  readonly subscriptions: ISubscription[]
  readonly code: string
}

export interface ISubscription {
  description: string
  email: boolean
  id: number
  label: string
  required: boolean
  telegram: boolean
  title: string
  slug: string
}

export interface ISubscriptionBodyRequest {
  email?: boolean
  telegram?: boolean
}
