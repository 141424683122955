import { createUseStyles } from 'react-jss'

export default createUseStyles(
  {
    balaceCardWrapper: {
      display: 'flex',
      gap: 8,
    },
  },
  { name: 'Balance' }
)
