import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="5" r="1" fill={fill} />
      <circle cx="7" cy="9" r="1" fill={fill} />
      <circle cx="7" cy="13" r="1" fill={fill} />
      <circle cx="11" cy="5" r="1" fill={fill} />
      <circle cx="11" cy="9" r="1" fill={fill} />
      <circle cx="11" cy="13" r="1" fill={fill} />
    </svg>
  )
}
