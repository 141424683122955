import React, { ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import { IntercomProps } from 'react-use-intercom/dist/types'

import history from '../../store/history'
import Aside from './components/Aside'
import Content from './components/Content'
import GlobalStyle from './globalStyle'
import selector from './selector'
import { PageWrapper, PreviewWrapper } from './styled'

import { Routes } from '@/const/routes'
import { getDefaultLanguage } from '@/locales/helpers'
import { openAppModal } from '@/store/app'
import { MODALS } from '@/store/app/types'
import { getIntercomProfile, setUserLanguage } from '@/store/user'
import { IIntercomProfile } from '@/store/user/types'

const App = () => {
  const { user, app, shouldShowBusinessModal } = useSelector(selector)
  const { update } = useIntercom()
  const dispatch = useDispatch()
  const defaultLanguage = getDefaultLanguage()

  useEffect(() => {
    const winw = window as any
    if (!user.isAuthorized) {
      dispatch(setUserLanguage(defaultLanguage))
    }
    // Fire event for GTM
    if (winw.dataLayer?.push && user.ga_user_id) {
      winw.dataLayer?.push({
        event: 'ym-user-id',
        userId: user.ga_user_id,
      })
    }

    window.addEventListener('resize', calcAppHeight)
    calcAppHeight()

    return () => {
      window.removeEventListener('resize', calcAppHeight)
    }
  }, [])

  useEffect(() => {
    if (user.isAuthorized) {
      dispatch(getIntercomProfile())
    }

    if (shouldShowBusinessModal) {
      dispatch(openAppModal({ name: MODALS.BUSINESS_PROFILE }))
    }
  }, [user.isAuthorized])

  useEffect(() => {
    if (user.intercom) {
      const attributes = Object.keys(user.intercom).reduce(
        (acc, key) => ({
          ...acc,
          [key]: (user.intercom as any)[key] || 'None',
        }),

        {} as IIntercomProfile
      )

      const intercomBootProps: IntercomProps = {
        name: user.intercom.profile_name,
        email: user.email,
        phone: user.phone,
        userId: (user.id && user.id.toString()) || '',
        customAttributes: {
          ...attributes,
          name: user.intercom.profile_name,
        },
        languageOverride: user.language,
      }

      update(intercomBootProps)
    }
  }, [user.intercom])

  const calcAppHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  const getContent = (): ReactNode => {
    const isHeaderNeeded = history.location.pathname.includes(Routes.CONFIRM_CLIENT)

    return user.isAuthorized || isHeaderNeeded ? (
      <PageWrapper>
        <Content />
        <GlobalStyle isShowedModal={app.isShowedModal} />
      </PageWrapper>
    ) : (
      <PreviewWrapper>
        <Aside />
        <Content />
        <GlobalStyle isShowedModal={app.isShowedModal} />
      </PreviewWrapper>
    )
  }

  return <>{getContent()}</>
}

export default App
