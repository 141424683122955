import { Reducer } from 'redux'

import { BudgetActionTypes } from './actions'
import { reducerName } from './modals/actions'
import { MODALS } from './modals/types'

export interface IModal {
  name: MODALS
  data?: any
}

export interface IBudgetState {
  modals: IModal | null
  redirect: string
}

export const initialState: IBudgetState = {
  modals: null,
  redirect: '',
}

const budgetReducer: Reducer<IBudgetState> = (state = initialState, action: any) => {
  switch (action.type) {
    case BudgetActionTypes.SET_BUDGET_REDIRECT: {
      return { ...state, redirect: action.data }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default budgetReducer
