import {
  anthraciteClr,
  BackgroundGrey,
  GreyBase,
  greyClr,
  greyClrDark,
  pinkClrLight,
  redClr,
  roseClrDark,
} from './colors'

// Fonts
export const baseFontFamily = '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif'

// Inputs
export const inputBorderBgFilled = greyClrDark
export const inputBorderBgError = redClr

export const baseInputBg = BackgroundGrey
export const errorInputBg = pinkClrLight

// Placeholder
export const inputPlaceholderClr = greyClr
export const inputPlaceholderClrError = redClr

// Texts
export const baseTextClr = anthraciteClr
export const mainTextClr = GreyBase
export const errorTextClr = redClr
export const baseErrorClr = roseClrDark

export const baseTransDur = '0.3s'

// Breakpoints
export const WidthBPs = {
  DESKTOP: '1280px',
  EXTRA_SMALL: '480px', //
  LARGE: '1440px', //
  MEDIUM: '1024px',
  SMALL: '768px', //
}

// Font Sizes
export const biggestFont = '40px/1'
export const bigFont = '30px/1.333'
export const biggerFont = '16px/1.3125'
export const baseFont = '15px/1.333'
export const smallFont = '14px/1.286'
export const mediumFont = '13px/15px'
export const smallestFont = '12px/1.333'

export const h1Font = '25.23px/30px'
export const h2Font = '22.43px/26px'
export const h3Font = '19.93px/23px'
export const h4Font = '17.72px/21px'
export const inputFont = '12.44px/15px'
export const inputMsgFont = '11.06px/13px'
export const menuFont = '13px/15px'

// Font Properties

export const signTitleFontProps = [`font: 500 ${biggestFont} ${baseFontFamily};`, `color: ${baseTextClr};`]
export const asideTitleFontProps = [`font: 400 21px/24px ${baseFontFamily};`, `letter-spacing: 0.2px;`]

export const h1HeaderFontProps = [
  `font: 500 ${h1Font} ${baseFontFamily};`,
  `color: ${mainTextClr};`,
  'letter-spacing: -0.5px;',
]

export const h2HeaderFontProps = [`font: 500 ${h2Font} ${baseFontFamily};`, `color: ${mainTextClr};`]

export const h3HeaderFontProps = [`font: 400 ${h3Font} ${baseFontFamily};`, `color: ${mainTextClr};`]

export const h4HeaderFontProps = [`font: 500 ${h4Font} ${baseFontFamily};`, `color: ${mainTextClr};`]

export const subtitleFontProps = [
  `font: 400 ${biggerFont} ${baseFontFamily};`,
  `color: ${baseTextClr};`,
  'letter-spacing: 0.2px;',
]

export const bodyFontProps = [
  `font: 400 ${smallFont} ${baseFontFamily};`,
  `color: ${mainTextClr};`,
  'letter-spacing: 0.2px;',
]

export const bodyBoldFontProps = [
  `font: bold ${smallFont} ${baseFontFamily};`,
  `color: ${mainTextClr};`,
  'letter-spacing: 0.2px;',
]

export const baseFontProps = [`font: 400 ${baseFont} ${baseFontFamily};`, 'letter-spacing: -0.2px;']

export const inputFontProps = [`font: 400 ${inputFont} ${baseFontFamily};`, 'letter-spacing: 0.2px;']

export const radioBtnFontProps = [`font: 500 13px/1 ${baseFontFamily};`, 'letter-spacing: 0.4px;']

export const paginationFontProps = [`font: 400 ${smallFont} ${baseFontFamily};`, 'letter-spacing: -0.2px;']

export const inputMsgFontProps = [`font: 400 ${inputMsgFont} ${baseFontFamily};`]

export const mediumFontProps = [`font: 500 ${mediumFont} ${baseFontFamily};`]

export const menuFontProps = [`font: 400 ${menuFont} ${baseFontFamily}; letter-spacing: -0.2px;`]

// Sizes
export const maxContentWidth = '1140px'
export const tinyFormWidth = '350px'
export const baseFormWidth = '560px'
export const mainFormWidth = '640px'
export const brandFormWidth = '620px'

// Spaces
export const outMainSpace = '40px'
export const inBaseSpace = '24px'
export const inAddSpace = '15px'
export const inSmallSpace = '8px'
export const doubleAddSpace = '30px'
export const formBaseSpace = '4px'
export const outFooterSpace = '64px'

export const TRANSITION_TIMEOUT = 300

export const MODAL_LOADER_TIMEOUT = 1000
