import { ReactNode } from 'react'

export enum COMBOBOX_SIZE {
  small = 'small',
  big = 'big',
}

export interface IProps {
  placeholder?: string | React.ReactNode
  size?: keyof typeof COMBOBOX_SIZE
  noOptionsMessage?: string | ReactNode
  options: IComboBoxOption[]
  defaultOptions?: IComboBoxOption[]
  defaultMenuIsOpen?: boolean
  closeMenuOnSelect?: boolean
  isDisabled?: boolean
  defaultValue?: IComboBoxOption | null
  searchFields?: SearchFieldsType[]
  inputId?: string
  containerId?: string
  onChange?: (newValue: IComboBoxOption) => void
  onMultiChange?: (newValue: IComboBoxOption[]) => void
  loadingMessage?: string | ReactNode
  isLoading?: boolean
  getRef?: (ref: any) => void
  label?: string | ReactNode
  helpText?: string | ReactNode
  errorText?: string | ReactNode
  fullWidth?: boolean
  isMultiSelect?: boolean
  multiValueText?: string
  hasCheckbox?: boolean
  value?: string | IComboBoxOption
  multiValue?: IComboBoxOption[]
  loadOptions?: any
  loadNextPage?: () => void
  isInfiniteScroll?: boolean
  dataTestId?: string
  className?: string
  isChip?: boolean
  isHideSelected?: boolean
  isHiddenInputWrite?: boolean
}

export interface IComboBoxOption {
  value: string
  label: string | ReactNode
  subLabel?: string
  icon?: ReactNode
}

export type SearchFieldsType = keyof IComboBoxOption

export const CustomInputAction = 'custom-input'
