import React from 'react'
import { useSelector } from 'react-redux'

import selector from './selector'
import useStyles from './styles'
import { BalanceCard } from './ui/Card'

import { EurIcon, UsdIcon } from '@/components/Icons'
import { Currency, CurrencySign } from '@/store/user/types'

const Balance = () => {
  const { balance, isProfileClient } = useSelector(selector)
  const classes = useStyles()

  const balanceTypes = [
    {
      currency: Currency.USD,
      amount: `${CurrencySign.USD} ${balance.USD}`,
      icon: <UsdIcon style={{ width: 22, height: 22 }} />,
    },
    {
      currency: Currency.EUR,
      amount: `${CurrencySign.EUR} ${balance.EUR}`,
      icon: <EurIcon style={{ width: 22, height: 22 }} />,
    },
  ]

  return (
    <div className={classes.balaceCardWrapper} data-tour-id="balanceCard">
      {balanceTypes.map((item) => (
        <BalanceCard key={item.currency} {...item} isProfileClient={isProfileClient} />
      ))}
    </div>
  )
}

export default Balance
