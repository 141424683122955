import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { IntercomProps } from 'react-use-intercom/dist/types'

import App from '../App'
import Startup from '../Startup'
import selector from './selector'

import { ToastContainer } from '@/components/ToastSMB'
import { LOCALE_DATA } from '@/locales'

const Router = () => {
  const { user } = useSelector(selector)
  const helmetContext = {}

  const intercomBootProps: IntercomProps = {
    name: user.first_name,
    email: user.email || undefined,
    phone: user.phone,
    userId: (user.id && user.id.toString()) || undefined,
  }

  return (
    <BrowserRouter>
      <IntlProvider locale={user.language} messages={LOCALE_DATA[user.language]}>
        <Startup>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_API || ''}
            autoBoot={true}
            autoBootProps={intercomBootProps}
          >
            <HelmetProvider context={helmetContext}>
              <App />
            </HelmetProvider>
            <ToastContainer />
          </IntercomProvider>
        </Startup>
      </IntlProvider>
    </BrowserRouter>
  )
}

export default Router
