import { createUseStyles } from 'react-jss'

import { TextSlateGreyBase, White } from 'src/const/colors'
import { FontBody1 } from 'src/const/fontStyles'

export default createUseStyles(
  {
    container: {
      position: 'absolute',
      top: '30%',
      left: 0,
      right: 0,
      margin: 'auto',
      width: 200,
      height: 200,
      zIndex: 100,
      backgroundColor: White,
      boxShadow: '0px 0px 6px rgba(92, 117, 149, 0.08)',
      borderRadius: 8,
    },
    message: {
      ...FontBody1,
      position: 'absolute',
      bottom: 26,
      left: 0,
      right: 0,
      margin: '0 auto',
      textAlign: 'center',
      color: TextSlateGreyBase,
    },
    loader: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  { name: 'Loader' }
)
