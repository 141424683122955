import { MODALS } from './modals/types'

import { Currency } from '@/store/user/types'

export enum InvoicesStatusType {
  issued = 'issued',
  payed = 'payed',
}

export interface IInvoice {
  id: string
  created_at: string
  amount: number
  status: InvoicesStatusType
  stripe_invoice_url: string
  invoice_number: string
  proforma_number: string
  currency: Currency
}

interface IInvoicesListType {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  results: IInvoice[]
  loading: boolean
}

export interface IInvoicesState {
  modals: IModal | null
  invoicesList: IInvoicesListType
  invoiceId: string | null
  loadingInvoices: string[]
}

export interface IModal {
  name: MODALS
  data?: any
}

export enum InvoicesGetTypes {
  card = 'invoices',
  proforma = 'proforms',
}

export enum InvoicesDeleteType {
  card = 'invoices',
  proforma = 'proforma',
}

export enum InvoicesActionTypes {
  SET_INVOICES = 'SET_INVOICES',
  UPDATE_INVOICES = 'UPDATE_INVOICE',
  SET_INVOICES_LOADING = 'SET_INVOICES_LOADING',
  SET_INVOICE_LOADING = 'SET_INVOICE_LOADING',
  REMOVE_INVOICE_LOADING = 'REMOVE_INVOICE_LOADING',
  SET_INVOICE_ID = 'SET_INVOICE_ID',
}
