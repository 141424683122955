import { createUseStyles } from 'react-jss'

import {
  GreenBase,
  GreenLighten41,
  OrangeBase,
  OrangeLighten37,
  PurpleBase,
  PurpleLighten12,
  PurpleLighten46,
  RedBase,
  RedLighten34,
  SlateGreyLighten13,
  SlateGreyLighten22,
  SlateGreyLighten50,
  TextGreyBase,
  TextSlateGreyDarken12,
  White,
} from '../../const/colors'
import { FontBody1Accent, FontBody2 } from '../../const/fontStyles'
import { ALERT_VARIANT } from './types'

export default createUseStyles(
  {
    root: {
      padding: 6,
      display: 'none',
      flexDirection: 'row',
      borderRadius: 8,
    },
    border: {
      flexShrink: 0,
      width: 3,
      borderRadius: 2,
    },
    [ALERT_VARIANT.neutral]: {
      backgroundColor: White,

      '& $border': {
        backgroundColor: PurpleBase,
      },

      '& $icon': {
        backgroundColor: PurpleBase,
      },

      '& $closeButton path': {
        stroke: SlateGreyLighten22,
      },

      '& $description span': {
        color: PurpleBase,
      },
    },
    [ALERT_VARIANT.neutralColor]: {
      backgroundColor: PurpleLighten46,

      '& $border': {
        backgroundColor: PurpleBase,
      },

      '& $icon': {
        backgroundColor: PurpleBase,
      },

      '& $closeButton path': {
        stroke: PurpleLighten12,
      },

      '& $description span': {
        color: PurpleBase,
      },
    },
    [ALERT_VARIANT.success]: {
      backgroundColor: White,

      '& $border': {
        backgroundColor: GreenBase,
      },

      '& $icon': {
        backgroundColor: GreenBase,
      },

      '& $closeButton path': {
        stroke: SlateGreyLighten22,
      },

      '& $description span': {
        color: GreenBase,
      },
    },
    [ALERT_VARIANT.successColor]: {
      backgroundColor: GreenLighten41,

      '& $border': {
        backgroundColor: GreenBase,
      },

      '& $icon': {
        backgroundColor: GreenBase,
      },

      '& $closeButton path': {
        stroke: GreenBase,
      },

      '& $description span': {
        color: GreenBase,
      },
    },
    [ALERT_VARIANT.warning]: {
      backgroundColor: White,

      '& $border': {
        backgroundColor: OrangeBase,
      },

      '& $icon': {
        backgroundColor: OrangeBase,
      },

      '& $closeButton path': {
        stroke: SlateGreyLighten22,
      },

      '& $description span': {
        color: OrangeBase,
      },
    },
    [ALERT_VARIANT.warningColor]: {
      backgroundColor: OrangeLighten37,

      '& $border': {
        backgroundColor: OrangeBase,
      },

      '& $icon': {
        backgroundColor: OrangeBase,
      },

      '& $closeButton path': {
        stroke: OrangeBase,
      },

      '& $description span': {
        color: OrangeBase,
      },
    },
    [ALERT_VARIANT.negative]: {
      backgroundColor: White,

      '& $border': {
        backgroundColor: RedBase,
      },

      '& $icon': {
        backgroundColor: RedBase,
      },

      '& $closeButton path': {
        stroke: SlateGreyLighten22,
      },

      '& $description span': {
        color: RedBase,
      },
    },
    [ALERT_VARIANT.negativeColor]: {
      backgroundColor: RedLighten34,

      '& $border': {
        backgroundColor: RedBase,
      },

      '& $icon': {
        backgroundColor: RedBase,
      },

      '& $closeButton path': {
        stroke: RedBase,
      },

      '& $description span': {
        color: RedBase,
      },
    },
    [ALERT_VARIANT.grey]: {
      backgroundColor: SlateGreyLighten50,

      '& $border': {
        backgroundColor: SlateGreyLighten13,
      },

      '& $icon': {
        backgroundColor: SlateGreyLighten13,
      },

      '& $closeButton path': {
        stroke: SlateGreyLighten22,
      },

      '& $description span': {
        color: TextSlateGreyDarken12,
      },
    },
    isOpen: {
      display: 'inline-flex',
    },
    isFullWidth: {
      width: '100%',
    },
    iconWrapper: {
      flexShrink: 0,
      padding: '10px 16px 10px 14px',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 20,
      height: 20,
      borderRadius: 10,

      '& > svg': {
        width: '100%',
        height: '100%',
      },
    },
    inner: {
      padding: '10px 10px 10px 0',
      flexGrow: 1,
      minWidth: 175,
    },
    title: {
      ...FontBody1Accent,
      color: TextGreyBase,

      '& + $description': {
        marginTop: 8,
      },
    },
    description: {
      ...FontBody2,
      color: TextSlateGreyDarken12,
    },
    content: {
      marginTop: 12,
    },
    closeButton: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 18,
      height: 18,
      cursor: 'pointer',
    },
  },
  { name: 'AlertInlineSMB' }
)
