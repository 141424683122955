import React from 'react'

import { PurpleBase, White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase, fillBg = White } = props
  const defaultStyle = { width: 45, height: 45 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" fill={fillBg} />
      <rect x="4" y="4" width="37" height="37" rx="18.5" fill="#FAFAFA" />
      <rect x="14" y="16" width="17" height="12" rx="2" fill="#FFFDE0" />
      <g clipPath="url(#clip0)">
        <path
          d="M29.8409 16H15.1591C14.5177 16 14 16.5025 14 17.125V27.625C14 28.2475 14.5177 28.75 15.1591 28.75H29.8409C30.4823 28.75 31 28.2475 31 27.625V17.125C31 16.5025 30.4823 16 29.8409 16ZM29.8409 26.83L26.7732 23.8525C26.5452 23.6313 26.1782 23.6313 25.9541 23.8525C25.73 24.0738 25.7261 24.43 25.9541 24.6475L29.0218 27.625H15.9782L19.0459 24.6475C19.2739 24.4262 19.2739 24.07 19.0459 23.8525C18.818 23.635 18.4509 23.6313 18.2268 23.8525L15.1591 26.83V19.6112L22.1214 24.7C22.2257 24.775 22.3455 24.8125 22.4691 24.8125C22.5927 24.8125 22.7125 24.775 22.8168 24.7L29.8409 19.6075V26.83ZM29.8409 18.205L22.473 23.545L15.1591 18.205V17.125H29.8409V18.205Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect x="14" y="16" width="17" height="12.75" fill={fillBg} />
        </clipPath>
      </defs>
    </svg>
  )
}
