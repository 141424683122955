import { createUseStyles } from 'react-jss'

import { TextSlateGreyDarken12 } from '../../const/colors'
import { FontCaption } from '../../const/fontStyles'

export default createUseStyles(
  {
    label: {
      color: TextSlateGreyDarken12,
      ...FontCaption,
      display: 'block',
    },
  },
  { name: 'FieldLabel' }
)
