import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import {
  BusinessTypes,
  FacebookPartnerActionTypes,
  IBusinessInfo,
  IBusinessManager,
  IBusinessPage,
  IFacebookApplication,
  IRequestBody,
} from './types'

import { API_URL } from '@/const'
import { IAdAccount, IAdAccountUpdateBody } from '@/store/adaccounts/types'
import { IFBCredentials } from '@/store/share-audiences/types'
import { ThunkResult } from '@/store/store'
import { UserActionTypes } from '@/store/user/types'
import { customAxios } from '@/utils'

export function setStep(step: number): AnyAction {
  return { type: FacebookPartnerActionTypes.SET_STEP, data: step }
}

export function selectBusinessManager(businessManager: IBusinessManager): AnyAction {
  return { type: FacebookPartnerActionTypes.SELECT_BUSINESS_MANAGER, data: businessManager }
}

export function selectBusinessType(businessType: BusinessTypes): AnyAction {
  return { type: FacebookPartnerActionTypes.SET_BUSINESS_INFO, data: { selected_business_type: businessType } }
}

export function setBusinessManagerLoading(value: boolean): AnyAction {
  return { type: FacebookPartnerActionTypes.SET_BUSINESS_MANAGER_LOADING, data: value }
}

export function setApplicationsSent(value: boolean): AnyAction {
  return { type: FacebookPartnerActionTypes.SET_APPLICATIONS_SENT, data: value }
}

export const getFBCredentials = (): ThunkResult<Promise<any>> => {
  return async (dispatch: Dispatch<AnyAction>): Promise<any> => {
    try {
      const response = await customAxios(`${API_URL}/me/facebook_credentials/`, 'GET', null, true)

      dispatch({
        type: FacebookPartnerActionTypes.SET_FACEBOOK_PROFILE,
        data: { is_authorized: true, ...response.data },
      })

      return response
    } catch (error: unknown) {
      if ((error as AxiosError)?.response?.status === 404) {
        dispatch({
          type: FacebookPartnerActionTypes.SET_FACEBOOK_PROFILE,
          data: { is_authorized: false },
        })
      }

      return Promise.reject(error)
    } finally {
      dispatch({ type: FacebookPartnerActionTypes.SET_FACEBOOK_PROFILE, data: { is_loading: false } })
    }
  }
}

export function setFBCredentials(body: IFBCredentials): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch) => {
    return customAxios(`${API_URL}/me/facebook_credentials/`, 'POST', body, true)
      .then((response: AxiosResponse<IFBCredentials>): any => {
        dispatch({ type: UserActionTypes.SET_FB_CREDENTIAL, data: true })
        return response
      })
      .catch((error: AxiosError<{ facebook_access_token?: string[] }>) => {
        const errorMessage = error.response?.data?.facebook_access_token?.[0]

        if (error.response?.status === 400 && errorMessage) {
          dispatch({
            type: FacebookPartnerActionTypes.SET_FACEBOOK_PROFILE,
            data: { errorMessage },
          })
        }

        return Promise.reject(error)
      })
  }
}

export function getBusinessManagers(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/facebook_partners/business_managers/`, 'GET', null, true)
      .then((response: AxiosResponse<IBusinessManager[]>): any => {
        dispatch({
          type: FacebookPartnerActionTypes.SET_BUSINESS_MANAGERS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function validateBusinessManager(body: IBusinessManager): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/facebook_partners/business_managers/validate/`, 'POST', body, true)
      .then((response: AxiosResponse<IBusinessManager>): any => response)
      .catch((error: AxiosError<{ non_field_errors?: string[] }>) => {
        const errorMessage = error.response?.data?.non_field_errors?.[0]

        if (error.response?.status === 400 && errorMessage) {
          dispatch({
            type: FacebookPartnerActionTypes.SET_BUSINESS_MANAGER_ERROR,
            data: errorMessage,
          })
        }

        return Promise.reject(error)
      })
  }
}

export function getBusinessPages(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch) => {
    dispatch({ type: FacebookPartnerActionTypes.SET_BUSINESS_PAGES_LOADING, data: true })

    return customAxios(`${API_URL}/facebook_partners/business_pages/`, 'GET', null, true)
      .then((response: AxiosResponse<IBusinessPage[]>): any => {
        dispatch({
          type: FacebookPartnerActionTypes.SET_BUSINESS_PAGES,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getBusinessInfo(id: string): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/facebook_partners/business_managers/retrieve/${id}/`, 'GET', null, true)
      .then((response: AxiosResponse<IBusinessInfo>): any => {
        dispatch({
          type: FacebookPartnerActionTypes.SET_BUSINESS_INFO,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function sendApplications(body: IRequestBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: FacebookPartnerActionTypes.SET_APPLICATIONS_LOADING, data: true })

    return customAxios(`${API_URL}/facebook_partners/applications/`, 'POST', body, true)
      .then((response: AxiosResponse<IFacebookApplication[]>): any => {
        dispatch({
          type: FacebookPartnerActionTypes.SET_APPLICATIONS_SENT,
          data: true,
        })

        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: FacebookPartnerActionTypes.SET_APPLICATIONS_ERRORS,
          data: error.response?.data,
        })

        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({ type: FacebookPartnerActionTypes.SET_APPLICATIONS_LOADING, data: false })
      })
  }
}

export function getApplications(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: FacebookPartnerActionTypes.SET_BUSINESS_PAGES_LOADING, data: true })

    return customAxios(`${API_URL}/facebook_partners/applications/`, 'GET', null, true)
      .then((response: AxiosResponse<IAdAccount[]>): any => {
        dispatch({
          type: FacebookPartnerActionTypes.SET_APPLICATIONS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function closeApplication(id: number, body: IAdAccountUpdateBody): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/facebook_partners/applications/${id}/`, 'PATCH', body, true)
      .then((response: AxiosResponse<IAdAccount>) => {
        dispatch({
          data: response.data,
          type: FacebookPartnerActionTypes.CLOSE_APPLICATION,
        })

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
