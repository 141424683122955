import countriesRu from 'react-phone-input-2/lang/ru.json'
import * as Sentry from '@sentry/browser'
import { AxiosError } from 'axios'
import en from 'date-fns/locale/en-GB'
import ru from 'date-fns/locale/ru'

import customAxios from './axios'
import enhancedError from './enhanceError'
import * as jwt from './jwt'

import { Language } from '@/store/user/types'

export const locales: { [key in Language]: string } = {
  [Language.Russian]: 'ru-RU',
  [Language.English]: 'en-GB',
}

export const datepickerLocales: { [key in Language]: any } = {
  [Language.Russian]: ru,
  [Language.English]: en,
}

export const phoneLocales = {
  [Language.Russian]: countriesRu,
  [Language.English]: {},
}

const addPrefixNull = (num: number) => (num < 10 ? `0${num}` : num)
const bodySelector = document.querySelector('body')
let scrollPosition = 0

export const getDate = (dateString: string) => {
  const date = new Date(dateString)

  return `${date.getFullYear()}-${addPrefixNull(date.getMonth() + 1)}-${addPrefixNull(date.getDate())}`
}

export const getDateString = (dateString: string) => {
  const date = new Date(dateString)

  return `${addPrefixNull(date.getDate())}.${addPrefixNull(date.getMonth() + 1)}.${date.getFullYear()}`
}

export const getDateWithMonth = (
  dateString: string,
  language: Language,
  hasYear = true,
  hasNull = true,
  hasDay = true
) => {
  const date = new Date(dateString)
  const month = date.toLocaleString(locales[language], { month: 'long' })
  const formattedDate = date.getDate()
  const result = `${hasDay ? (hasNull ? addPrefixNull(formattedDate) : formattedDate) : ''} ${month.substring(0, 3)}`

  return hasYear ? `${result} ${date.getFullYear()}` : result
}

export const getDateWithWeekday = (dateString: string, language: Language) => {
  const date = new Date(dateString)
  const options = { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit' }
  const formattedDate = date.toLocaleDateString(locales[language], options as any)

  // Need slice extra string ' .г' and capitalize first-letter
  return (
    formattedDate.charAt(0).toUpperCase() +
    (language === Language.Russian ? formattedDate.slice(1, -3) : formattedDate.slice(1))
  )
}

export const getMonthFromDatestring = (dateString: string, language: Language, hasYear = true) => {
  const date = new Date(dateString)
  const options = hasYear ? { year: 'numeric', month: 'long' } : { month: 'long' }
  const formattedDate = date.toLocaleDateString(locales[language], options as any)

  // Need slice extra string ' .г' and capitalize first-letter
  return hasYear
    ? formattedDate.charAt(0).toUpperCase() +
        (language === Language.Russian ? formattedDate.slice(1, -3) : formattedDate.slice(1)).split(' ').join(', ')
    : capitalizeFirstLetter(formattedDate)
}

export const capitalizeFirstLetter = (str: string) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '')

export const baseCatchError = (error: AxiosError) => {
  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setExtra('response', error.response)
  })

  return Promise.reject(error)
}

export const getDateWithTimeFromDateString = (dateString: string, language: Language) => {
  const date = new Date(dateString)
  const reportYear = date.getFullYear()
  const currentYear = new Date().getFullYear()
  const options = { day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit' }
  const optionsWithYear = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
  const formattedDate = date.toLocaleDateString(
    locales[language],
    reportYear === currentYear ? (options as any) : (optionsWithYear as any)
  )

  return formattedDate.replace(/ г./, '')
}

export const getFullDateFromDateString = (dateString: string, language: Language, isShortYear = true) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: 'long', year: isShortYear ? '2-digit' : 'numeric' }
  const formattedDate = date.toLocaleDateString(locales[language], options as any)

  // Need slice extra string ' .г' and capitalize first-letter
  return (language === Language.Russian ? formattedDate.slice(0, -3) : formattedDate.slice(0)).replace(/^0?/, '')
}

export const getHoursFromDateString = (dateString: string, language: Language) => {
  const date = new Date(dateString)
  const options = { hour: '2-digit', minute: '2-digit' }
  return date.toLocaleTimeString(locales[language], options as any)
}

export const getFullStringDateFromDateString = (
  dateString: string,
  isShortYear = true,
  language = Language.Russian
) => {
  const date = new Date(dateString)
  const options = { day: '2-digit', month: '2-digit', year: isShortYear ? '2-digit' : 'numeric' }
  return date.toLocaleDateString(locales[language], options as any)
}

export const getYearFromDateString = (dateString: string, language: Language, isShortYear = false) => {
  const date = new Date(dateString)
  const options = { year: isShortYear ? '2-digit' : 'numeric' }
  return date.toLocaleDateString(locales[language], options as any)
}

export const getQuarterFromDateString = (dateString: string, language: Language) => {
  const date = new Date(dateString)
  const options = { month: '2-digit' }
  const month = date.toLocaleDateString(locales[language], options as any)

  const quarters: { [key: string]: string[] } = {
    '1': ['01', '02', '03'],
    '2': ['04', '05', '06'],
    '3': ['07', '08', '09'],
    '4': ['10', '11', '12'],
  }

  const quarter = Object.keys(quarters).find((key: string) => quarters[key].includes(month))

  return `Q${quarter || ''}`
}

export const disableBodyScroll = () => {
  scrollPosition = window.pageYOffset

  if (bodySelector) {
    bodySelector.style.overflow = 'hidden'
    bodySelector.style.position = 'fixed'
    bodySelector.style.top = `-${scrollPosition}px`
    bodySelector.style.width = '100%'
  }
}

export const enableBodyScroll = (hasScrolling = true) => {
  if (bodySelector) {
    bodySelector.style.removeProperty('overflow')
    bodySelector.style.removeProperty('position')
    bodySelector.style.removeProperty('top')
    bodySelector.style.removeProperty('width')

    if (hasScrolling) {
      window.scrollTo(0, scrollPosition)
    }
  }
}

// https://jsfiddle.net/jp6chsa2/
export const getDayDeclension = (value: number, language: Language) => {
  const cases = [2, 0, 1, 1, 1, 2]
  const variants = ['день', 'дня', 'дней']

  return language === Language.Russian
    ? variants[value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5]]
    : value === 1
    ? 'day'
    : 'days'
}

export { enhancedError, jwt, customAxios }

export const getHostNameFromUrl = (url: string) =>
  new URL(url.match(/^https?:\/\//) ? url : `https://${url}`).hostname.replace('www.', '')
