import React, { PureComponent } from 'react'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'

import Loader from 'src/components/Loader'
import { RouteParams, Routes } from 'src/const/routes'
import { ContentBlock, ContentWrapper, StyledHeader } from 'src/containers/App/styled'
import { PrivateRoute } from 'src/containers/PrivateRoute'
import Index from 'src/pages/Index'
import Logout from 'src/pages/Logout'
import history from 'src/store/history'
import IApplicationState from 'src/types/state'

import AppModals from '../../../Modals/AppModals'
import Menu from '../Menu'

import { historyRedux } from '@/store/store'

const loaderDelay = 300

const Analytics = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Analytics/AnalyticsRoot'),
  loading: Loader,
})

const Reports = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Analytics/ReportsRoot'),
  loading: Loader,
})

const Report = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Analytics/ReportRoot'),
  loading: Loader,
})

const Tracker = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Analytics/TrackerRoot'),
  loading: Loader,
})

const Accounts = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Accounts'),
  loading: Loader,
})

const Balance = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Balance'),
  loading: Loader,
})

const Profile = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Profile'),
  loading: Loader,
})

const PasswordChange = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/PasswordChange'),
  loading: Loader,
})

const Invoices = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Invoices'),
  loading: Loader,
})

const Signin = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Signin'),
  loading: Loader,
})

const Signup = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Signup'),
  loading: Loader,
})

const History = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/History'),
  loading: Loader,
})

const ErrorPage = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/ErrorPage'),
  loading: Loader,
})

const ForgotPasswordRequest = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/ForgotPasswordRequest'),
  loading: Loader,
})

const ForgotPasswordConfirm = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/ForgotPasswordConfirm'),
  loading: Loader,
})

const AddAccounts = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/AddAccounts'),
  loading: Loader,
})

const Budget = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Budget'),
  loading: Loader,
})

const Settings = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Settings'),
  loading: Loader,
})

const Optimizer = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Optimizer'),
  loading: Loader,
})

// @todo: hide audiences while we are working with facebook partner (panda), we will return it back in the future
// const Audiences = Loadable({
//   delay: loaderDelay,
//   loader: () => import('@/pages/Audiences'),
//   loading: Loader,
// })
//
// const AudiencesWelcome = Loadable({
//   delay: loaderDelay,
//   loader: () => import('@/pages/Audiences/components/Welcome'),
//   loading: Loader,
// })
//
// const AudiencesQuiz = Loadable({
//   delay: loaderDelay,
//   loader: () => import('@/pages/Audiences/components/Quiz'),
//   loading: Loader,
// })

const Notifications = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Notifications'),
  loading: Loader,
})

const Referral = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Referral'),
  loading: Loader,
})

const ReferralWelcome = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/Referral/components/Welcome'),
  loading: Loader,
})

const MyCashback = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/MyCashback'),
  loading: Loader,
})

// @todo: hide antiban while we are working with facebook partner (panda), we will return it back in the future
// const AntiBan = Loadable({
//   delay: loaderDelay,
//   loader: () => import('@/pages/AntiBan'),
//   loading: Loader,
// })

// const AntiBanWelcome = Loadable({
//   delay: loaderDelay,
//   loader: () => import('@/pages/AntiBan/components/Welcome'),
//   loading: Loader,
// })

// const AntiBanBlockingActions = Loadable({
//   delay: loaderDelay,
//   loader: () => import('@/pages/AntiBan/components/BlockingActions'),
//   loading: Loader,
// })

// const AntiBanIncreaseProtection = Loadable({
//   delay: loaderDelay,
//   loader: () => import('@/pages/AntiBan/components/IncreaseProtection'),
//   loading: Loader,
// })

const AddTelegram = Loadable({
  delay: loaderDelay,
  loader: () => import('@/pages/AddTelegram'),
  loading: Loader,
})

class ContentClass extends PureComponent<any, any> {
  constructor(props: any) {
    super(props)
  }

  public render() {
    const { isAuthorized, hasProfile } = this.props

    const isHeaderNeeded = history.location.pathname.includes(Routes.CONFIRM_CLIENT)

    return (
      <Router history={historyRedux}>
        <>
          {(isAuthorized || isHeaderNeeded) && <StyledHeader />}
          <ContentWrapper isAuthorized={isAuthorized || isHeaderNeeded}>
            {isAuthorized && hasProfile && <Menu />}
            <ContentBlock hasProfile={hasProfile} isAuthorized={isAuthorized} isWhite={isHeaderNeeded}>
              <Switch>
                <PrivateRoute exact={true} path={Routes.INDEX} component={Index} />
                <PrivateRoute exact={true} path={`${Routes.BALANCE}/${RouteParams.CLIENT_ID}`} component={Balance} />
                <PrivateRoute exact={true} path={Routes.PROFILE} component={Profile} />
                <PrivateRoute exact={true} path={Routes.CHANGE_PASSWORD} component={PasswordChange} />
                <PrivateRoute exact={true} path={Routes.INVOICES} component={Invoices} />
                {/*<PrivateRoute exact={true} path={`${Routes.ACCOUNTING}/${RouteParams.TAB}`} component={Accounting} />*/}
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ACCOUNTS}/${RouteParams.ACCOUNT_SECTION}/${RouteParams.ACCOUNT_TAB}`}
                  component={Accounts}
                />
                <PrivateRoute exact={true} path={Routes.ACCOUNTS} component={Accounts} />
                <PrivateRoute exact={true} path={Routes.ANALYTICS} component={Analytics} />
                <PrivateRoute exact={true} path={Routes.REPORTS} component={Reports} />
                <PrivateRoute exact={true} path={`${Routes.REPORTS}/${RouteParams.REPORT_ID}`} component={Report} />
                <PrivateRoute exact={true} path={Routes.TRACKER} component={Tracker} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.ADD_ACCOUNTS}/${RouteParams.NETWORK}/${RouteParams.CLIENT_ID}`}
                  component={AddAccounts}
                />
                <PrivateRoute exact={true} path={Routes.ADD_TELEGRAM} component={AddTelegram} />
                <PrivateRoute exact={true} path={Routes.HISTORY} component={History} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.BUDGET}/${RouteParams.ACCOUNT_ID}/${RouteParams.CLIENT_ID}`}
                  component={Budget}
                />
                <PrivateRoute exact={true} path={`${Routes.SETTINGS}/${RouteParams.BLOCK_ID}`} component={Settings} />
                <PrivateRoute
                  exact={true}
                  path={`${Routes.OPTIMIZER}/${RouteParams.ACCOUNT_ID}`}
                  component={Optimizer}
                />
                {/*<PrivateRoute exact={true} path={Routes.AUDIENCES} component={Audiences} />*/}
                {/*<PrivateRoute exact={true} path={Routes.AUDIENCES_WELCOME} component={AudiencesWelcome} />*/}
                {/*<PrivateRoute exact={true} path={Routes.AUDIENCES_QUIZ} component={AudiencesQuiz} />*/}
                <PrivateRoute exact={true} path={Routes.NOTIFICATIONS} component={Notifications} />
                <PrivateRoute exact={true} path={Routes.REFERRAL} component={Referral} />
                <PrivateRoute exact={true} path={Routes.REFERRAL_WELCOME} component={ReferralWelcome} />
                <PrivateRoute exact={true} path={Routes.MY_CASHBACK} component={MyCashback} />
                {/*<PrivateRoute exact={true} path={`${Routes.ANTIBAN}`} component={AntiBan} />*/}
                {/*<PrivateRoute*/}
                {/*  exact={true}*/}
                {/*  path={`${Routes.ANTIBAN}/${RouteParams.ANTIBAN_BLOCKING_SECTION}/${RouteParams.ANTIBAN_BLOCKING_SUBSECTION}`}*/}
                {/*  component={AntiBanBlockingActions}*/}
                {/*/>*/}
                {/*<PrivateRoute*/}
                {/*  exact={true}*/}
                {/*  path={`${Routes.ANTIBAN}/${RouteParams.ANTIBAN_PROTECTION_SECTION}/${RouteParams.ANTIBAN_PROTECTION_SUBSECTION}`}*/}
                {/*  component={AntiBanIncreaseProtection}*/}
                {/*/>*/}
                {/*<PrivateRoute exact={true} path={Routes.ANTIBAN_WELCOME} component={AntiBanWelcome} />*/}
                <Route exact={true} path={`${Routes.SIGN_UP}`} component={Signup} />
                <Route path={`${Routes.SIGN_UP}/${RouteParams.ID}`} component={Signup} />
                <Route exact={true} path={Routes.SIGN_IN} component={Signin} />
                <Route exact={true} path={Routes.LOGOUT} component={Logout} />
                <Route exact={true} path={Routes.FORGOT_PASSWORD} component={ForgotPasswordRequest} />
                <Route exact={true} path={Routes.FORGOT_PASSWORD_CONFIRM} component={ForgotPasswordConfirm} />
                <Route component={ErrorPage} />
              </Switch>
            </ContentBlock>
          </ContentWrapper>
          <AppModals />
        </>
      </Router>
    )
  }
}

export const mapStateToProps = (state: IApplicationState) => ({
  hasProfile: state.user.has_profile,
  isAuthorized: state.user.isAuthorized,
  router: state.router,
})

export const Content = connect(mapStateToProps)(ContentClass)

export default Content
