import update from 'immutability-helper'
import { Reducer } from 'redux'

import { AnalyticsActionTypes, SELECTED_ANALYTICS_DASHBOARD_ID } from './actions'
import { reducerName } from './Modals/actions'
import { MODALS } from './Modals/types'
import {
  IDashboards,
  IDashboardSelectedFilters,
  IFilter,
  IQueryEditing,
  IQueryInsight,
  IQueryInsights,
  IQueryItem,
  ISelectedFilters,
} from './types'

export interface IModal {
  name: MODALS
  data?: any
}

export interface IAnalyticsState {
  modals: IModal | null
  filters: IFilter
  selectedFilters: IDashboardSelectedFilters
  selectedConstructorFilters: ISelectedFilters
  selectedDashboard: IDashboards | null
  dashboards: IDashboards[]
  dashboard: {
    [key: string]: any
  }
  selectedQuery: IQueryItem | null
  queries: IQueryItem[]
  queryInsights: IQueryInsights | null
  queryEditing: IQueryEditing
  queryExcludedGraphs: IQueryInsight[]
  redirectPathname: string
  isQueryInsightsLoading: boolean
}

const FILTER_PAGE_OBJECT = {
  page_count: 0,
  count: 0,
  current: 0,
  next: null,
  previous: null,
  loading: false,
}

export const initialState: IAnalyticsState = {
  filters: {
    accounts: null,
    platforms: null,
    period: null,
    campaigns: null,
    adsets: null,
    ads: null,
  },
  selectedFilters: {
    period: null,
    filters: {
      accounts: null,
      platforms: null,
      campaigns: null,
      adsets: null,
      ads: null,
    },
  },
  selectedConstructorFilters: {
    accounts: null,
    platforms: null,
    campaigns: null,
    adsets: null,
    ads: null,
  },
  selectedDashboard: null,
  dashboards: [],
  dashboard: {},
  selectedQuery: null,
  queries: [],
  modals: null,
  queryInsights: null,
  isQueryInsightsLoading: false,
  queryEditing: {
    isMetricsEditing: false,
    isDimensionEditing: false,
    isGroupingEditing: false,
    isPeriodEditing: false,
    isExcludedGraphsEditing: false,
    isFiltersEditing: false,
  },
  redirectPathname: '',
  queryExcludedGraphs: [],
}

const analyticsReducer: Reducer<IAnalyticsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_DASHBOARDS: {
      let selectedDashboard = action.data[0]

      if (action.data.length > 1) {
        const savedDashboardId = window.localStorage.getItem(SELECTED_ANALYTICS_DASHBOARD_ID)

        selectedDashboard = savedDashboardId
          ? action.data.find((item: IDashboards) => item?.id === savedDashboardId)
          : action.data.find((item: IDashboards) => item?.is_protected === true)
      }

      return {
        ...state,
        dashboards: action.data,
        selectedFilters: selectedDashboard?.params ?? null,
        selectedDashboard,
      }
    }
    case AnalyticsActionTypes.SET_SELECTED_DASHBOARD: {
      return {
        ...state,
        selectedDashboard: action.data,
        selectedFilters: action.data?.params ?? null,
        dashboard: {},
      }
    }
    case AnalyticsActionTypes.DELETE_DASHBOARD: {
      const { id } = action
      const queries = state.selectedDashboard?.queries.filter((item) => item.id !== id) || []
      const selectedDashboard = { ...state.selectedDashboard, queries }

      return { ...state, selectedDashboard }
    }
    case AnalyticsActionTypes.SET_DASHBOARD: {
      return {
        ...state,
        dashboard: { ...state.dashboard, ...action.data },
      }
    }
    case AnalyticsActionTypes.SET_DASHBOARDS_LOADING: {
      return {
        ...state,
        dashboard: Object.keys(state.dashboard).reduce((acc: any, key) => {
          acc[key] = null

          return acc
        }, {}),
      }
    }
    case AnalyticsActionTypes.SET_DASHBOARD_LOADING: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [action.data.id]: null,
        },
      }
    }
    case AnalyticsActionTypes.SET_FILTER: {
      return {
        ...state,
        selectedFilters: action.data.params,
      }
    }
    case AnalyticsActionTypes.CLEAR_FILTERS: {
      return {
        ...state,
        filters: {
          accounts: null,
          platforms: null,
          period: null,
          campaigns: null,
          adsets: null,
          ads: null,
        },
      }
    }
    case AnalyticsActionTypes.RESET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: {
          period: null,
          filters: {
            accounts: null,
            platforms: null,
            campaigns: null,
            adsets: null,
            ads: null,
          },
        },
      }
    }
    case AnalyticsActionTypes.SET_FILTER_PLATFORMS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          platforms: action.data,
        },
      }
    }
    case AnalyticsActionTypes.SET_FILTER_ACCOUNTS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          accounts: action.data,
        },
      }
    }
    case AnalyticsActionTypes.SET_FILTER_CAMPAIGNS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          campaigns: {
            ...action.data,
            results: state.filters.campaigns
              ? [...state.filters.campaigns.results, ...action.data.results]
              : [...action.data.results],
            loading: false,
          },
        },
      }
    }
    case AnalyticsActionTypes.CLEAR_FILTER_CAMPAIGNS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          campaigns: {
            ...FILTER_PAGE_OBJECT,
            loading: true,
            results: [],
          },
        },
      }
    }
    case AnalyticsActionTypes.SET_FILTER_ADSETS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          adsets: {
            ...action.data,
            results: state.filters.adsets
              ? [...state.filters.adsets.results, ...action.data.results]
              : [...action.data.results],
            loading: false,
          },
        },
      }
    }
    case AnalyticsActionTypes.CLEAR_FILTER_ADSETS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          adsets: {
            ...FILTER_PAGE_OBJECT,
            loading: true,
            results: [],
          },
        },
      }
    }
    case AnalyticsActionTypes.SET_FILTER_ADS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ads: {
            ...action.data,
            results: state.filters.ads
              ? [...state.filters.ads.results, ...action.data.results]
              : [...action.data.results],
            loading: false,
          },
        },
      }
    }
    case AnalyticsActionTypes.CLEAR_FILTER_ADS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ads: {
            ...FILTER_PAGE_OBJECT,
            loading: true,
            results: [],
          },
        },
      }
    }
    case AnalyticsActionTypes.SET_SELECTED_FILTER: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.filterKey]: action.selectedFilter,
        },
      }
    }
    case AnalyticsActionTypes.SET_SELECTED_FILTERS: {
      return {
        ...state,
        [action.storeSelectedFilterKey]: {
          // @ts-ignore
          ...state[action.storeSelectedFilterKey],
          // [action.filterKey]: action.selectedFilters,
          ...action.selectedFilters,
        },
      }
    }
    case AnalyticsActionTypes.CLEAR_FILTER_GROUP: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.filterKey]: null,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERIES: {
      return {
        ...state,
        queries: action.data,
      }
    }
    case AnalyticsActionTypes.SET_QUERY: {
      return {
        ...state,
        selectedQuery: {
          ...action.data,
        },
      }
    }
    case AnalyticsActionTypes.CLEAR_QUERY: {
      return {
        ...state,
        selectedQuery: null,
      }
    }
    case AnalyticsActionTypes.UPDATE_QUERY: {
      const foundIndex = state.queries.findIndex((query) => query.id === action.data.id)

      return {
        ...state,
        queries: update(state.queries, {
          $splice: [[foundIndex, 1, action.data]],
        }),
      }
    }
    case AnalyticsActionTypes.SET_QUERY_INSIGHTS: {
      return {
        ...state,
        queryInsights: action.data,
      }
    }
    case AnalyticsActionTypes.CLEAR_QUERY_INSIGHTS: {
      return {
        ...state,
        queryInsights: null,
        queryExcludedGraphs: [],
      }
    }
    case AnalyticsActionTypes.SET_QUERY_METRICS_EDITING: {
      return {
        ...state,
        queryEditing: {
          ...state.queryEditing,
          isMetricsEditing: true,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERY_DIMENSION_EDITING: {
      return {
        ...state,
        queryEditing: {
          ...state.queryEditing,
          isDimensionEditing: true,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERY_GROUPING_EDITING: {
      return {
        ...state,
        queryEditing: {
          ...state.queryEditing,
          isGroupingEditing: true,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERY_PERIOD_EDITING: {
      return {
        ...state,
        queryEditing: {
          ...state.queryEditing,
          isPeriodEditing: true,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERY_EXCLUDED_GRAPHS_EDITING: {
      return {
        ...state,
        queryEditing: {
          ...state.queryEditing,
          isExcludedGraphsEditing: true,
        },
      }
    }
    case AnalyticsActionTypes.RESET_QUERY_EDITING: {
      return {
        ...state,
        queryEditing: {
          ...state.queryEditing,
          isMetricsEditing: false,
          isDimensionEditing: false,
          isGroupingEditing: false,
          isPeriodEditing: false,
          isExcludedGraphsEditing: false,
          isFiltersEditing: false,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERY_REDIRECT: {
      return {
        ...state,
        redirectPathname: action.data,
      }
    }
    case AnalyticsActionTypes.RESET_CONSTRUCTOR_FILTERS: {
      return {
        ...state,
        selectedConstructorFilters: {
          accounts: null,
          platforms: null,
          period: null,
          campaigns: null,
          adsets: null,
          ads: null,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERY_FILTERS_EDITING: {
      return {
        ...state,
        queryEditing: {
          ...state.queryEditing,
          isFiltersEditing: true,
        },
      }
    }
    case AnalyticsActionTypes.SET_QUERY_INSIGHTS_LOADING: {
      return {
        ...state,
        isQueryInsightsLoading: action.data,
      }
    }
    case AnalyticsActionTypes.SET_QUERY_EXCLUDED_GRAPHS: {
      return {
        ...state,
        queryExcludedGraphs: action.data,
      }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default analyticsReducer
