import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5199_4992)">
        <path
          d="M20 40C31.0463 40 40 31.0463 40 20C40 8.95375 31.0463 0 20 0C8.95375 0 0 8.95375 0 20C0 31.0463 8.95375 40 20 40Z"
          fill="#005FD1"
        />
        <path
          d="M21.5204 26.821C20.2547 26.8221 19.0131 26.4801 17.9315 25.8323C16.8499 25.1845 15.9699 24.2559 15.3878 23.1481H20.8603C21.2649 23.1481 21.6375 22.9278 21.8078 22.571C22.1592 21.8784 21.6481 21.0494 20.8603 21.0494H14.6852C14.6319 20.7031 14.6 20.3568 14.6 20C14.6 19.6432 14.6319 19.2969 14.6852 18.9506H20.8603C21.2649 18.9506 21.6375 18.7302 21.8078 18.3734C21.8914 18.2139 21.9318 18.0358 21.9251 17.8564C21.9183 17.6769 21.8647 17.5023 21.7694 17.3492C21.6741 17.1962 21.5404 17.07 21.3811 16.9829C21.2218 16.8958 21.0424 16.8506 20.8603 16.8518H15.3878C15.9709 15.7449 16.8511 14.8171 17.9325 14.1694C19.0139 13.5217 20.255 13.1792 21.5204 13.179C22.8512 13.179 24.0969 13.5568 25.1616 14.1969C25.6939 14.5222 26.3859 14.4697 26.8331 14.029C27.4506 13.4204 27.3122 12.4234 26.5669 11.9722C25.0505 11.0481 23.3033 10.5576 21.5204 10.5555C17.3468 10.5555 13.8121 13.1895 12.4919 16.8518H9.40437C8.99979 16.8518 8.62716 17.0722 8.44616 17.429C8.09482 18.1321 8.60586 18.9506 9.40437 18.9506H12.0022C11.916 19.6476 11.916 20.3524 12.0022 21.0494H9.40437C8.99979 21.0494 8.62716 21.2697 8.45681 21.6265C8.09482 22.3296 8.60586 23.1481 9.40437 23.1481H12.4919C13.8121 26.8105 17.3468 29.4444 21.5204 29.4444C23.3729 29.4444 25.0977 28.9302 26.5669 28.0278C27.3016 27.5765 27.44 26.5691 26.8225 25.9605C26.3753 25.5197 25.6833 25.4673 25.1509 25.8031C24.0969 26.4537 22.8619 26.821 21.5204 26.821Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5199_4992">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
