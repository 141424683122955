import React, { ChangeEvent, useCallback, useState } from 'react'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { useSelector } from 'react-redux'

import { getLanguage } from '../../common/selectors'
import { phoneLocales } from '../../utils'
import useStyles from './styles'
import { IInputPhoneProps } from './types'

import 'react-phone-input-2/lib/material.css'

const InputPhone = (props: IInputPhoneProps) => {
  const { label = '', country = '', onChange, onBlur, value } = props
  const [phoneValue, setPhoneValue] = useState(value)
  const classes = useStyles()
  const language = useSelector(getLanguage)

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>, countryObject: CountryData) => {
    if (onBlur) {
      onBlur(event, countryObject)
    }
  }

  const handlePhoneChange = useCallback(
    (valueData: string, countryData: CountryData, e: ChangeEvent<HTMLInputElement>, formattedValue: string) => {
      onChange(valueData, countryData)
      setPhoneValue(formattedValue)
    },
    []
  )

  return (
    <PhoneInput
      value={phoneValue}
      country={country}
      buttonClass={classes.button}
      dropdownClass={classes.dropdown}
      inputClass={classes.input}
      containerClass={classes.container}
      specialLabel={label}
      localization={phoneLocales[language]}
      onChange={handlePhoneChange}
      onBlur={blurHandler}
      disableCountryGuess={true}
    />
  )
}

export default InputPhone
