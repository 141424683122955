import React from 'react'
import Lottie from 'react-lottie'

export interface ILottieRendererProps {
  source: any
  width?: number
  height?: number
  loop?: boolean | number
}

const LottieRenderer = (props: ILottieRendererProps) => {
  const { loop = true } = props

  const defaultOptions = {
    animationData: props.source,
    autoplay: true,
    loop,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return <Lottie options={defaultOptions} width={props.width} height={props.height} />
}

export default LottieRenderer
