import { Language } from '../../../../store/user/types'
import { ILanguage } from './types'

export const languages: ILanguage[] = [
  {
    value: Language.Russian,
    label: 'Русский',
  },
  {
    value: Language.English,
    label: 'English',
  },
]
