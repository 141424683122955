import React, { useState } from 'react'
import { DefaultTheme } from 'react-jss'
import cn from 'classnames'

import { White } from '../../const/colors'
import AlertIcon from '../Icons/AlertIcon'
import CheckIcon from '../Icons/CheckIcon'
import InfoIcon from '../Icons/InfoIcon'
import PopupCloseIcon from '../Icons/PopupCloseIcon'
import useStyles from './styles'
import { ALERT_VARIANT, IAlertInlineSMBProps } from './types'

const AlertInline = (props: IAlertInlineSMBProps & { theme?: DefaultTheme }) => {
  const {
    isOpen = true,
    title,
    description,
    content,
    className,
    hasCloseButton = false,
    isCloseCallback,
    variant = ALERT_VARIANT.neutral,
    isFullWidth = false,
    hasBorder = true,
  } = props

  const classes = useStyles(props)

  const [isOpenLocal, setIsOpenLocal] = useState(isOpen)

  const closeHandler = () => {
    setIsOpenLocal(false)

    if (isCloseCallback) {
      isCloseCallback()
    }
  }

  return (
    <div
      className={cn(
        classes.root,
        classes[variant],
        {
          [classes.isOpen]: isOpenLocal,
          [classes.isFullWidth]: isFullWidth,
        },
        className
      )}
    >
      {hasBorder && <div className={classes.border} />}
      <div className={classes.iconWrapper}>
        <div className={classes.icon}>
          {variant === ALERT_VARIANT.neutral ||
          variant === ALERT_VARIANT.neutralColor ||
          variant === ALERT_VARIANT.grey ? (
            <InfoIcon fill={White} fillBg={'transparent'} />
          ) : variant === ALERT_VARIANT.success || variant === ALERT_VARIANT.successColor ? (
            <CheckIcon fill={White} />
          ) : (
            <AlertIcon fill={White} fillBg={'transparent'} />
          )}
        </div>
      </div>
      <div className={classes.inner}>
        {title && <div className={classes.title}>{title}</div>}
        {description && <div className={classes.description}>{description}</div>}
        {content && <div className={classes.content}>{content}</div>}
      </div>
      {hasCloseButton && (
        <div onClick={closeHandler} className={classes.closeButton}>
          <PopupCloseIcon style={{ width: 12, height: 12 }} />
        </div>
      )}
    </div>
  )
}

export { AlertInline }
