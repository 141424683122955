import React from 'react'

const Star = () => (
  <svg width="101" height="91" viewBox="0 0 101 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddi_1295_51765)">
      <path
        d="M56.8136 21.1906C57.6874 19.7006 59.9726 20.6281 59.5608 22.3056L56.6698 34.0822C56.0379 36.6561 57.1719 39.3393 59.4581 40.6799L69.9185 46.8141C71.4085 47.6878 70.481 49.973 68.8035 49.5612L57.0269 46.6702C54.453 46.0383 51.7698 47.1723 50.4291 49.4585L44.295 59.9189C43.4213 61.4089 41.136 60.4814 41.5478 58.8039L44.4389 47.0273C45.0707 44.4534 43.9367 41.7702 41.6506 40.4296L31.1901 34.2954C29.7002 33.4217 30.6277 31.1365 32.3051 31.5483L44.0818 34.4393C46.6556 35.0712 49.3388 33.9372 50.6795 31.651L56.8136 21.1906Z"
        fill="url(#paint0_linear_1295_51765)"
        shapeRendering="crispEdges"
      />
      <path
        d="M56.8136 21.1906C57.6874 19.7006 59.9726 20.6281 59.5608 22.3056L56.6698 34.0822C56.0379 36.6561 57.1719 39.3393 59.4581 40.6799L69.9185 46.8141C71.4085 47.6878 70.481 49.973 68.8035 49.5612L57.0269 46.6702C54.453 46.0383 51.7698 47.1723 50.4291 49.4585L44.295 59.9189C43.4213 61.4089 41.136 60.4814 41.5478 58.8039L44.4389 47.0273C45.0707 44.4534 43.9367 41.7702 41.6506 40.4296L31.1901 34.2954C29.7002 33.4217 30.6277 31.1365 32.3051 31.5483L44.0818 34.4393C46.6556 35.0712 49.3388 33.9372 50.6795 31.651L56.8136 21.1906Z"
        fill="white"
        fillOpacity="0.8"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddi_1295_51765"
        x="0.442383"
        y="-9.55664"
        width="100.224"
        height="100.223"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.701961 0 0 0 0 0.376471 0 0 0 0 0.854902 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1295_51765" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.701961 0 0 0 0 0.376471 0 0 0 0 0.854902 0 0 0 0.7 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_1295_51765" result="effect2_dropShadow_1295_51765" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1295_51765" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.70182 0 0 0 0 0.377257 0 0 0 0 0.854167 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1295_51765" />
      </filter>
      <linearGradient
        id="paint0_linear_1295_51765"
        x1="58.6693"
        y1="20.5603"
        x2="42.4393"
        y2="60.5492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default Star
