import { Reducer } from 'redux'

import { IRequestsState, RequestsActionTypes } from './types'

import { AdAccountTypes } from '@/store/adaccounts/types'

const initialState: IRequestsState = {
  facebook: [],
  tiktok: [],
}

const reducer: Reducer<IRequestsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case RequestsActionTypes.SET_REQUESTS: {
      return {
        ...state,
        [action.network]: [...action.data],
      }
    }
    case RequestsActionTypes.SET_REQUEST: {
      return {
        ...state,
        [action.network]: [...state[action.network as AdAccountTypes], action.data],
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as requestsReducer }
