import React, { FunctionComponent as FC } from 'react'
import { useIntl } from 'react-intl'

import LottieRenderer from '../LottieRenderer'
import source from './loader.json'
import useStyle from './styles'

const Loader: FC<any> = (props: any) => {
  const { pastDelay } = props

  const intl = useIntl()
  const classes = useStyle()

  if (!pastDelay) {
    return null
  }

  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <LottieRenderer source={source} height={200} width={200} />
      </div>
      <div className={classes.message}>{intl.formatMessage({ id: 'common.loading' })}</div>
    </div>
  )
}

Loader.defaultProps = {
  pastDelay: true,
}

export default Loader
