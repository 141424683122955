import React from 'react'

import LottieRenderer from '../LottieRenderer'
import loader from './loader-circle.json'

interface ICircleLoaderProps {
  width?: number
  height?: number
}

const CircleLoader = (props: ICircleLoaderProps) => {
  const { height, width } = props

  return <LottieRenderer source={loader} height={height} width={width} />
}

export default CircleLoader
