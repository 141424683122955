export enum Routes {
  ADD_CLIENT = '/add-client',
  ANALYTICS = '/analytics',
  ACCOUNTS = '/accounts',
  ADD_ACCOUNTS = '/accounts/add',
  ADD_TELEGRAM = '/accounts/add/telegram',
  BALANCE = '/balance',
  CHANGE_PASSWORD = '/user/change_password',
  ACCOUNTING = '/profile/accounting',
  INDEX = '/',
  INVOICES = '/invoices',
  HISTORY = '/history',
  LOGOUT = '/logout',
  PROFILE = '/profile',
  SIGN_IN = '/user/signin',
  SIGN_UP = '/user/signup',
  FORGOT_PASSWORD = '/user/forgot_password',
  FORGOT_PASSWORD_CONFIRM = '/user/forgot_password/:token',
  BUDGET = '/budget',
  SETTINGS = '/settings',
  OPTIMIZER = '/optimizer',
  SHARE_AUDIENCES = '/share-audiences',
  AUDIENCES = '/audiences',
  AUDIENCES_WELCOME = '/audiences/welcome',
  AUDIENCES_QUIZ = '/audiences/quiz',
  NOTIFICATIONS = '/notifications',
  REFERRAL = '/referral',
  REFERRAL_WELCOME = '/referral/welcome',
  MY_CASHBACK = '/my-cashback',
  ANTIBAN = '/antiban',
  ANTIBAN_WELCOME = '/antiban/welcome',
  CONFIRM_CLIENT = '/confirm-client',
  TRACKER = '/tracker',
  REPORTS = '/reports',
}

export enum RouteParams {
  CLIENT_ID = ':clientId?',
  NETWORK = ':network(facebook|tiktok)',
  TAB = ':tab(acts|documents)?',
  ID = ':id',
  BLOCK_ID = ':blockId?',
  ACCOUNT_ID = ':accountId?',
  ANTIBAN_BLOCKING_SECTION = ':section(blocking-actions)',
  ANTIBAN_PROTECTION_SECTION = ':section(increase-protection)',
  ANTIBAN_BLOCKING_SUBSECTION = ':subsection(i-dont-know|business-manager|account|business-page|profile)?',
  ANTIBAN_PROTECTION_SUBSECTION = ':subsection(business-manager|account|business-page|profile)',
  ACCOUNT_TAB = ':tab(facebook|tiktok|archived_accounts|telegram)?',
  ACCOUNT_SECTION = ':section(platform|client)?',
  CLIENT_UUID = ':uuid?',
  REPORT_ID = ':id?',
}
