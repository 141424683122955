import React from 'react'
import { DefaultTheme } from 'react-jss'
import cn from 'classnames'

import useStyles from './styles'
import { IPopoverProps } from './types'

const Popover = (props: IPopoverProps & { theme?: DefaultTheme }) => {
  const { children, className, right = false } = props

  const classes = useStyles(props)

  return <div className={cn(classes.wrapper, { [classes.right]: right }, className)}>{children}</div>
}

export default Popover
