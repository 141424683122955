import React from 'react'
import PhoneInput from 'react-phone-input-2'

import useStyles from './styles'
import { IInputPhoneProps } from './types'

const PhoneFormat = (props: Pick<IInputPhoneProps, 'value'>) => {
  const { value } = props
  const classes = useStyles()

  return (
    <PhoneInput
      buttonClass={classes.buttonPhoneFormatter}
      containerClass={classes.containerFormatter}
      inputClass={classes.inputFormatter}
      disabled
      value={value}
    />
  )
}

export default PhoneFormat
