import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import cn from 'classnames'

import selector, {
  IsNotViewableFeature,
  IsNotViewableInviteFriend,
  IsNotViewableReportsFeature,
  IsNotViewableTrackerFeature,
} from './selector'
import {
  NewFeatureFlag,
  Section,
  SectionArrow,
  SectionBlock,
  SectionEllipsedIcon,
  SectionIcon,
  SectionInfo,
  SectionSquaredIcon,
  SubSection,
  SubSectionLink,
  Wrapper,
} from './styled'
import useStyles from './styles'

import { Mixpanel } from '@/analytics'
import {
  ArrowUpIcon,
  BillIcon,
  ChartPieIcon,
  CoinsIcon,
  CycleIcon,
  DashboardIcon,
  HomeIcon,
  InviteFriendIcon,
  PercentFilledIcon,
  ReportIcon,
  TrackerIcon,
} from '@/components/Icons'
import ClockFilledIcon from '@/components/Icons/ClockFilledIcon'
import { GreyLighten26 } from '@/const/colors'
import { TRANSITION_TIMEOUT } from '@/const/commonStyles'
import { Routes } from '@/const/routes'
import { toggleAnalyticsMenu, toggleFinanceMenu } from '@/store/app'
import { CloseDocType, PaymentMethod, ProfileType } from '@/store/user/types'

const SubMenu = () => {
  const {
    isFinanceMenuOpened,
    isToolMenuOpened,
    isMenuOpened,
    referral,
    isNotViewableFeature,
    isNotViewableInviteFriend,
    isAnalyticsMenuOpened,
    isNotViewableTrackerFeature,
    paymentMethod,
    closedoc_type,
    payment_method,
    profile_type,
    isPostPayment,
    isHiddenMenu,
  } = useSelector(selector)

  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const isReferralProgram = !(profile_type === ProfileType.BUSINESS && payment_method === PaymentMethod.PROFORMA)

  const onFinanceMenuToggle = () => {
    dispatch(toggleFinanceMenu(!isFinanceMenuOpened))

    Mixpanel.track('ToggleFinanceMenu')
  }

  const onInviteFriendClick = () => {
    Mixpanel.track('ReferralVisit', { place: 'menu' })

    window.localStorage.setItem(IsNotViewableInviteFriend, 'false')
  }

  // const onToolMenuToggle = () => {
  //   dispatch(toggleToolMenu(!isToolMenuOpened))
  //
  //   Mixpanel.track('ToggleToolMenu')
  // }

  const onAnalyticsMenuToggle = () => {
    dispatch(toggleAnalyticsMenu(!isAnalyticsMenuOpened))

    Mixpanel.track('ToggleAnalyticsMenu')
  }

  const isFinanceMenuActive = () =>
    !isFinanceMenuOpened &&
    (location.pathname.includes(Routes.HISTORY) ||
      location.pathname.includes(Routes.BUDGET) ||
      location.pathname.includes(Routes.MY_CASHBACK))

  // const isToolMenuActive = () =>
  //   !isToolMenuOpened && (location.pathname.includes(Routes.AUDIENCES) || location.pathname.includes(Routes.ANTIBAN))

  const isAnalyticsMenuActive = () =>
    !isAnalyticsMenuOpened &&
    (location.pathname.includes(Routes.ANALYTICS) ||
      location.pathname.includes(Routes.TRACKER) ||
      location.pathname.includes(Routes.REPORTS))

  const onBudgetClick = () => {
    Mixpanel.track('AccountsGotoBudget')
  }

  const onMyCashbackClick = () => {
    Mixpanel.track('MenuGoToMyCashback')
  }

  const onAnalyticsDashboardClick = () => {
    Mixpanel.track('AnalyticsMainClick', { source: 'dashboard' })

    window.localStorage.setItem(IsNotViewableFeature, 'false')
  }

  const onAnalyticsReportsClick = () => {
    Mixpanel.track('ReportsMainClick')

    window.localStorage.setItem(IsNotViewableReportsFeature, 'false')
  }

  const onTrackerClick = () => {
    Mixpanel.track('TrackerMainClick')

    window.localStorage.setItem(IsNotViewableTrackerFeature, 'false')
  }

  return (
    <Wrapper>
      <NavLink to={Routes.ACCOUNTS} exact={false} className={classes.navLink}>
        <SectionIcon>
          <HomeIcon />
        </SectionIcon>
        <FormattedMessage id="menu.accounts" />
      </NavLink>
      <Section opened={isAnalyticsMenuOpened} onClick={onAnalyticsMenuToggle} isActive={isAnalyticsMenuActive()}>
        <SectionInfo>
          <SectionIcon>
            <SectionEllipsedIcon>
              <ChartPieIcon />
            </SectionEllipsedIcon>
          </SectionIcon>
          <FormattedMessage id="menu.analytics" />
          {(isNotViewableFeature || isNotViewableTrackerFeature) && (
            <NewFeatureFlag isOpened={isMenuOpened && !isAnalyticsMenuOpened} />
          )}
        </SectionInfo>
        {isAnalyticsMenuOpened && (
          <SectionArrow>
            <ArrowUpIcon style={{ width: 22, height: 22 }} fill={GreyLighten26} />
          </SectionArrow>
        )}
      </Section>
      <CSSTransition timeout={TRANSITION_TIMEOUT} in={isAnalyticsMenuOpened} classNames="opacity" unmountOnExit={true}>
        <SubSection>
          <SubSectionLink to={Routes.ANALYTICS} exact={false} onClick={onAnalyticsDashboardClick}>
            <SectionIcon>
              <SectionSquaredIcon>
                <DashboardIcon />
              </SectionSquaredIcon>
            </SectionIcon>
            <FormattedMessage id="menu.dashboards" />
            {isNotViewableFeature && <NewFeatureFlag isOpened={isMenuOpened && isAnalyticsMenuOpened} />}
          </SubSectionLink>
          <SubSectionLink to={Routes.REPORTS} exact={false} onClick={onAnalyticsReportsClick} data-tour-id="reports">
            <SectionIcon>
              <SectionSquaredIcon>
                <ReportIcon />
              </SectionSquaredIcon>
            </SectionIcon>
            <FormattedMessage id="menu.reports" />
            {isNotViewableFeature && <NewFeatureFlag isOpened={isMenuOpened && isAnalyticsMenuOpened} />}
          </SubSectionLink>
          <SubSectionLink to={Routes.TRACKER} exact={false} onClick={onTrackerClick}>
            <SectionIcon>
              <SectionSquaredIcon>
                <TrackerIcon />
              </SectionSquaredIcon>
            </SectionIcon>
            <FormattedMessage id="menu.tracker" />
            {isNotViewableTrackerFeature && <NewFeatureFlag isOpened={isMenuOpened && isAnalyticsMenuOpened} />}
          </SubSectionLink>
        </SubSection>
      </CSSTransition>
      <CSSTransition
        timeout={TRANSITION_TIMEOUT}
        in={isAnalyticsMenuOpened}
        classNames="analytics-menu"
        unmountOnExit={false}
      >
        <SectionBlock>
          <Section opened={isFinanceMenuOpened} onClick={onFinanceMenuToggle} isActive={isFinanceMenuActive()}>
            <SectionInfo>
              <SectionIcon>
                <SectionEllipsedIcon>
                  <CoinsIcon />
                </SectionEllipsedIcon>
              </SectionIcon>
              <FormattedMessage id="menu.finance" />
            </SectionInfo>
            {isFinanceMenuOpened && (
              <SectionArrow>
                <ArrowUpIcon style={{ width: 22, height: 22 }} fill={GreyLighten26} />
              </SectionArrow>
            )}
          </Section>
          <CSSTransition
            timeout={TRANSITION_TIMEOUT}
            in={isFinanceMenuOpened}
            classNames="opacity"
            unmountOnExit={true}
          >
            <SubSection>
              {!isPostPayment && !isHiddenMenu && (
                <SubSectionLink to={Routes.INVOICES} exact={false}>
                  <SectionIcon>
                    <SectionSquaredIcon>
                      <BillIcon />
                    </SectionSquaredIcon>
                  </SectionIcon>
                  <FormattedMessage
                    id={paymentMethod === PaymentMethod.CARD ? 'menu.invoicesPayment' : 'menu.invoices'}
                  />
                </SubSectionLink>
              )}
              <SubSectionLink to={Routes.HISTORY} exact={false}>
                <SectionIcon>
                  <SectionSquaredIcon>
                    <ClockFilledIcon />
                  </SectionSquaredIcon>
                </SectionIcon>
                <FormattedMessage id="menu.orderHistory" />
              </SubSectionLink>
              <SubSectionLink to={Routes.BUDGET} exact={false} onClick={onBudgetClick}>
                <SectionIcon>
                  <SectionSquaredIcon>
                    <CycleIcon />
                  </SectionSquaredIcon>
                </SectionIcon>
                <FormattedMessage id="menu.distributeBudget" />
              </SubSectionLink>
              <SubSectionLink to={Routes.MY_CASHBACK} exact={false} onClick={onMyCashbackClick}>
                <SectionIcon>
                  <SectionSquaredIcon>
                    <PercentFilledIcon />
                  </SectionSquaredIcon>
                </SectionIcon>
                <FormattedMessage id="menu.myCashBack" />
              </SubSectionLink>
            </SubSection>
          </CSSTransition>
          <CSSTransition
            timeout={TRANSITION_TIMEOUT}
            in={isFinanceMenuOpened}
            classNames={{
              enter: isPostPayment || isHiddenMenu ? 'finance-menu-enter-three-items' : 'finance-menu-enter-four-items',
              exit:
                isPostPayment || isHiddenMenu
                  ? 'finance-menu-exit-active-three-items'
                  : 'finance-menu-exit-active-four-items',
              enterActive: 'finance-menu-enter-active',
              exitActive: 'finance-menu-exit',
            }}
            unmountOnExit={false}
          >
            <SectionBlock>
              {/*<Section opened={isToolMenuOpened} onClick={onToolMenuToggle} isActive={isToolMenuActive()}>*/}
              {/*  <SectionInfo>*/}
              {/*    <SectionIcon>*/}
              {/*      <SectionEllipsedIcon>*/}
              {/*        <RocketIcon />*/}
              {/*      </SectionEllipsedIcon>*/}
              {/*    </SectionIcon>*/}
              {/*    <FormattedMessage id="menu.tools" />*/}
              {/*  </SectionInfo>*/}
              {/*  {isToolMenuOpened && (*/}
              {/*    <SectionArrow>*/}
              {/*      <ArrowUpIcon style={{ width: 22, height: 22 }} fill={GreyLighten26} />*/}
              {/*    </SectionArrow>*/}
              {/*  )}*/}
              {/*</Section>*/}
              {/*<CSSTransition*/}
              {/*  timeout={TRANSITION_TIMEOUT}*/}
              {/*  in={isToolMenuOpened}*/}
              {/*  classNames="opacity"*/}
              {/*  unmountOnExit={true}*/}
              {/*>*/}
              {/*  <SubSection>*/}
              {/*    <SubSectionLink to={Routes.ANTIBAN} exact={false} onClick={onMyCashbackClick}>*/}
              {/*      <SectionIcon>*/}
              {/*        <SectionSquaredIcon>*/}
              {/*          <AntiBanIcon />*/}
              {/*        </SectionSquaredIcon>*/}
              {/*      </SectionIcon>*/}
              {/*      <FormattedMessage id="menu.antiBan" />*/}
              {/*    </SubSectionLink>*/}
              {/*    <SubSectionLink to={Routes.AUDIENCES} exact={false}>*/}
              {/*      <SectionIcon>*/}
              {/*        <SectionSquaredIcon>*/}
              {/*          <TargetIcon />*/}
              {/*        </SectionSquaredIcon>*/}
              {/*      </SectionIcon>*/}
              {/*      <FormattedMessage id="menu.audiences" />*/}
              {/*    </SubSectionLink>*/}
              {/*  </SubSection>*/}
              {/*</CSSTransition>*/}
              {closedoc_type !== CloseDocType.spend && isReferralProgram && (
                <CSSTransition
                  timeout={TRANSITION_TIMEOUT}
                  in={isToolMenuOpened}
                  classNames="tool-menu"
                  unmountOnExit={false}
                >
                  <SectionBlock>
                    <NavLink
                      to={referral.code ? Routes.REFERRAL : Routes.REFERRAL_WELCOME}
                      exact={false}
                      onClick={onInviteFriendClick}
                      className={cn(classes.navLink, classes.isDashed)}
                    >
                      <SectionIcon>
                        <InviteFriendIcon />
                      </SectionIcon>
                      <FormattedMessage id="menu.inviteFriend" />
                      {isNotViewableInviteFriend && <NewFeatureFlag isOpened={isMenuOpened} />}
                    </NavLink>
                  </SectionBlock>
                </CSSTransition>
              )}
            </SectionBlock>
          </CSSTransition>
        </SectionBlock>
      </CSSTransition>
    </Wrapper>
  )
}

export default SubMenu
