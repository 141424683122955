import { Reducer } from 'redux'

import {
  CloseDocType,
  IUserState,
  Language,
  PaymentMethod,
  PAYS_TO_TYPES,
  ProfileType,
  UserActionTypes,
  UserWorkflow,
} from './types'

import { AgencyDocsReceivedStatus } from '@/types'

export const initialState: IUserState = {
  language: Language.Russian,
  ab_tests: [],
  accessToken: '',
  email: '',
  first_name: '',
  fb_credential_enabled: false,
  fbc: '',
  fbp: '',
  ga_user_id: '',
  has_profile: false,
  id: null,
  isAuthorized: false,
  mailProvider: '',
  profile: {
    account_counts: {
      archived_accounts: 0,
      archived_fb: 0,
      archived_tiktok: 0,
      facebook: 0,
      tiktok: 0,
      telegram: 0,
      archived_telegram: 0,
    },
    min_card_payment: { EUR: 0, USD: 0 },
    pays_to: PAYS_TO_TYPES.UK_NODDINGTON,
    contract_agency_status: false,
    contract_client_status: false,
    agency_docs_received_status: AgencyDocsReceivedStatus.EMPTY,
    actual_address: '',
    agency: '',
    agency_clients: [],
    allow_create_accounts: {
      facebook: false,
      tiktok: false,
      telegram: false,
    },
    antiban: false,
    autopayment: false,
    available_plugins: [],
    balance: { EUR: 0, USD: 0 },
    business_manager_id: '',
    cashback: null,
    ceo: '',
    connected_plugins: [],
    contract_type: '',
    created_at: '',
    creatives_sent: false,
    documents_methods: '',
    facebook_commission_percent: 0.1,
    fb_optimizer_auth: false,
    full_name: '',
    has_tiktok_manager: false,
    id: null,
    industry: null,
    inn: '',
    is_profile_agency: false,
    is_profile_client: false,
    kpp: '',
    legal_address: '',
    name: '',
    onboarding_optimizer_is_finished: false,
    optimizer: false,
    business_info: null,
    bank_details: null,
    paying_customer: false,
    payment_method: PaymentMethod.CARD,
    personal_phone: '',
    post_sale_onboarding: false,
    profile_checks: {
      payment: false,
      policy: false,
    },
    profile_is_approved: false,
    profile_type: ProfileType.INDIVIDUAL,
    promocode: null,
    smart_targetings: false,
    website: '',
    is_last_30_days_spending: false,
    analytics_product_tour: false,
    balance_product_tour: false,
    is_country_confirmed: false,
    country_is_ready: false,
    tax_rate: 0,
    closedoc_type: CloseDocType.payment,
    workflow: UserWorkflow.standard,
  },
  refreshToken: '',
  subscribe: false,
  telegram_enabled: false,
  tokenExpire: 0,
  country: { code: '', name: '' },
  user_timezone: '',
  phone: '',
  intercom: null,
}

const reducer: Reducer<IUserState> = (state = initialState, action: any) => {
  switch (action.type) {
    case UserActionTypes.SET_AUTHORIZED: {
      return { ...state, isAuthorized: action.data }
    }
    case UserActionTypes.SET_ATOKEN: {
      return { ...state, accessToken: action.data }
    }
    case UserActionTypes.SET_RTOKEN: {
      return { ...state, refreshToken: action.data }
    }
    case UserActionTypes.SET_EXPIRES: {
      return { ...state, tokenExpire: action.data }
    }
    case UserActionTypes.SET_USER: {
      const language = action.data.preferred_language
      return { ...state, language, ...action.data }
    }
    case UserActionTypes.SET_HAS_PROFILE: {
      return { ...state, has_profile: action.data }
    }
    case UserActionTypes.SET_SUBSCRIBE: {
      return { ...state, subscribe: action.data }
    }
    case UserActionTypes.SET_PROFILE: {
      return {
        ...state,
        profile: { ...state.profile, ...action.data },
      }
    }
    case UserActionTypes.SET_ALLOW_CREATE_ACCOUNTS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          allow_create_accounts: { ...action.data },
        },
      }
    }
    case UserActionTypes.CLEAR_PROFILE: {
      return {
        ...state,
        profile: initialState.profile,
      }
    }
    case UserActionTypes.SET_MAIL_PROVIDER: {
      return {
        ...state,
        mailProvider: action.data,
      }
    }
    case UserActionTypes.SET_GA_USER_ID: {
      return { ...state, ga_user_id: action.data }
    }
    case UserActionTypes.SET_FBP: {
      return { ...state, fbp: action.data }
    }
    case UserActionTypes.SET_AVAILABLE_PLUGINS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          available_plugins: [...action.data],
        },
      }
    }
    case UserActionTypes.REMOVE_AVAILABLE_PLUGIN: {
      const foundIndex = state.profile.available_plugins.findIndex((plugin) => plugin.slug === action.data)

      return {
        ...state,
        profile: {
          ...state.profile,
          available_plugins: [
            ...state.profile.available_plugins.slice(0, foundIndex),
            ...state.profile.available_plugins.slice(foundIndex + 1),
          ],
        },
      }
    }
    case UserActionTypes.SET_FB_CREDENTIAL: {
      return { ...state, fb_credential_enabled: action.data }
    }
    case UserActionTypes.SET_TIKTOK_MANAGER: {
      return {
        ...state,
        profile: {
          ...state.profile,
          has_tiktok_manager: action.data,
        },
      }
    }
    case UserActionTypes.REMOVE_CONNECTED_PLUGIN: {
      return {
        ...state,
        profile: {
          ...state.profile,
          connected_plugins: state.profile.connected_plugins.filter((plugin) => plugin.id !== action.data),
        },
      }
    }
    case UserActionTypes.SET_CONNECTED_PLUGIN: {
      return {
        ...state,
        profile: {
          ...state.profile,
          connected_plugins: [...state.profile.connected_plugins, action.data],
        },
      }
    }
    case UserActionTypes.SET_INTERCOM_PROFILE: {
      return {
        ...state,
        intercom: action.data,
      }
    }
    case UserActionTypes.SET_USER_LANGUAGE: {
      return { ...state, language: action.data }
    }
    default: {
      return state
    }
  }
}

export { reducer as userReducer }
