import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import selector from './selector'
import {
  PopupButton,
  PopupButtonWrapper,
  PopupDivider,
  PopupEmail,
  PopupFooter,
  PopupFooterButton,
  PopupHeader,
  PopupIcon,
  PopupMenuWrapper,
} from './styled'

import { Mixpanel } from '@/analytics'
import { UserIcon } from '@/components/Icons'
import { GLOBAL_COUNTRIES } from '@/const'
import { links } from '@/const/links'
import { Routes } from '@/const/routes'
import { CloseDocType, PaymentMethod, ProfileType } from '@/store/user/types'

interface IPopupMenuProps {
  email: string
  onClick: () => void
  hasProfile: boolean
}

const PopupMenu = (props: IPopupMenuProps) => {
  const { language, profile, user, country } = useSelector(selector)

  const isReferralProgram = !(
    user.profile.profile_type === ProfileType.BUSINESS && user.profile.payment_method === PaymentMethod.PROFORMA
  )

  const onOfertaClick = () => {
    Mixpanel.track('GoToOferta')
  }

  const onInviteFriendClick = () => {
    Mixpanel.track('ReferralVisit', { place: 'client' })

    props.onClick()
  }

  const termsLink =
    country.code === GLOBAL_COUNTRIES.AZ ? links[language].evidoTermsPdfAz : links[language].evidoTermsPdf

  return (
    <PopupMenuWrapper>
      <PopupHeader>
        <PopupIcon>
          <UserIcon style={{ width: 40, height: 40 }} />
        </PopupIcon>
        <PopupEmail>{props.email}</PopupEmail>
        <PopupDivider />
      </PopupHeader>
      {props.hasProfile && (
        <>
          <PopupButtonWrapper>
            <PopupButton bType="link" theme="default" to={Routes.SETTINGS} onClick={props.onClick}>
              <FormattedMessage id="menu.settings" />
            </PopupButton>
          </PopupButtonWrapper>
          <PopupButtonWrapper>
            <PopupButton bType="link" theme="default" to={Routes.NOTIFICATIONS} onClick={props.onClick}>
              <FormattedMessage id="menu.notifications" />
            </PopupButton>
          </PopupButtonWrapper>
          {profile.closedoc_type !== CloseDocType.spend && isReferralProgram && (
            <PopupButtonWrapper>
              <PopupButton bType="link" theme="default" to={Routes.REFERRAL} onClick={onInviteFriendClick}>
                <FormattedMessage id="menu.inviteFriend" />
              </PopupButton>
            </PopupButtonWrapper>
          )}
        </>
      )}
      <PopupButtonWrapper>
        <PopupButton bType="external_link" theme="default" href={termsLink} target="_blank" onClick={onOfertaClick}>
          <FormattedMessage id="menu.oferta" />
        </PopupButton>
      </PopupButtonWrapper>
      {props.hasProfile && (
        <PopupButtonWrapper>
          <PopupButton bType="link" theme="default" to={Routes.CHANGE_PASSWORD} onClick={props.onClick}>
            <FormattedMessage id="menu.changePassword" />
          </PopupButton>
        </PopupButtonWrapper>
      )}
      <PopupFooter>
        <PopupFooterButton bType="link" theme="default" to={Routes.LOGOUT} onClick={props.onClick}>
          <FormattedMessage id="menu.exit" />
        </PopupFooterButton>
      </PopupFooter>
    </PopupMenuWrapper>
  )
}

export default PopupMenu
