import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 17C13 18.8565 13.7375 20.637 15.0503 21.9497C16.363 23.2625 18.1435 24 20 24C21.8565 24 23.637 23.2625 24.9497 21.9497C26.2625 20.637 27 18.8565 27 17C27 15.1435 26.2625 13.363 24.9497 12.0503C23.637 10.7375 21.8565 10 20 10C18.1435 10 16.363 10.7375 15.0503 12.0503C13.7375 13.363 13 15.1435 13 17Z"
        fill="#C6CCD4"
      />
      <path
        d="M11.3333 40C7.39966 40 7 37.8087 7 35.3333C7 32.858 7.91309 30.484 9.53841 28.7337C11.1637 26.9833 14.3681 26 16.6667 26H23.3333C25.6319 26 28.8363 26.9833 30.4616 28.7337C32.0869 30.484 33 32.858 33 35.3333C33 37.8087 32.6003 40 28.6667 40"
        fill="#C6CCD4"
      />
    </svg>
  )
}
