import { Reducer } from 'redux'

import { BalanceActionTypes } from './actions'
import { reducerName } from './modals/actions'
import { MODALS } from './modals/types'
import { IExchangeRate } from './types'

export interface IModal {
  name: MODALS
  data?: any
}

export interface IBalanceState {
  modals: IModal | null
  exchangeRate: IExchangeRate | null
}

export const initialState: IBalanceState = {
  modals: null,
  exchangeRate: null,
}

const balanceReducer: Reducer<IBalanceState> = (state = initialState, action: any) => {
  switch (action.type) {
    case BalanceActionTypes.SET_BALANCE_EXCHANGE_RATE: {
      return {
        ...state,
        exchangeRate: action.data,
      }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default balanceReducer
