import React, { ReactNode } from 'react'

export enum INPUT_VARIANT {
  default = 'default',
  grey = 'grey',
}

export enum INPUT_SIZE {
  size40 = 'size40',
  size48 = 'size48',
}

export interface IInputSMBProps {
  variant?: INPUT_VARIANT
  defaultValue?: string
  size?: INPUT_SIZE
  value?: string
  name: string
  placeholder?: string
  mask?: string | Array<string | RegExp>
  onChange?: (value: any) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFocus?: (e?: React.SyntheticEvent<HTMLElement>) => void
  onClick?: (e?: React.SyntheticEvent<HTMLElement>) => void
  inputRef?: React.Ref<HTMLInputElement>
  disabled?: boolean
  type?: string
  error?: boolean
  width?: string
  fullWidth?: boolean
  rows?: number
  min?: number
  max?: number
  className?: string
  request?: (value: string) => Promise<unknown>
  suggestionList?: (response: IResponseProps) => ReactNode
  alwaysShowMask?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  id?: string
}

export interface ISuggestionsProps {
  data: any
  unrestricted_value: string
  value: string
}

export interface IResponseProps {
  suggestions: ISuggestionsProps[]
}
