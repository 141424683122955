import { createUseStyles } from 'react-jss'

import { FontBody1Accent } from '../../../../../const/fontStyles'

import { SlateGreyLighten50 } from '@/const/colors'

export default createUseStyles(
  {
    root: {
      ...FontBody1Accent,
      width: '100%',
      backgroundColor: SlateGreyLighten50,
      padding: '8px 6px 6px 8px',
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      cursor: 'pointer',
    },
  },
  { name: 'BalanceCountryType' }
)
