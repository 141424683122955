import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5196_4953)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="#2AABEE"
        />
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="url(#paint0_linear_5196_4953)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.0532 19.7889C14.8836 17.2487 18.7715 15.574 20.7167 14.7649C26.271 12.4547 27.4251 12.0534 28.1773 12.0401C28.3428 12.0372 28.7127 12.0782 28.9523 12.2727C29.1546 12.4369 29.2103 12.6586 29.237 12.8143C29.2636 12.97 29.2968 13.3246 29.2704 13.6016C28.9694 16.7641 27.6671 24.4386 27.0045 27.9806C26.7241 29.4793 26.1721 29.9818 25.6377 30.031C24.4762 30.1379 23.5943 29.2635 22.4694 28.5261C20.7091 27.3722 19.7147 26.6539 18.0061 25.528C16.0314 24.2267 17.3115 23.5115 18.4368 22.3427C18.7313 22.0368 23.8487 17.3823 23.9477 16.96C23.9601 16.9072 23.9716 16.7104 23.8546 16.6064C23.7377 16.5025 23.5651 16.538 23.4406 16.5663C23.264 16.6064 20.452 18.465 15.0044 22.1422C14.2063 22.6903 13.4833 22.9574 12.8355 22.9434C12.1214 22.9279 10.7478 22.5396 9.7266 22.2077C8.4741 21.8005 7.47864 21.5853 7.56532 20.8938C7.61047 20.5337 8.10643 20.1654 9.0532 19.7889Z"
          fill="white"
        />
        <path
          d="M8 16C12.4185 16 16 12.4185 16 8C16 3.5815 12.4185 0 8 0C3.5815 0 0 3.5815 0 8C0 12.4185 3.5815 16 8 16Z"
          fill="#005FD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15.9333C12.3817 15.9333 15.9333 12.3817 15.9333 8C15.9333 3.61829 12.3817 0.0666667 8 0.0666667C3.61829 0.0666667 0.0666667 3.61829 0.0666667 8C0.0666667 12.3817 3.61829 15.9333 8 15.9333ZM17 8C17 12.9708 12.9708 17 8 17C3.02919 17 -1 12.9708 -1 8C-1 3.02919 3.02919 -1 8 -1C12.9708 -1 17 3.02919 17 8Z"
          fill="white"
        />
        <path
          d="M8.68415 11.0694C8.11461 11.07 7.55589 10.916 7.06916 10.6245C6.58243 10.333 6.18643 9.91515 5.92451 9.41667H8.38711C8.56917 9.41667 8.73685 9.3175 8.81351 9.15694C8.97161 8.84528 8.74164 8.47222 8.38711 8.47222H5.6083C5.58435 8.31639 5.56998 8.16056 5.56998 8C5.56998 7.83944 5.58435 7.68361 5.6083 7.52778H8.38711C8.56917 7.52778 8.73685 7.42861 8.81351 7.26806C8.85112 7.19626 8.86929 7.11612 8.86626 7.03538C8.86323 6.95463 8.83911 6.87603 8.79623 6.80717C8.75334 6.73831 8.69315 6.68152 8.62148 6.64231C8.54981 6.6031 8.46908 6.58278 8.38711 6.58333H5.92451C6.1869 6.08523 6.583 5.66768 7.06963 5.37624C7.55625 5.08479 8.11473 4.93063 8.68415 4.93056C9.28303 4.93056 9.84358 5.10056 10.3227 5.38861C10.5622 5.535 10.8737 5.51139 11.0749 5.31306C11.3528 5.03917 11.2905 4.59056 10.9551 4.3875C10.2727 3.97165 9.48645 3.75094 8.68415 3.75C6.80606 3.75 5.21544 4.93528 4.62135 6.58333H3.23195C3.04989 6.58333 2.8822 6.6825 2.80076 6.84306C2.64265 7.15944 2.87262 7.52778 3.23195 7.52778H4.40096C4.36216 7.84143 4.36216 8.15857 4.40096 8.47222H3.23195C3.04989 8.47222 2.8822 8.57139 2.80555 8.73194C2.64265 9.04833 2.87262 9.41667 3.23195 9.41667H4.62135C5.21544 11.0647 6.80606 12.25 8.68415 12.25C9.51779 12.25 10.2939 12.0186 10.9551 11.6125C11.2857 11.4094 11.348 10.9561 11.0701 10.6822C10.8689 10.4839 10.5574 10.4603 10.3179 10.6114C9.84358 10.9042 9.28782 11.0694 8.68415 11.0694Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_5196_4953" x1="20" y1="0" x2="20" y2="39.7033" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
        <clipPath id="clip0_5196_4953">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
