import React, { PureComponent, SyntheticEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import LanguageSwitch from './components/LanguageSwitch'
import PopupMenu from './components/PopupMenu'
import {
  BurgerButton,
  BurgerButtonWrapper,
  ButtonBlock,
  ButtonGroup,
  ClientLabel,
  ClientName,
  ClientNameBlock,
  Container,
  HeaderIconButton,
  IconButton,
  LogoAuthWrapper,
  LogoWrapper,
  NavBlock,
  StyledHeader,
} from './styled'
import { IHeaderProps, IHeaderState } from './types'

import { Mixpanel } from '@/analytics'
import { BurgerIcon, InviteFriendIcon, LogoIcon, UserIcon } from '@/components/Icons'
import { White } from '@/const/colors'
import { Routes } from '@/const/routes'
import { toggleMenu } from '@/store/app'
import { MyThunkDispatch } from '@/store/store'
import { CloseDocType, PaymentMethod, ProfileType } from '@/store/user/types'
import IApplicationState from '@/types/state'

/**
 * Header line with partners badges in desktop and logo in mobile versions.
 */
export class Header extends PureComponent<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  public render() {
    const { user, isDesktop } = this.props

    const isReferralProgram = !(
      user.profile.profile_type === ProfileType.BUSINESS && user.profile.payment_method === PaymentMethod.PROFORMA
    )

    return (
      <div className={this.props.className}>
        <StyledHeader>
          <Container>
            <NavBlock>
              {!!user.has_profile && (
                <BurgerButtonWrapper>
                  <BurgerButton onClick={this.onMenuToggle}>
                    <BurgerIcon />
                  </BurgerButton>
                </BurgerButtonWrapper>
              )}
              {user.has_profile ? (
                <NavLink exact={true} to={Routes.ACCOUNTS} onClick={this.onLogoClick}>
                  <LogoAuthWrapper>
                    <LogoIcon style={{ width: 76, height: 15 }} fill={White} />
                  </LogoAuthWrapper>
                </NavLink>
              ) : (
                <LogoWrapper>
                  <LogoIcon style={{ width: 76, height: 15 }} fill={White} />
                </LogoWrapper>
              )}
            </NavBlock>

            {isDesktop &&
              (user.profile.is_profile_client ? (
                <ClientNameBlock>
                  <ClientLabel>
                    <FormattedMessage id="header.youAreClient" />
                  </ClientLabel>
                  <ClientName>{user.profile.agency}</ClientName>
                </ClientNameBlock>
              ) : (
                <div />
              ))}

            <ButtonBlock>
              <LanguageSwitch />

              {isDesktop && user.profile.closedoc_type !== CloseDocType.spend && isReferralProgram && (
                <ButtonGroup>
                  {!!user.has_profile && (
                    <HeaderIconButton
                      theme="default"
                      bType="link"
                      to={Routes.REFERRAL_WELCOME}
                      onClick={this.onInviteFriendClick}
                    >
                      <InviteFriendIcon fill={White} />
                    </HeaderIconButton>
                  )}
                </ButtonGroup>
              )}

              <IconButton onClick={this.toggleMenu} data-testid="user-profile">
                <UserIcon />
              </IconButton>

              {this.state.isOpen && (
                <OutsideClickHandler onOutsideClick={this.onClickOut}>
                  <PopupMenu email={user.email} onClick={this.onClickClose} hasProfile={Boolean(user.has_profile)} />
                </OutsideClickHandler>
              )}
            </ButtonBlock>
          </Container>
        </StyledHeader>
      </div>
    )
  }

  private onMenuToggle = () => {
    this.props.openMenu(!this.props.isMenuOpened)

    Mixpanel.track('ToggleMenuBurger')
  }

  private onClickOut = (e: MouseEvent) => {
    e.preventDefault()

    this.setState({
      isOpen: false,
    })
  }

  private toggleMenu = (e: SyntheticEvent) => {
    e.preventDefault()

    Mixpanel.track('TogglePopupMenu')

    this.setState((prevState: IHeaderState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  private onInviteFriendClick = () => {
    Mixpanel.track('ReferralVisit', { place: 'header' })
  }

  private onLogoClick = () => {
    Mixpanel.track('LogoClick')
  }

  private onClickClose = () => {
    this.setState({
      isOpen: false,
    })
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  isDesktop: state.browser.greaterThan.medium,
  isMenuOpened: state.app.isMenuOpened,
  profile: state.user.profile,
  user: state.user,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openMenu: (open: boolean) => dispatch(toggleMenu(open)),
})

const HeaderClass = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderClass
