import React from 'react'
import { useIntl } from 'react-intl'

import { ALERT_VARIANT, AlertInline } from '../../../../../components/AlertInlineSMB'
import { IPaymentMethodCardsProps } from '../../types'
import PaymentMethodCard from '../PaymentMethodCard'
import useStyles from './styles'

import { PaymentMethod } from '@/store/user/types'

const PaymentMethodCards = (props: IPaymentMethodCardsProps) => {
  const { changeHandler, cardType } = props

  const intl = useIntl()

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.cardsWrapper}>
        <PaymentMethodCard
          cardType={PaymentMethod.CARD}
          isActive={cardType === PaymentMethod.CARD}
          changeHandler={changeHandler}
        />
        <PaymentMethodCard
          cardType={PaymentMethod.PROFORMA}
          isActive={cardType === PaymentMethod.PROFORMA}
          changeHandler={changeHandler}
        />
      </div>
      <AlertInline
        variant={ALERT_VARIANT.warningColor}
        isFullWidth={true}
        description={intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodNotification' })}
      />
    </div>
  )
}

export default PaymentMethodCards
