import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { cleanObject } from '../../common/utils'
import { API_URL } from '../../const'
import { ThunkResult } from '../../store/store'
import { customAxios } from '../../utils'
import {
  FilterKey,
  IAnalyticsForm,
  IDashboardBodyRequest,
  IDashboards,
  IParams,
  IQueryBodyRequest,
  IQueryEditing,
  IQueryInsight,
  IQueryItem,
  ISelectedFilters,
  StoreFilterKeys,
} from './types'

export const SELECTED_ANALYTICS_DASHBOARD_ID = 'selectedAnalyticsDashboardId'

export enum AnalyticsActionTypes {
  SET_FILTER_PLATFORMS = 'SET_FILTER_PLATFORMS',
  SET_FILTER_ACCOUNTS = 'SET_FILTER_ACCOUNTS',
  SET_FILTER_CAMPAIGNS = 'SET_FILTER_CAMPAIGNS',
  CLEAR_FILTER_CAMPAIGNS = 'CLEAR_FILTER_CAMPAIGNS',
  SET_FILTER_ADSETS = 'SET_FILTER_ADSETS',
  CLEAR_FILTER_ADSETS = 'CLEAR_FILTER_ADSETS',
  SET_FILTER_ADS = 'SET_FILTER_ADS',
  CLEAR_FILTER_ADS = 'CLEAR_FILTER_ADS',
  SET_FILTER = 'SET_FILTER',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS',
  SET_SELECTED_FILTER = 'SET_SELECTED_FILTER',
  SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS',
  CLEAR_FILTER_GROUP = 'CLEAR_FILTER_GROUP',
  SET_DASHBOARDS = 'SET_DASHBOARDS',
  SET_DASHBOARDS_LOADING = 'SET_DASHBOARDS_LOADING',
  SET_DASHBOARD = 'SET_DASHBOARD',
  SET_SELECTED_DASHBOARD = 'SET_SELECTED_DASHBOARD',
  DELETE_DASHBOARD = 'DELETE_DASHBOARD',
  SET_DASHBOARD_LOADING = 'SET_DASHBOARD_LOADING',
  SET_QUERY = 'SET_QUERY',
  CLEAR_QUERY = 'CLEAR_QUERY',
  SET_QUERIES = 'SET_QUERIES',
  UPDATE_QUERY = 'UPDATE_QUERY',
  SET_QUERY_INSIGHTS = 'SET_QUERY_INSIGHTS',
  CLEAR_QUERY_INSIGHTS = 'CLEAR_QUERY_INSIGHTS',
  SET_QUERY_METRICS_EDITING = 'SET_QUERY_METRICS_EDITING',
  SET_QUERY_DIMENSION_EDITING = 'SET_QUERY_DIMENSION_EDITING',
  RESET_QUERY_EDITING = 'RESET_QUERY_EDITING',
  SET_QUERY_GROUPING_EDITING = 'SET_QUERY_GROUPING_EDITING',
  SET_QUERY_PERIOD_EDITING = 'SET_QUERY_PERIOD_EDITING',
  SET_QUERY_EXCLUDED_GRAPHS_EDITING = 'SET_QUERY_EXCLUDED_GRAPHS_EDITING',
  SET_QUERY_REDIRECT = 'SET_QUERY_REDIRECT',
  RESET_CONSTRUCTOR_FILTERS = 'RESET_CONSTRUCTOR_FILTERS',
  SET_QUERY_FILTERS_EDITING = 'SET_QUERY_FILTERS_EDITING',
  SET_QUERY_INSIGHTS_LOADING = 'SET_QUERY_INSIGHTS_LOADING',
  SET_QUERY_EXCLUDED_GRAPHS = 'SET_QUERY_EXCLUDED_GRAPHS',
}

export function clearFilters(): AnyAction {
  return {
    type: AnalyticsActionTypes.CLEAR_FILTERS,
  }
}

export function clearSelectedFilters(): AnyAction {
  return {
    type: AnalyticsActionTypes.RESET_SELECTED_FILTERS,
  }
}

export function clearFilterCampaigns(): AnyAction {
  return {
    type: AnalyticsActionTypes.CLEAR_FILTER_CAMPAIGNS,
  }
}

export function clearFilterAdsets(): AnyAction {
  return {
    type: AnalyticsActionTypes.CLEAR_FILTER_ADSETS,
  }
}

export function clearFilterAds(): AnyAction {
  return {
    type: AnalyticsActionTypes.CLEAR_FILTER_ADS,
  }
}

export function getFilterAccounts(
  dashboardId: string | null,
  queryId: number | null = null,
  q?: string
): ThunkResult<Promise<any>> {
  let query = queryId ? `?query_id=${queryId}` : `?dashboard_id=${dashboardId}`
  query = q ? `${query}&q=${q}` : query

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/analytics/filter_instances/accounts/${query}`, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_FILTER_ACCOUNTS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getFilterCampaigns(
  dashboardId: string | null,
  queryId: number | null = null,
  q?: string,
  page?: number | null
): ThunkResult<Promise<any>> {
  const query = queryId ? `?query_id=${queryId}` : `?dashboard_id=${dashboardId}`

  const BASE_URL = `${API_URL}/analytics/filter_instances/campaigns/${query}`

  const URL = page ? `${BASE_URL}&page=${page}` : `${BASE_URL}`
  const GET_URL = page && q ? `${URL}&q=${q}` : q ? `${BASE_URL}&q=${q}` : URL

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(GET_URL, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_FILTER_CAMPAIGNS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getFilterAdsets(
  dashboardId: string | null,
  queryId: number | null = null,
  q?: string,
  page?: number | null
): ThunkResult<Promise<any>> {
  const query = queryId ? `?query_id=${queryId}` : `?dashboard_id=${dashboardId}`

  const BASE_URL = `${API_URL}/analytics/filter_instances/adsets/${query}`

  const URL = page ? `${BASE_URL}&page=${page}` : `${BASE_URL}`
  const GET_URL = page && q ? `${URL}&q=${q}` : q ? `${BASE_URL}&q=${q}` : URL

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(GET_URL, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_FILTER_ADSETS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getFilterAds(
  dashboardId: string | null,
  queryId: number | null = null,
  q?: string,
  page?: number | null
): ThunkResult<Promise<any>> {
  const query = queryId ? `?query_id=${queryId}` : `?dashboard_id=${dashboardId}`

  const BASE_URL = `${API_URL}/analytics/filter_instances/ads/${query}`

  const URL = page ? `${BASE_URL}&page=${page}` : `${BASE_URL}`
  const GET_URL = page && q ? `${URL}&q=${q}` : q ? `${BASE_URL}&q=${q}` : URL

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(GET_URL, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_FILTER_ADS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getFilterPlatforms(
  dashboardId: string | null,
  queryId: number | null = null
): ThunkResult<Promise<any>> {
  const query = queryId ? `?query_id=${queryId}` : `?dashboard_id=${dashboardId}`

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/analytics/filter_instances/platforms/${query}`, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_FILTER_PLATFORMS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setFilters(uuid: string, filterParams: any): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/analytics/dashboard/${uuid}/`, 'PATCH', { params: cleanObject(filterParams) }, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_FILTER,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setSelectedFilter(filterKey: FilterKey, selectedFilter: any): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_SELECTED_FILTER,
    filterKey,
    selectedFilter,
  }
}

export function setSelectedFilters(
  storeSelectedFilterKey: StoreFilterKeys,
  selectedFilters: ISelectedFilters
): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_SELECTED_FILTERS,
    storeSelectedFilterKey,
    selectedFilters,
  }
}

export function cleatFilterGroup(filterKey: FilterKey): AnyAction {
  return {
    type: AnalyticsActionTypes.CLEAR_FILTER_GROUP,
    filterKey,
  }
}

export function getDashboards(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/analytics/dashboards/`, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_DASHBOARDS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getDashboard(uuid: string, queryId: string): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/analytics/dashboard/${uuid}/${queryId}/`, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_DASHBOARD,
          data: {
            [queryId]: response.data,
          },
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setSelectedDashboard(dashboard: IDashboards): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_SELECTED_DASHBOARD,
    data: dashboard,
  }
}

export function setDashboardsLoading(): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_DASHBOARDS_LOADING,
  }
}

export function setDashboardLoading(queryId: string, params: IParams): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_DASHBOARD_LOADING,
    data: {
      queryId,
      params,
    },
  }
}

export function setQueryParams(uuid: string, queryId: string, params: IParams): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    const cleanParams = cleanObject(params)
    dispatch(setDashboardLoading(queryId, cleanParams))
    return customAxios(`${API_URL}/analytics/dashboard/${uuid}/${queryId}/`, 'PATCH', { params: cleanParams }, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_DASHBOARD,
          data: {
            [queryId]: response.data,
          },
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function addDashboard(body: IAnalyticsForm): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/analytics/dashboards/`, 'POST', body, true)
      .then((response: AxiosResponse<IDashboards>) => {
        window.localStorage.setItem(SELECTED_ANALYTICS_DASHBOARD_ID, response.data.id)

        dispatch(getDashboards())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateDashboard(uuid: string, body: IDashboardBodyRequest): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/analytics/dashboard/${uuid}/`, 'PATCH', body, true)
      .then((response: AxiosResponse<IDashboards>) => {
        dispatch(getDashboards())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function deleteDashboard(uuid: string): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/analytics/dashboard/${uuid}/`, 'DELETE', null, true)
      .then((response: AxiosResponse<IDashboards>) => {
        dispatch(getDashboards())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getAllQueries(): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/analytics/queries/`, 'GET', null, true)
      .then((response: AxiosResponse<IDashboards>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_QUERIES,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function addQuery(body: IAnalyticsForm): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/analytics/queries/`, 'POST', body, true)
      .then((response: AxiosResponse<IDashboards>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_QUERY,
          data: response.data,
        })
        dispatch(getAllQueries())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function clearQuery(): AnyAction {
  return {
    type: AnalyticsActionTypes.CLEAR_QUERY,
  }
}

export function updateQuery(id: string, body: IQueryBodyRequest): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/analytics/queries/${id}/`, 'PATCH', body, true)
      .then((response: AxiosResponse<IDashboards>) => {
        dispatch({
          type: AnalyticsActionTypes.UPDATE_QUERY,
          data: response.data,
        })
        dispatch(getAllQueries())
        dispatch(getDashboards())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function deleteQuery(id: number): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/analytics/queries/${id}/`, 'DELETE', null, true)
      .then((response: AxiosResponse<IDashboards>) => {
        dispatch(getAllQueries())
        dispatch(getDashboards())
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateDraftQuery(
  query: IQueryItem,
  params: IParams,
  queryEditing: IQueryEditing
): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    const body = {
      draft_params: {
        ...query.params,
        ...(queryEditing.isDimensionEditing ? { dimension: query.draft_params.dimension } : {}),
        ...(queryEditing.isMetricsEditing ? { metrics: query.draft_params.metrics } : {}),
        ...(queryEditing.isPeriodEditing ? { period: query.draft_params.period } : {}),
        ...(queryEditing.isGroupingEditing ? { grouping: query.draft_params.grouping } : {}),
        ...(queryEditing.isFiltersEditing ? { filters: query.draft_params.filters } : {}),
        ...params,
      },
    }

    return customAxios(`${API_URL}/analytics/queries/${query.id}/`, 'PATCH', body, true)
      .then((response: AxiosResponse<IQueryItem>) => {
        dispatch({
          type: AnalyticsActionTypes.UPDATE_QUERY,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getQueryInsights(id: number, isEditing = false): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    let url = `${API_URL}/analytics/queries/${id}/insights/`

    if (isEditing) {
      url = `${url}?edit=true`
    }

    dispatch(setQueryInsightsLoading())
    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<IDashboards>) => {
        dispatch({
          type: AnalyticsActionTypes.SET_QUERY_INSIGHTS,
          data: response.data,
        })
        dispatch(setQueryInsightsLoading(false))
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setQueryMetricsEditing(): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_METRICS_EDITING,
  }
}

export function setQueryDimensionEditing(): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_DIMENSION_EDITING,
  }
}

export function setQueryGroupingEditing(): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_GROUPING_EDITING,
  }
}

export function setQueryPeriodEditing(): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_PERIOD_EDITING,
  }
}

export function setQueryExcludedGraphsEditing(): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_EXCLUDED_GRAPHS_EDITING,
  }
}

export function resetQueryEditing(): AnyAction {
  return {
    type: AnalyticsActionTypes.RESET_QUERY_EDITING,
  }
}

export function clearQueryInsights(): AnyAction {
  return {
    type: AnalyticsActionTypes.CLEAR_QUERY_INSIGHTS,
  }
}

export function setQueryRedirect(pathname = ''): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_REDIRECT,
    data: pathname,
  }
}

export function resetConstructorFilters(): AnyAction {
  return {
    type: AnalyticsActionTypes.RESET_CONSTRUCTOR_FILTERS,
  }
}

export function setQueryFiltersEditing(): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_FILTERS_EDITING,
  }
}

export function setQueryInsightsLoading(value = true): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_INSIGHTS_LOADING,
    data: value,
  }
}

export function setQueryExcludedGraphs(graphs: IQueryInsight[]): AnyAction {
  return {
    type: AnalyticsActionTypes.SET_QUERY_EXCLUDED_GRAPHS,
    data: graphs,
  }
}

export function getQueryXLSX(id: number): AxiosPromise<any> {
  return customAxios(
    `${API_URL}/analytics/queries/${id}/xlsx/`,
    'GET',
    null,
    true,
    {
      'Content-type': 'application/vnd.ms-excel',
    },
    'arraybuffer'
  )
    .then((response: AxiosResponse<any>): any => {
      return response.data
    })
    .catch((error: AxiosError) => Promise.reject(error))
}
