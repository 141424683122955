export enum AppActionTypes {
  SET_LOADED = 'SET_LOADED',
  SET_MODAL = 'SET_MODAL',
  SET_COUNTRIES = 'SET_COUNTRIES',
  TOGGLE_MENU = 'TOGGLE_MENU',
  TOGGLE_FINANCE_MENU = 'TOGGLE_FINANCE_MENU',
  TOGGLE_TOOL_MENU = 'TOGGLE_TOOL_MENU',
  TOGGLE_ANALYTICS_MENU = 'TOGGLE_ANALYTICS_MENU',
  SET_BUSINESS_PROFILE = 'SET_BUSINESS_PROFILE',
  SET_CURRENT_BUSINESS_PROFILE = 'SET_CURRENT_BUSINESS_PROFILE',
  SET_LOADING_COUNTRIES = 'SET_LOADING_COUNTRIES',
}

export enum MODALS {
  BUSINESS_PROFILE = 'businessProfile',
  REDIRECT_EVIDO = 'redirectEvido',
}

export interface IModal {
  name: MODALS
  data?: any
}

export interface IAppState {
  readonly loaded: boolean
  readonly isShowedModal: boolean
  readonly isMenuOpened: boolean
  readonly isFinanceMenuOpened: boolean
  readonly isToolMenuOpened: boolean
  readonly isAnalyticsMenuOpened: boolean
  readonly modals: IModal | null
  readonly hasSuccessBusinessProfile: boolean
  readonly hasSuccessCurrentBusinessProfile: boolean
  readonly countries: []
  readonly isCountriesLoading: boolean
}
