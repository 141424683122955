import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'

import ErrorBoundary from './components/ErrorBoundary'
import Router from './containers/Router'
import store from './store/store'

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || '',
  }

  TagManager.initialize(tagManagerArgs)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    normalizeDepth: 5,
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Router />
    </ErrorBoundary>
  </Provider>
)
