import { ReactNode } from 'react'

import { IDropdownItem } from '../../components/Dropdown/types'
import { ISelectItem as ISelectItemType } from '../../components/Select/types'

export enum AudiencesActionTypes {
  SET_INDUSTRIES = 'SET_INDUSTRIES',
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_REPORT_STATUS = 'SET_REPORT_STATUS',
  SET_CAMPAIGN_FILTER = 'SET_CAMPAIGN_FILTER',
  SET_CAMPAIGN_ADSET = 'SET_CAMPAIGN_ADSET',
  SET_CAMPAIGNS = 'SET_CAMPAIGNS',
  SET_SELECTED_INDUSTRY = 'SET_SELECTED_INDUSTRY',
  SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT',
  SET_FORECAST = 'SET_FORECAST',
  ADD_SELECTED_TARGETING = 'ADD_SELECTED_TARGETING',
  ADD_USED_SELECTED_TARGETING = 'ADD_USED_SELECTED_TARGETING',
  REMOVE_SELECTED_TARGETING = 'REMOVE_SELECTED_TARGETING',
  CLEAR_SELECTED_TARGETING = 'CLEAR_SELECTED_TARGETING',
  SET_CONFIRM_MODAL = 'SET_CONFIRM_MODAL',
  SET_ERROR_MODAL = 'SET_ERROR_MODAL',
  SET_SUCCESS_MODAL = 'SET_SUCCESS_MODAL',
  SET_ERROR_MODAL_OBJECTS = 'SET_ERROR_MODAL_OBJECTS',
  SET_MODAL_COPY_MODE = 'SET_MODAL_COPY_MODE',
  SET_MODAL_ACTIVATE_MODE = 'SET_MODAL_ACTIVATE_MODE',
  SET_MODAL_LOADER = 'SET_MODAL_LOADER',
  SET_MODAL_NULL_MODES = 'SET_MODAL_NULL_MODES',
  RESET_AUDIENCES_STATE = 'RESET_AUDIENCES_STATE',
  SET_HEADER_NOTIFICATION_VISIBILITY = 'SET_HEADER_NOTIFICATION_VISIBILITY',
  SET_DID_USE_NOTIFICATION_VISIBILITY = 'SET_DID_USE_NOTIFICATION_VISIBILITY',
}

export interface ISelectItem {
  key: string
  name: string | ReactNode
}

export interface IIndustry {
  id: number
  title: string
  approximate_count: string
}

export interface IAudiencesState {
  readonly currentStep: number
  readonly industries: IIndustry[]
  readonly reportStatus: IReportStatus
  readonly campaignFilter: ISelectItem
  readonly campaigns: IReportCampaign[]
  readonly campaignAdset: IReportAdset | null
  readonly selectedIndustry?: ISelectItemType
  readonly selectedAccount: IDropdownItem | null
  readonly selectedTargeting: IReportTargeting[]
  readonly forecast: IForecast | null
  readonly isConfirmModalShown: boolean
  readonly isErrorModalShown: boolean
  readonly isSuccessModalShown: boolean
  readonly errorObjects: IErrorModalObject[]
  readonly modalCopyMode: ModalModeTypes
  readonly modalActivateMode: ModalModeTypes
  readonly isModalLoaderShown: boolean
  readonly isHiddenHeaderNotification: boolean
  readonly isHiddenDidUseNotification: boolean
}

export enum ModalModeBinaryTypes {
  YES = 'yes',
  NO = 'no',
}

export type ModalModeTypes = ModalModeBinaryTypes | null

export interface IErrorModalObject {
  error_message: string
  error_title: string
  detail: string
}

export interface IReportBodyRequest {
  adaccount_id?: string
  industry: number
  uuid?: string
  region: string
  type_of_selling: string[]
}

export interface IReportIndustryInfo {
  count_of_adsets: number
  count_of_targetings: number
  description: string
  name: string
}

export type TargetingFilterTypes = 'popular' | 'unusual' | 'new'

export interface IReportSlugName {
  description: string
  score: number
  slug: string
  title: string
}

export interface IReportTargetingObject {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  results: IReportTargeting[]
}

export interface IReportTargeting {
  already_used?: boolean
  id: number
  name: string
  targeting_type: string
  usage_score?: number
  count_of_using?: number
}

export interface IReportStatus {
  benchmarks_is_ready: boolean
  targetings_is_ready: boolean
}

export enum CampaignStatusTypes {
  WITH_ISSUES = 'WITH_ISSUES',
  PAUSED = 'PAUSED',
  IN_PROCESS = 'IN_PROCESS',
  ACTIVE = 'ACTIVE',
  LEARNING_PHASE = 'LEARNING_PHASE',
}

export enum AdsetStatusTypes {
  WITH_ISSUES = 'WITH_ISSUES',
  CAMPAIGN_PAUSED = 'CAMPAIGN_PAUSED',
  PAUSED = 'PAUSED',
  IN_PROCESS = 'IN_PROCESS',
  ACTIVE = 'ACTIVE',
  LEARNING_PHASE = 'LEARNING_PHASE',
}

export interface IReportCampaign {
  id: string
  name: string
  status: CampaignStatusTypes
  adsets: IReportAdset[]
}

export interface IReportAdset {
  id: string
  name: string
  campaign: IReportCampaign
  status: AdsetStatusTypes
  targetings: IReportTargeting[]
}

export interface ITargetingBody {
  targeting: IReportTargeting[]
}

export interface IProposalTargetingsBody {
  adset_id: string
  proposal_targetings: IReportTargeting[]
}

export interface IForecast {
  adset_id: string
  daily_budget: number
  proposal_targetings: IReportTargeting[]
  main_forecast: IForecastData
  proposal_forecast: IForecastData
}

export interface IForecastData {
  nearest_reach: number
  nearest_actions: number
  forecast_information: IForecastInformation
}

export interface IForecastInformation {
  data: IForecastDataType[]
  interpolate_curve_actions: number[][]
  interpolate_curve_reach: number[][]
}

export interface IForecastDataType {
  daily_outcomes_curve: IForecastDailyOutcomesCurve[] // если 1 элемент - выводи заглшку
  estimate_dau: number
  estimate_mau_lower_bound: number // охват нижний за месяц
  estimate_mau_upper_bound: number // охват верхний за месяц
  estimate_ready: boolean
}

export interface IForecastDailyOutcomesCurve {
  spend: number // X  // бюджет день
  reach: number // охват
  impressions: number // показы
  actions: number // Y  // конверсии
}
