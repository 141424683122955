import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4657_15644)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#F0F0F0"
        />
        <path
          d="M19.1304 19.9996H39.9999C39.9999 18.1945 39.7593 16.4457 39.3109 14.7822H19.1304V19.9996Z"
          fill="#D80027"
        />
        <path
          d="M19.1304 9.56489H37.0644C35.8401 7.56708 34.2747 5.80122 32.4496 4.34747H19.1304V9.56489Z"
          fill="#D80027"
        />
        <path
          d="M20 39.9997C24.7069 39.9997 29.0333 38.3728 32.4497 35.6519H7.55029C10.9667 38.3728 15.293 39.9997 20 39.9997Z"
          fill="#D80027"
        />
        <path
          d="M2.93545 30.4344H37.0645C38.0474 28.8306 38.8097 27.0775 39.311 25.217H0.688965C1.19029 27.0775 1.95256 28.8306 2.93545 30.4344Z"
          fill="#D80027"
        />
        <path
          d="M9.26437 3.12328H11.087L9.39164 4.35492L10.0392 6.34781L8.34398 5.11617L6.64875 6.34781L7.20813 4.62617C5.71547 5.86953 4.40719 7.32625 3.32906 8.94938H3.91305L2.83391 9.73336C2.66578 10.0138 2.50453 10.2987 2.35 10.5879L2.86531 12.1739L1.90391 11.4754C1.66492 11.9817 1.44633 12.4995 1.24984 13.028L1.81758 14.7755H3.91305L2.21773 16.0071L2.86531 18L1.17008 16.7684L0.154609 17.5062C0.0529687 18.3232 0 19.1554 0 20H20C20 8.95437 20 7.65219 20 0C16.0491 0 12.366 1.14609 9.26437 3.12328ZM10.0392 18L8.34398 16.7684L6.64875 18L7.29633 16.0071L5.60102 14.7755H7.69648L8.34398 12.7826L8.99148 14.7755H11.087L9.39164 16.0071L10.0392 18ZM9.39164 10.181L10.0392 12.1739L8.34398 10.9423L6.64875 12.1739L7.29633 10.181L5.60102 8.94938H7.69648L8.34398 6.95648L8.99148 8.94938H11.087L9.39164 10.181ZM17.2131 18L15.5179 16.7684L13.8227 18L14.4702 16.0071L12.7749 14.7755H14.8704L15.5179 12.7826L16.1654 14.7755H18.2609L16.5655 16.0071L17.2131 18ZM16.5655 10.181L17.2131 12.1739L15.5179 10.9423L13.8227 12.1739L14.4702 10.181L12.7749 8.94938H14.8704L15.5179 6.95648L16.1654 8.94938H18.2609L16.5655 10.181ZM16.5655 4.35492L17.2131 6.34781L15.5179 5.11617L13.8227 6.34781L14.4702 4.35492L12.7749 3.12328H14.8704L15.5179 1.13039L16.1654 3.12328H18.2609L16.5655 4.35492Z"
          fill="#0052B4"
        />
      </g>
      <defs></defs>
    </svg>
  )
}
