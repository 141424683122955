import { createUseStyles } from 'react-jss'

import { WidthBPs } from '../../../../../const/commonStyles'

export default createUseStyles(
  {
    root: {
      width: '100%',
      maxWidth: 386,
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        minHeight: 464,
        height: '100%',
      },
    },
    alert: {
      marginBottom: 24,
    },
    button: {
      marginBottom: 10,
      flexGrow: 0,

      '&:last-child': {
        marginBottom: 0,
      },
    },
    chatButton: {
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
      color: 'inherit',
    },
  },
  { name: 'IndividualInfo' }
)
