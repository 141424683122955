import { createUseStyles } from 'react-jss'

import { BackgroundGrey, TextGreyBase, White } from '../../const/colors'
import { WidthBPs } from '../../const/commonStyles'
import { FontBody1, FontH4 } from '../../const/fontStyles'
import { IModalSMBProps } from './types'

export default createUseStyles(
  {
    root: {
      display: 'none',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: `rgba(28, 31, 35, 0.75)`,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    isOpen: {
      display: 'flex',
    },
    modal: {
      position: 'relative',
      backgroundColor: White,
      boxShadow: '0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)',
      borderRadius: 8,
      maxWidth: (props: IModalSMBProps) => (props.maxWidth ? props.maxWidth : 460),
      width: 'calc(100% - 40px)',
    },
    closeButton: {
      position: 'absolute',
      top: 18,
      right: 18,
    },
    header: {
      padding: '24px 56px 0 24px',
      ...FontH4,
      color: TextGreyBase,
    },
    body: {
      padding: 24,
      ...FontBody1,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 24px 24px 24px',
    },
    fullHeight: {
      background: BackgroundGrey,
      alignItems: 'flex-start',
      overflowY: 'auto',
      top: 50,
      zIndex: 0,

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        background: `rgba(28, 31, 35, 0.75)`,
        alignItems: 'center',
        overflow: 'hidden',
        top: 0,
        zIndex: 1000,
      },

      '& $modal': {
        width: 'calc(100% - 30px)',
        boxShadow: 'none',
        marginBottom: 24,
        background: 'none',

        [`@media (min-width: ${WidthBPs.SMALL})`]: {
          width: 'calc(100% - 40px)',
          boxShadow: '0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)',
          marginBottom: 0,
          background: White,
        },
      },

      '& $header': {
        background: BackgroundGrey,
        padding: '34px 0 30px',

        [`@media (min-width: ${WidthBPs.SMALL})`]: {
          background: 'none',
          padding: '24px 56px 0 24px',
        },
      },

      '& $body': {
        background: White,
        borderRadius: 8,
        boxShadow: '0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)',

        [`@media (min-width: ${WidthBPs.SMALL})`]: {
          background: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
  { name: 'ModalSMB' }
)
