import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5195_4838)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="#2AABEE"
        />
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="url(#paint0_linear_5195_4838)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.0532 19.7889C14.8836 17.2487 18.7715 15.574 20.7167 14.7649C26.271 12.4547 27.4251 12.0534 28.1773 12.0401C28.3428 12.0372 28.7127 12.0782 28.9523 12.2727C29.1546 12.4369 29.2103 12.6586 29.237 12.8143C29.2636 12.97 29.2968 13.3246 29.2704 13.6016C28.9694 16.7641 27.6671 24.4386 27.0045 27.9806C26.7241 29.4793 26.1721 29.9818 25.6377 30.031C24.4762 30.1379 23.5943 29.2635 22.4694 28.5261C20.7091 27.3722 19.7147 26.6539 18.0061 25.528C16.0314 24.2267 17.3115 23.5115 18.4368 22.3427C18.7313 22.0368 23.8487 17.3823 23.9477 16.96C23.9601 16.9072 23.9716 16.7104 23.8546 16.6064C23.7377 16.5025 23.5651 16.538 23.4406 16.5663C23.264 16.6064 20.452 18.465 15.0044 22.1422C14.2063 22.6903 13.4833 22.9574 12.8355 22.9434C12.1214 22.9279 10.7478 22.5396 9.7266 22.2077C8.4741 21.8005 7.47864 21.5853 7.56532 20.8938C7.61047 20.5337 8.10643 20.1654 9.0532 19.7889Z"
          fill="white"
        />
        <path
          d="M8 16C12.4185 16 16 12.4185 16 8C16 3.5815 12.4185 0 8 0C3.5815 0 0 3.5815 0 8C0 12.4185 3.5815 16 8 16Z"
          fill="#0098EA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15.9333C12.3817 15.9333 15.9333 12.3817 15.9333 8C15.9333 3.61829 12.3817 0.0666667 8 0.0666667C3.61829 0.0666667 0.0666667 3.61829 0.0666667 8C0.0666667 12.3817 3.61829 15.9333 8 15.9333ZM17 8C17 12.9708 12.9708 17 8 17C3.02919 17 -1 12.9708 -1 8C-1 3.02919 3.02919 -1 8 -1C12.9708 -1 17 3.02919 17 8Z"
          fill="white"
        />
        <path
          d="M11.0636 4.20001H4.96534C3.84414 4.20001 3.1337 5.40939 3.69793 6.38772L7.46111 12.9106C7.70667 13.3365 8.32224 13.3365 8.5678 12.9106L12.3321 6.38716C12.8958 5.41106 12.1848 4.20001 11.0641 4.20001H11.0636ZM7.45832 10.954L6.63849 9.36791L4.66063 5.83074C4.63058 5.77791 4.615 5.71809 4.61546 5.65731C4.61593 5.59653 4.63242 5.53695 4.66327 5.48458C4.69413 5.43222 4.73825 5.38892 4.79119 5.35906C4.84413 5.3292 4.90401 5.31383 4.96478 5.31451H7.45665V10.9551L7.45776 10.954H7.45832ZM11.3666 5.83019L9.38986 9.36846L8.57003 10.954V5.3134H11.063C11.3365 5.3134 11.4972 5.6036 11.3666 5.83019Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_5195_4838" x1="20" y1="0" x2="20" y2="39.7033" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
        <clipPath id="clip0_5195_4838">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
