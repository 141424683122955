import { AgencyDocsReceivedStatus } from '@/types'

export enum UserActionTypes {
  CLEAR_PROFILE = 'CLEAR_PROFILE',
  SET_AUTHORIZED = 'SET_AUTHORIZED',
  SET_ATOKEN = 'SET_ATOKEN',
  SET_RTOKEN = 'SET_RTOKEN',
  SET_EXPIRES = 'SET_EXPIRES',
  SET_EMAIL = 'SET_EMAIL',
  SET_HAS_PROFILE = 'SET_HAS_PROFILE',
  SET_PROFILE = 'SET_PROFILE',
  SET_SUBSCRIBE = 'SET_SUBSCRIBE',
  SET_ALLOW_CREATE_ACCOUNTS = 'SET_ALLOW_CREATE_ACCOUNTS',
  SET_MAIL_PROVIDER = 'SET_MAIL_PROVIDER',
  SET_GA_USER_ID = 'SET_GA_USER_ID',
  SET_FBP = 'SET_FBP',
  SET_AVAILABLE_PLUGINS = 'SET_AVAILABLE_PLUGINS',
  REMOVE_AVAILABLE_PLUGIN = 'REMOVE_AVAILABLE_PLUGIN',
  SET_FB_CREDENTIAL = 'SET_FB_CREDENTIAL',
  SET_TIKTOK_MANAGER = 'SET_TIKTOK_MANAGER',
  REMOVE_CONNECTED_PLUGIN = 'REMOVE_CONNECTED_PLUGIN',
  SET_CONNECTED_PLUGIN = 'SET_CONNECTED_PLUGIN',
  SET_USER = 'SET_USER',
  SET_INTERCOM_PROFILE = 'SET_INTERCOM_PROFILE',
  SET_USER_LANGUAGE = 'SET_USER_LANGUAGE',
}

export enum UserProfileStatuses {
  NEW = 'new',
  APPROVED = 'approved',
  DOUBTFUL = 'doubtful',
  REJECTED = 'rejected',
  WAITDOCS = 'waitdocs',
}

export enum Language {
  Russian = 'ru',
  English = 'en',
}

export enum PaymentMethod {
  CARD = 'card',
  PROFORMA = 'proforma',
}

export enum ProfileType {
  BUSINESS = 'business',
  INDIVIDUAL = 'individual',
}

export enum CloseDocType {
  payment = 'payment',
  spend = 'spend',
}

export enum IndustryType {
  AGENCY = 'agency',
  CLIENT = 'client',
}

export interface IUserCountry {
  code: string
  name: string
}

export interface IUserState {
  readonly language: Language
  readonly email: string
  readonly first_name: string
  readonly accessToken?: string
  readonly has_profile?: boolean
  readonly profile: IUserProfile
  readonly isAuthorized: boolean
  readonly refreshToken?: string
  readonly subscribe?: boolean
  readonly tokenExpire?: number
  readonly mailProvider?: string
  readonly ga_user_id: string
  readonly fbp: string | null
  readonly fbc: string | null
  readonly ab_tests: IABTest[]
  readonly telegram_enabled: boolean
  readonly fb_credential_enabled: boolean
  readonly id: number | null
  readonly country: IUserCountry
  readonly user_timezone: string
  readonly phone: string
  readonly intercom: IIntercomProfile | null
}

export interface IBusinessInfo {
  business_name: string
  business_country: string
  business_address: string
  business_ceo: string
  business_phone: string
  vat_registration_number: string
}

export interface IBankDetails {
  bank: string
  bank_address: string
  account_number_usd: string
  sort_code: string
  iban: string
  swift: string
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
}

export enum CurrencySign {
  USD = '$',
  EUR = '€',
  TON = 'TON',
  AZN = 'AZN',
}

interface IBalance {
  [Currency.EUR]: number
  [Currency.USD]: number
}

export enum PAYS_TO_TYPES {
  UK_NODDINGTON = 'uk_noddington',
  KZ_ADHAND = 'kz_adhand',
  AZ_ADHAND = 'az_adhand',
  GE_ADHAND = 'ge_adhand',
  AM_ADHAND = 'am_adhand',
  CY_ADHAND = 'cy_adhand',
  AE_ADHAND = 'ae_adhand',
}

export interface IUserProfile {
  readonly actual_address: string
  readonly allow_create_accounts: {
    facebook: boolean
    tiktok: boolean
    telegram: boolean
  }
  readonly min_card_payment: IBalance
  readonly pays_to: PAYS_TO_TYPES
  readonly balance: IBalance
  readonly business_manager_id: string | null
  readonly ceo: string
  readonly closedoc_type: CloseDocType
  readonly documents_methods: string
  readonly full_name: string
  readonly inn: string
  readonly kpp: string
  readonly legal_address: string
  readonly business_info: null | IBusinessInfo
  readonly bank_details: null | IBankDetails
  readonly name: string
  readonly profile_type: ProfileType
  readonly promocode: number | null
  readonly website: string
  readonly autopayment: boolean
  readonly optimizer: boolean
  readonly paying_customer: boolean
  readonly payment_method: PaymentMethod
  readonly industry: number | null
  readonly id: number | null
  readonly contract_agency_status: boolean
  readonly contract_client_status: boolean
  readonly agency_docs_received_status: AgencyDocsReceivedStatus
  readonly balance_product_tour: boolean
  readonly account_counts: {
    archived_accounts: number
    archived_fb: number
    archived_tiktok: number
    archived_telegram: number
    facebook: number
    tiktok: number
    telegram: number
  }
  readonly cashback: IUserCashBack | null
  readonly profile_checks: {
    payment: boolean
    policy: boolean
  }
  readonly onboarding_optimizer_is_finished: boolean
  readonly available_plugins: IUserAvailablePlugin[]
  readonly created_at: string
  readonly smart_targetings: boolean
  readonly fb_optimizer_auth: boolean
  readonly personal_phone: string | null
  readonly has_tiktok_manager: boolean
  readonly legal_status?: UserProfileStatuses
  readonly policy_status?: UserProfileStatuses
  readonly post_sale_onboarding: boolean
  readonly contract_type: string
  readonly facebook_commission_percent: number
  readonly antiban: boolean
  readonly connected_plugins: IUserPlugin[]
  readonly is_profile_agency: boolean
  readonly is_profile_client: boolean
  readonly agency_clients: IAgencyClient[]
  readonly agency: string
  readonly profile_is_approved: boolean
  readonly creatives_sent: boolean
  readonly is_last_30_days_spending: boolean
  readonly analytics_product_tour: boolean
  readonly tax_rate: number
  readonly is_country_confirmed: boolean
  readonly country_is_ready: boolean
  readonly workflow: UserWorkflow
}

export interface IUserCashBack {
  month: string
  facebook_amount: number
  telegram_amount: number
}

export interface IAuthorizeUserData {
  email: string
  password: string
}

export interface ISignupUserData {
  first_name: string
  email: string
  password: string
  password_repeat: string
  subscribe?: boolean
  promo?: string
  referer: string
  fbp?: string
  fbc?: string
  preferred_language: Language
  country?: string
}

export interface IChangePasswordData {
  old_password: string
  new_password: string
}

export interface ITokenRefreshResponse {
  readonly access: string
  readonly refresh: string
}

export interface IUserInfoResponse {
  email: string
  password: string
  has_profile: string
  promo: string
  subscribe: boolean
  ga_user_id: string
  fbc: string
  fbp: string
  ab_tests: IABTest[]
  telegram_enabled: boolean
  fb_credential_enabled: boolean
  id: number
  preferred_language: Language
  first_name: string
}

export interface ISignupResponse {
  email: string
  password: string
  promo: string
  subscribe: boolean
  ga_user_id: string
  id: number
}

export interface ISigninResponse {
  access: string
  refresh: string
}

export interface ICreateProfileData {
  profile_type: ProfileType
  source?: string
  website: string
  instagram_username?: string
  payment_method?: PaymentMethod
  industry?: number
  planned_platforms?: string[]
  planned_budget?: string
}

export interface IUpdateProfileData {
  actual_address?: string
  business_manager_id?: string
  documents_methods?: string
  personal_phone?: string
  website?: string
  instagram_username?: string
  source?: string
  contract_agency_status?: boolean
  agency_docs_received_status?: AgencyDocsReceivedStatus
  payment_method?: PaymentMethod
  closedoc_type?: CloseDocType
  profile_type?: ProfileType
  legal_status?: UserProfileStatuses
}

export interface IUpdateUserData {
  fbp?: string
  country?: string
  user_timezone?: string
  phone?: string
  preferred_language?: Language
}

export type PluginTypes =
  | 'optimizer'
  | 'onboarding_optimizer_is_finished'
  | 'smart_targetings'
  | 'fb_optimizer_auth'
  | 'post_sale_onboarding'
  | 'antiban'
  | 'profile_is_approved'
  | 'creatives_sent'
  | 'analytics_product_tour'
  | 'is_country_confirmed'
  | 'country_is_ready'
  | 'balance_product_tour'

export interface IUserPlugin {
  created_at: string
  id: number
  plugin: PluginTypes
  updated_at: string
}

export interface IABTest {
  ab_test: string
  test_group: ABTestTypes
}

export type ABTestTypes = 'test' | 'control'

export interface IUserAvailablePlugin {
  name: string
  slug: PluginTypes
}

export interface IAgencyClient {
  id: number
  name: string
  contract_client_status: boolean
}

export interface ICountry {
  value: string
  display_name: string
}

export interface IIntercomProfile {
  email: string
  facebook_spend: string
  facebook_spend_past_30_days: string
  first_payment_date: string | null
  industry: string | null
  last_payment_date: string | null
  name: string
  phone: string
  policy_status: string
  profile_date: string
  profile_id: number
  signup_date: string
  social_media: string
  subindustry: string | null
  tiktok_spend: string
  tiktok_spend_past_30_days: string
  total_payments: string
  user_id: number
  user_status: string
  waiting_doc_reason: string
  website: string
  profile_name: string
  country: string
}

export enum UserWorkflow {
  standard = 'standard',
  partner = 'partner',
}
