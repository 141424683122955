import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5198_4964)">
        <path
          d="M20 40C31.0462 40 40 31.0462 40 20C40 8.95375 31.0462 0 20 0C8.95375 0 0 8.95375 0 20C0 31.0462 8.95375 40 20 40Z"
          fill="#0098EA"
        />
        <path
          d="M26.8288 11.1625H13.17C10.6588 11.1625 9.0675 13.8712 10.3313 16.0625L18.76 30.6725C19.31 31.6262 20.6888 31.6262 21.2388 30.6725L29.67 16.0612C30.9325 13.875 29.34 11.1625 26.83 11.1625H26.8288ZM18.7538 26.29L16.9175 22.7375L12.4875 14.815C12.4202 14.6966 12.3853 14.5626 12.3863 14.4265C12.3874 14.2904 12.4243 14.1569 12.4934 14.0396C12.5625 13.9224 12.6614 13.8254 12.7799 13.7585C12.8985 13.6916 13.0326 13.6572 13.1688 13.6587H18.75V26.2925L18.7525 26.29H18.7538ZM27.5075 14.8137L23.08 22.7387L21.2438 26.29V13.6562H26.8275C27.44 13.6562 27.8 14.3062 27.5075 14.8137Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5198_4964">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
