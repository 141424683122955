import { createUseStyles } from 'react-jss'

import { WidthBPs } from '../../../../../const/commonStyles'

export default createUseStyles(
  {
    root: {
      marginBottom: 24,
    },
    cardsWrapper: {
      display: 'grid',
      marginBottom: 32,
      gap: 24,

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        gridTemplateColumns: '1fr 1fr',
        marginBottom: 40,
      },
    },
  },
  { name: 'PaymentMethodCards' }
)
