import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { ALERT_VARIANT, AlertInline } from '../../../../../components/AlertInlineSMB'
import { ComboBox, COMBOBOX_SIZE, IComboBoxOption } from '../../../../../components/ComboBox'
import { FieldLabel } from '../../../../../components/FieldLabel'
import { GLOBAL_COUNTRIES } from '../../../../../const'
import { IBusinessProfileFormContent } from '../../types'
import useStyles from './styles'

interface IJurisdictionInfoProps {
  countries: IComboBoxOption[]
  isCountriesLoading: boolean
  onCountryChange: (value: IComboBoxOption) => void
  country: IBusinessProfileFormContent
}

const JurisdictionInfo = (props: IJurisdictionInfoProps) => {
  const { isCountriesLoading, countries, onCountryChange, country } = props

  const intl = useIntl()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h5 className={classes.title}>
        <FormattedMessage id="detailsPayment.modal.jurisdictionTitle" />
      </h5>
      <AlertInline
        variant={ALERT_VARIANT.warningColor}
        isFullWidth={true}
        description={intl.formatMessage({ id: 'detailsPayment.modal.jurisdictionAlert' })}
        className={classes.alert}
      />
      <div>
        <FieldLabel className={classes.label}>
          <FormattedMessage id="detailsPayment.modal.formJurisdictionLabel" />
        </FieldLabel>
        <ComboBox
          options={countries}
          onChange={onCountryChange}
          size={COMBOBOX_SIZE.big}
          placeholder={intl.formatMessage({ id: 'detailsPayment.modal.countryPlaceholder' })}
          noOptionsMessage={<FormattedMessage id="common.noResults" />}
          loadingMessage={<FormattedMessage id="common.waitLoading" />}
          isLoading={isCountriesLoading}
          searchFields={['label']}
          errorText={country.hasError && intl.formatMessage({ id: 'detailsPayment.modal.countryPlaceholder' })}
        />
      </div>
      {country.value === GLOBAL_COUNTRIES.RU && (
        <AlertInline
          variant={ALERT_VARIANT.negativeColor}
          isFullWidth={true}
          description={intl.formatMessage({ id: 'detailsPayment.modal.jurisdictionRuAlert' })}
          className={classes.ruAlert}
        />
      )}
    </div>
  )
}

export default JurisdictionInfo
