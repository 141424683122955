import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4657_15652)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#0052B4"
        />
        <path
          d="M20 7.82581L20.6476 9.8187H22.7429L21.0477 11.0503L21.6953 13.0432L20 11.8115L18.3047 13.0432L18.9523 11.0503L17.2571 9.8187H19.3525L20 7.82581Z"
          fill="#FFDA44"
        />
        <path
          d="M11.3918 11.3914L13.259 12.3427L14.7406 10.8611L14.4127 12.9306L16.2799 13.882L14.2102 14.2098L13.8824 16.2795L12.9311 14.4124L10.8616 14.7403L12.3432 13.2586L11.3918 11.3914Z"
          fill="#FFDA44"
        />
        <path
          d="M7.82617 19.9997L9.81906 19.3521V17.2567L11.0506 18.9521L13.0436 18.3045L11.8118 19.9997L13.0436 21.6949L11.0506 21.0474L9.81906 22.7427V20.6473L7.82617 19.9997Z"
          fill="#FFDA44"
        />
        <path
          d="M11.3918 28.608L12.3431 26.7408L10.8616 25.2592L12.9312 25.5871L13.8824 23.72L14.2102 25.7896L16.2798 26.1175L14.4129 27.0688L14.7406 29.1383L13.259 27.6567L11.3918 28.608Z"
          fill="#FFDA44"
        />
        <path
          d="M20 32.1736L19.3524 30.1807H17.2571L18.9524 28.9491L18.3047 26.9564L20 28.1879L21.6953 26.9564L21.0477 28.9491L22.7429 30.1807H20.6475L20 32.1736Z"
          fill="#FFDA44"
        />
        <path
          d="M28.6083 28.608L26.7412 27.6568L25.2595 29.1384L25.5874 27.0687L23.7205 26.1175L25.79 25.7896L26.1178 23.72L27.0691 25.5871L29.1386 25.2592L27.6569 26.741L28.6083 28.608Z"
          fill="#FFDA44"
        />
        <path
          d="M32.1738 19.9997L30.1809 20.6473V22.7427L28.9493 21.0474L26.9565 21.6949L28.1882 19.9997L26.9565 18.3045L28.9494 18.9521L30.1809 17.2567V19.3522L32.1738 19.9997Z"
          fill="#FFDA44"
        />
        <path
          d="M28.6083 11.3914L27.657 13.2586L29.1387 14.7403L27.069 14.4123L26.1178 16.2794L25.79 14.2098L23.7205 13.8819L25.5874 12.9307L25.2595 10.8612L26.7413 12.3428L28.6083 11.3914Z"
          fill="#FFDA44"
        />
      </g>
    </svg>
  )
}
