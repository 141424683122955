import React, { useCallback, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon'
import Popover from '../../../../components/Popover'
import { White } from '../../../../const/colors'
import { getProfile, updateUserData } from '../../../../store/user'
import { Language } from '../../../../store/user/types'
import { languages } from './consts'
import selector from './selector'
import useStyles from './styles'

const LanguageSwitch = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { language } = useSelector(selector)

  const [isOpened, open] = useState(false)

  const openHandler = useCallback(() => {
    open(!isOpened)
  }, [isOpened])

  const closeHandler = useCallback(() => {
    open(false)
  }, [isOpened])

  const languageHandler = useCallback(
    (newLanguage: Language) => {
      return async () => {
        await dispatch(updateUserData({ preferred_language: newLanguage }))
        dispatch(getProfile())
        closeHandler()
      }
    },
    [language]
  )

  return (
    <OutsideClickHandler onOutsideClick={closeHandler}>
      <div className={classes.wrapper}>
        <button className={classes.button} onClick={openHandler}>
          <span>{language}</span>
          <ArrowDownIcon fill={White} />
        </button>
        {isOpened && (
          <Popover className={classes.popup} right={true} topOffset={6}>
            {languages.map((item) => (
              <div
                className={cn(classes.item, { [classes.itemActive]: item.value === language })}
                key={item.value}
                onClick={languageHandler(item.value)}
              >
                {item.label}
              </div>
            ))}
          </Popover>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default LanguageSwitch
