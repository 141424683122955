import { Reducer } from 'redux'

import { FacebookPartnerActionTypes, IFacebookPartnerState } from './types'

import { IAdAccount } from '@/store/adaccounts/types'

export const initialState: IFacebookPartnerState = {
  step: 1,
  profile: {
    picture: '',
    name: '',
    is_valid: false,
    is_authorized: false,
    is_loading: true,
    errorMessage: '',
  },
  businessManager: {
    list: [],
    selectedBusinessManager: null,
    errorMessage: '',
    info: {
      business_type: null,
      count: null,
      selected_business_type: null,
    },
    isLoading: false,
  },
  businessPage: {
    list: [],
    isLoading: false,
  },
  applications: [],
  isSentForm: false,
  isFormLoading: false,
  errors: null,
}

const reducer: Reducer<IFacebookPartnerState> = (state = initialState, action: any) => {
  switch (action.type) {
    case FacebookPartnerActionTypes.SET_STEP: {
      return { ...state, step: action.data }
    }
    case FacebookPartnerActionTypes.SET_BUSINESS_MANAGERS: {
      return {
        ...state,
        businessManager: {
          ...state.businessManager,
          list: action.data,
        },
      }
    }
    case FacebookPartnerActionTypes.SET_BUSINESS_PAGES: {
      return {
        ...state,
        businessPage: {
          ...state.businessPage,
          list: action.data,
          isLoading: false,
        },
      }
    }
    case FacebookPartnerActionTypes.SET_BUSINESS_PAGES_LOADING: {
      return {
        ...state,
        businessPage: {
          ...state.businessPage,
          isLoading: action.data,
        },
      }
    }
    case FacebookPartnerActionTypes.SET_BUSINESS_MANAGER_ERROR: {
      return {
        ...state,
        businessManager: {
          ...state.businessManager,
          info: {
            business_type: null,
            count: null,
            selected_business_type: null,
          },
          errorMessage: action.data,
        },
      }
    }
    case FacebookPartnerActionTypes.SET_FACEBOOK_PROFILE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          errorMessage: '',
          ...action.data,
        },
      }
    }
    case FacebookPartnerActionTypes.SELECT_BUSINESS_MANAGER: {
      return {
        ...state,
        businessManager: {
          ...state.businessManager,
          selectedBusinessManager: action.data,
          errorMessage: '',
        },
      }
    }
    case FacebookPartnerActionTypes.SET_BUSINESS_INFO: {
      return {
        ...state,
        businessManager: {
          ...state.businessManager,
          info: {
            ...state.businessManager.info,
            selected_business_type: null,
            ...action.data,
          },
        },
      }
    }
    case FacebookPartnerActionTypes.SET_BUSINESS_MANAGER_LOADING: {
      return {
        ...state,
        businessManager: {
          ...state.businessManager,
          isLoading: action.data,
        },
      }
    }
    case FacebookPartnerActionTypes.SET_APPLICATIONS_SENT: {
      return {
        ...state,
        isSentForm: action.data,
      }
    }
    case FacebookPartnerActionTypes.SET_APPLICATIONS_LOADING: {
      return {
        ...state,
        isFormLoading: action.data,
      }
    }
    case FacebookPartnerActionTypes.SET_APPLICATIONS_ERRORS: {
      return {
        ...state,
        errors: action.data,
      }
    }
    case FacebookPartnerActionTypes.SET_APPLICATIONS: {
      return {
        ...state,
        applications: action.data,
      }
    }
    case FacebookPartnerActionTypes.CLOSE_APPLICATION: {
      const index = state.applications.findIndex((account: IAdAccount) => account.id === action.data.id)
      const applications = [...state.applications.slice(0, index), ...state.applications.slice(index + 1)]

      return {
        ...state,
        applications,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as facebookPartnerReducer }
