import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { Routes } from '../../const/routes'

class Index extends Component<{}, {}> {
  public render() {
    return <Redirect to={Routes.ACCOUNTS} />
  }
}

export default Index
