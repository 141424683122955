import React from 'react'
import { useIntl } from 'react-intl'

import { IPaymentMethodCardProps } from '../../types'
import useStyles from './styles'

import { CheckIcon, FlexCheckIcon } from '@/components/Icons'
import { PurpleBase, PurpleLighten40, SlateGreyLighten13 } from '@/const/colors'
import { PaymentMethod } from '@/store/user/types'

const PaymentMethodCard = (props: IPaymentMethodCardProps) => {
  const { cardType, isActive, changeHandler } = props

  const intl = useIntl()

  const classes = useStyles({ isActive })

  const content = {
    [PaymentMethod.CARD]: {
      title: intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodCardTitle' }),
      texts: [intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodCardText2' })],
      textWithButton: intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodCardText1' }),
    },
    [PaymentMethod.PROFORMA]: {
      title: intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodInvoiceTitle' }),
      texts: [
        intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodInvoiceText2' }),
        intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodInvoiceText3' }),
        intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodInvoiceText4' }),
        intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodInvoiceText5' }),
      ],
      textWithButton: intl.formatMessage({ id: 'detailsPayment.modal.paymentMethodInvoiceText1' }),
    },
  }

  const { title, texts, textWithButton } = content[cardType]

  return (
    <div className={classes.root} onClick={changeHandler(cardType)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.contentWrapper}>
        <div className={classes.contentItem}>
          <CheckIcon fill={SlateGreyLighten13} />
          <div className={classes.contentText}>{textWithButton}</div>
        </div>
        {texts.map((item) => (
          <div key={item} className={classes.contentItem}>
            <CheckIcon fill={SlateGreyLighten13} />
            <div className={classes.contentText}>{item}</div>
          </div>
        ))}
      </div>
      <div className={classes.checkedIcon}>
        <FlexCheckIcon fill={PurpleBase} fillBg={PurpleLighten40} />
      </div>
    </div>
  )
}

export default PaymentMethodCard
