import { createUseStyles } from 'react-jss'

import {
  PurpleBase,
  PurpleLighten40,
  RedBase,
  SlateGreyLighten34,
  SlateGreyLighten43,
  SlateGreyLighten46,
  SlateGreyLighten50,
  TextGreyBase,
  TextSlateGreyLighten30,
  White,
} from '../../const/colors'
import { FontInput } from '../../const/fontStyles'
import { IInputSMBProps, INPUT_SIZE, INPUT_VARIANT } from './types'

export default createUseStyles(
  {
    root: {
      position: 'relative',
      maxWidth: (props: IInputSMBProps) => (props.width ? props.width : 'initial'),
      width: (props: IInputSMBProps) => (props.fullWidth ? '100%' : 'initial'),
    },
    [INPUT_SIZE.size40]: {
      '& $input': {
        padding: '9px 15px',
      },
    },
    [INPUT_SIZE.size48]: {
      '& $input': {
        padding: '13px 15px',
      },
    },
    [INPUT_VARIANT.default]: {
      '& $input': {
        borderColor: SlateGreyLighten43,
        backgroundColor: White,

        '&:hover': {
          borderColor: SlateGreyLighten34,
        },

        '&:focus, &:active': {
          borderColor: PurpleBase,
        },
      },
    },
    [INPUT_VARIANT.grey]: {
      '& $input': {
        backgroundColor: SlateGreyLighten50,

        '&:hover': {
          borderColor: PurpleBase,
        },

        '&:focus, &:active': {
          borderColor: PurpleBase,
          boxShadow: `0 0 0 3px ${PurpleLighten40}`,
        },
      },
    },
    input: {
      outline: 'none',
      border: `1px solid ${SlateGreyLighten50}`,
      borderRadius: 6,
      ...FontInput,
      color: TextGreyBase,
      caretColor: PurpleBase,
      width: (props: IInputSMBProps) => (props.width ? props.width : 'initial'),
      resize: 'none',
    },
    suggestion: {
      position: 'absolute',
      top: 48,
      right: 0,
      left: 0,
      padding: 6,
      backgroundColor: White,
      border: `1px solid ${SlateGreyLighten46}`,
      borderRadius: 6,
      boxShadow: '0 16px 24px rgba(16, 23, 41, 0.04), 0 2px 6px rgba(16, 23, 41, 0.04), 0 0 1px rgba(16, 23, 41, 0.04)',
      zIndex: 99,
      maxHeight: 282,
    },
    disabled: {
      '& $input': {
        backgroundColor: SlateGreyLighten50,
        borderColor: SlateGreyLighten50,
        color: TextSlateGreyLighten30,

        '&:hover, &:focus, &:active': {
          borderColor: SlateGreyLighten50,
          boxShadow: 'none',
          color: TextSlateGreyLighten30,
        },
      },
    },
    fullWidth: {
      width: '100%',
      '& $input': {
        width: '100%',
      },
    },
    error: {
      '& $input': {
        borderColor: RedBase,
      },
    },
    leftIconWrapper: {
      position: 'absolute',
      top: 10,
      left: 10,
    },
    rightIconWrapper: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        flexShrink: 0,
      },
    },
    leftIcon: {
      '& $input': {
        paddingLeft: 36,
      },
    },
    rightIcon: {
      '& $input': {
        paddingRight: 48,
      },
    },
  },
  { name: 'InputSMB' }
)
