import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = PurpleBase } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5198_4984)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 31.0462 31.0462 40 20 40C8.95375 40 0 31.0462 0 20C0 8.95375 8.95375 0 20 0C31.0462 0 40 8.95375 40 20ZM13.17 11.1625H26.8288H26.83C29.34 11.1625 30.9325 13.875 29.67 16.0612L21.2388 30.6725C20.6888 31.6262 19.31 31.6262 18.76 30.6725L10.3313 16.0625C9.0675 13.8712 10.6588 11.1625 13.17 11.1625ZM18.75 26.2827V13.6587H13.1688C13.0326 13.6572 12.8985 13.6916 12.7799 13.7585C12.6614 13.8254 12.5625 13.9224 12.4934 14.0396C12.4243 14.1569 12.3874 14.2904 12.3863 14.4265C12.3853 14.5626 12.4202 14.6966 12.4875 14.815L16.9175 22.7375L18.75 26.2827ZM23.08 22.7387L27.5075 14.8137C27.8 14.3062 27.44 13.6562 26.8275 13.6562H21.2438V26.29L23.08 22.7387Z"
          fill={fillBg}
        />
      </g>
      <defs>
        <clipPath id="clip0_5198_4984">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
