import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import cn from 'classnames'

import { IBusinessInfoProps } from '../../types'
import useStyles from './styles'

import { ALERT_VARIANT, AlertInline } from '@/components/AlertInlineSMB'
import { ComboBox, COMBOBOX_SIZE } from '@/components/ComboBox'
import { FieldHelpText } from '@/components/FieldHelpText'
import { FieldLabel } from '@/components/FieldLabel'
import { Asterisk } from '@/components/Icons'
import InputPhone from '@/components/InputPhone'
import { Input, INPUT_SIZE } from '@/components/InputSMB'
import { GLOBAL_COUNTRIES } from '@/const'
import { PurpleBase } from '@/const/colors'
import { PaymentMethod } from '@/store/user/types'

const BusinessInfo = (props: IBusinessInfoProps) => {
  const {
    businessInfoForm,
    changeHandler,
    onPhoneInputChange,
    onPhoneInputBlur,
    blurHandler,
    country,
    cardType,
    isCurrentClient,
    isRuClient,
    countries,
    isCountriesLoading,
    onCountryChange,
    isCountryConfirm,
    isVatRequired = false,
  } = props
  const { business_address, business_phone, business_ceo, business_name, vat_registration_number, business_country } =
    businessInfoForm
  const intl = useIntl()
  const classes = useStyles()

  const hasJurisdiction = isCurrentClient && !isRuClient && !isCountryConfirm

  return (
    <div>
      {isCurrentClient && (
        <AlertInline
          variant={ALERT_VARIANT.neutralColor}
          isFullWidth={true}
          description={intl.formatMessage({ id: 'detailsPayment.modal.companyAlert' })}
          className={classes.detailsAlert}
        />
      )}
      <form className={classes.root}>
        <div>
          <FieldLabel className={classes.label}>
            <FormattedMessage id="detailsPayment.modal.formNameLabel" />
            <span className={classes.requiredIcon}>
              <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
            </span>
          </FieldLabel>
          <Input
            name="business_name"
            id="business_name"
            placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formNamePlaceholder' })}
            size={INPUT_SIZE.size48}
            fullWidth={true}
            onBlur={blurHandler}
            onChange={changeHandler}
            value={business_name.value}
            error={business_name.hasError}
          />
          <FieldHelpText isError={true} className={classes.error}>
            {business_name.hasError && business_name.errorText}
          </FieldHelpText>
        </div>
        <div className={cn({ [classes.formGroup]: hasJurisdiction })}>
          {hasJurisdiction && (
            <div>
              <FieldLabel className={classes.label}>
                <FormattedMessage id="detailsPayment.modal.formJurisdictionLabel" />
                <span className={classes.requiredIcon}>
                  <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
                </span>
              </FieldLabel>
              <ComboBox
                options={countries}
                onChange={onCountryChange}
                size={COMBOBOX_SIZE.big}
                placeholder={intl.formatMessage({ id: 'detailsPayment.modal.countryPlaceholder' })}
                noOptionsMessage={<FormattedMessage id="common.noResults" />}
                loadingMessage={<FormattedMessage id="common.waitLoading" />}
                isLoading={isCountriesLoading}
                searchFields={['label']}
                errorText={
                  business_country.hasError && intl.formatMessage({ id: 'detailsPayment.modal.countryPlaceholder' })
                }
              />
            </div>
          )}

          <div>
            <FieldLabel className={classes.label}>
              <FormattedMessage id="detailsPayment.modal.formAddressLabel" />
              <span className={classes.requiredIcon}>
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </span>
            </FieldLabel>
            <Input
              name="business_address"
              id="business_address"
              placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formAddressPlaceholder' })}
              size={INPUT_SIZE.size48}
              fullWidth={true}
              onBlur={blurHandler}
              onChange={changeHandler}
              value={business_address.value}
              error={business_address.hasError}
            />
          </div>
        </div>
        <div className={classes.formGroup}>
          <div>
            <FieldLabel className={classes.label}>
              <FormattedMessage id="detailsPayment.modal.formCeoLabel" />
              {cardType === PaymentMethod.PROFORMA && business_ceo.required && (
                <span className={classes.requiredIcon}>
                  <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
                </span>
              )}
            </FieldLabel>
            <Input
              name="business_ceo"
              id="business_ceo"
              placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formCeoPlaceholder' })}
              size={INPUT_SIZE.size48}
              fullWidth={true}
              onBlur={blurHandler}
              onChange={changeHandler}
              value={business_ceo.value}
              error={business_ceo.hasError}
            />
          </div>
          <div>
            <FieldLabel className={classes.label}>
              <FormattedMessage id="detailsPayment.modal.formPhoneNumber" />
              {cardType === PaymentMethod.PROFORMA && (
                <span className={classes.requiredIcon}>
                  <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
                </span>
              )}
            </FieldLabel>
            <InputPhone
              country={(business_country.value || country)?.toLowerCase()}
              onChange={onPhoneInputChange}
              onBlur={onPhoneInputBlur}
              value={business_phone.value}
            />
            <FieldHelpText isError={true} className={classes.error}>
              {business_phone.hasError && intl.formatMessage({ id: 'profile.form.phoneError' })}
            </FieldHelpText>
          </div>
        </div>
        <div>
          <FieldLabel className={classes.label}>
            <FormattedMessage
              id={
                cardType === PaymentMethod.CARD
                  ? 'detailsPayment.modal.formTaxCodeLabel'
                  : 'detailsPayment.modal.formRegistrationNumberLabel'
              }
            />
            {isVatRequired && (
              <span className={classes.requiredIcon}>
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </span>
            )}
          </FieldLabel>
          <Input
            name="vat_registration_number"
            id="vat_registration_number"
            placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formRegistrationNumberPlaceholder' })}
            size={INPUT_SIZE.size48}
            fullWidth={true}
            onBlur={blurHandler}
            onChange={changeHandler}
            value={vat_registration_number.value}
            error={vat_registration_number.hasError}
          />
        </div>
        {business_country.value === GLOBAL_COUNTRIES.RU && (
          <AlertInline
            variant={ALERT_VARIANT.negativeColor}
            isFullWidth={true}
            description={intl.formatMessage({ id: 'detailsPayment.modal.sanctionAlert' })}
            className={classes.countryAlert}
          />
        )}
        {business_country.value === GLOBAL_COUNTRIES.GB && (
          <AlertInline
            variant={ALERT_VARIANT.neutralColor}
            description={intl.formatMessage({ id: 'profile.form.GBHintVAT' })}
            isFullWidth={true}
            className={classes.countryAlert}
          />
        )}
      </form>
    </div>
  )
}

export default BusinessInfo
