import React, { ReactNode } from 'react'
import cn from 'classnames'

import useStyles from './styles'

export interface IFieldHelpTextProps {
  children: string | ReactNode
  className?: string
  isError?: boolean
}

const FieldHelpText = (props: IFieldHelpTextProps) => {
  const { children, className, isError = false } = props

  const classes = useStyles()

  return <div className={cn(classes.helpText, className, { [classes.error]: isError })}>{children}</div>
}

export { FieldHelpText }
