import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import useStyles from './styles'

import { Mixpanel } from '@/analytics'
import { getNumWithDelimiter } from '@/common/utils'
import { Tooltip, TOOLTIP_SIZE } from '@/components/TooltipSMB'
import { Delimiters } from '@/const'
import { Routes } from '@/const/routes'
import { Currency } from '@/store/user/types'

interface IBalanceCard {
  amount: string
  icon: ReactNode
  isProfileClient?: boolean
  currency: Currency
}

export const BalanceCard = (props: IBalanceCard) => {
  const { amount, icon, isProfileClient, currency } = props

  const { push } = useHistory()
  const { formatMessage } = useIntl()

  const classes = useStyles()

  const clickHandler = () => {
    if (!isProfileClient) {
      Mixpanel.track('HeaderGoToBudget')

      push(`${Routes.BUDGET}?currency=${currency}`)
    }
  }

  const toolTipContentText = {
    [Currency.USD]: formatMessage({ id: 'balance.menu.usd.tooltip' }),
    [Currency.EUR]: formatMessage({ id: 'balance.menu.eur.tooltip' }),
  }[currency]

  return (
    <Tooltip id={currency} content={toolTipContentText} place="bottom" size={TOOLTIP_SIZE.size247}>
      <div className={classes.root} onClick={clickHandler}>
        {icon}
        <div>{getNumWithDelimiter(amount, Delimiters.SPACE_COMMA)}</div>
      </div>
    </Tooltip>
  )
}
