import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.54038 7.54038C9.28654 7.79422 9.28654 8.20578 9.54038 8.45962C9.79422 
        8.71346 10.2058 8.71346 10.4596 8.45962L9.54038 7.54038ZM15 3H15.65C15.65 
        2.64101 15.359 2.35 15 2.35V3ZM11.6667 2.35C11.3077 2.35 11.0167 2.64101 11.0167 
        3C11.0167 3.35899 11.3077 3.65 11.6667 3.65V2.35ZM14.35 6.33333C14.35 6.69232 14.641 
        6.98333 15 6.98333C15.359 6.98333 15.65 6.69232 15.65 6.33333H14.35ZM15.65 10.3333C15.65 
        9.97435 15.359 9.68333 15 9.68333C14.641 9.68333 14.35 9.97435 14.35 10.3333H15.65ZM3 
        13.6667H2.35H3ZM4.33333 3V2.35V3ZM7.66667 3.65C8.02565 3.65 8.31667 3.35899 8.31667 3C8.31667 
        2.64101 8.02565 2.35 7.66667 2.35V3.65ZM10.4596 8.45962L15.4596 3.45962L14.5404 2.54038L9.54038 
        7.54038L10.4596 8.45962ZM11.6667 3.65H15V2.35H11.6667V3.65ZM14.35 3V6.33333H15.65V3H14.35ZM14.35 
        10.3333V13.6667H15.65V10.3333H14.35ZM14.35 13.6667C14.35 13.8479 14.278 14.0217 14.1499 14.1499L15.0691 
        15.0691C15.441 14.6971 15.65 14.1927 15.65 13.6667H14.35ZM14.1499 14.1499C14.0217 14.278 13.8479 14.35 
        13.6667 14.35V15.65C14.1927 15.65 14.6971 15.441 15.0691 15.0691L14.1499 14.1499ZM13.6667 
        14.35H4.33333V15.65H13.6667V14.35ZM4.33333 14.35C4.1521 14.35 3.97829 14.278 3.85014 14.1499L2.9309 
        15.0691C3.30285 15.441 3.80732 15.65 4.33333 15.65V14.35ZM3.85014 14.1499C3.72199 14.0217 3.65 13.8479
         3.65 13.6667H2.35C2.35 14.1927 
        2.55896 14.6971 2.9309 15.0691L3.85014 14.1499ZM3.65 13.6667V4.33333H2.35V13.6667H3.65ZM3.65 
        4.33333C3.65 4.1521 3.72199 3.97829
         3.85014 3.85014L2.9309 2.9309C2.55896 3.30285 2.35 3.80732 2.35 4.33333H3.65ZM3.85014 
         3.85014C3.97829 3.72199 4.1521 3.65 4.33333 3.65V2.35C3.80732 2.35 3.30285 2.55896 
         2.9309 2.9309L3.85014 3.85014ZM4.33333 3.65H7.66667V2.35H4.33333V3.65Z"
        fill={fill}
      />
    </svg>
  )
}
