import EN_STATE from './locales/en'
import RU_STATE from './locales/ru'

export enum LOCALES {
  ru = 'ru',
  en = 'en',
}

export const LOCALE_DATA = {
  [LOCALES.ru]: RU_STATE,
  [LOCALES.en]: EN_STATE,
}
