import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import { reducer as formReducer } from 'redux-form'
import { createResponsiveStateReducer } from 'redux-responsive'

import addTelegram from './pages/AddTelegram/reducer'
import analytics from './pages/Analytics/reducer'
import balanceReducer from './pages/Balance/reducer'
import budgetRootReducer from './pages/Budget/reducer'
import invoicesReducer from './pages/Invoices/reducer'
import { accountingReducer } from './store/accounting'
import { adAccountsReducer } from './store/adaccounts'
import { antibanReducer } from './store/antiban'
import { appReducer } from './store/app'
import { audiencesReducer } from './store/audiences'
import { budgetReducer } from './store/budget'
import { documentsReducer } from './store/documents'
import { facebookPartnerReducer } from './store/facebook-partner'
import { myCashbackReducer } from './store/my-cashback'
import { notificationsReducer } from './store/notifications'
import { onboardingReducer } from './store/onboarding'
import { promoReducer } from './store/promo'
import { promocodeReducer } from './store/promocodes'
import { referralReducer } from './store/referral'
import { requestsReducer } from './store/requests'
import { transactionReducer } from './store/transactions'
import { userReducer } from './store/user'

const browserReducer = createResponsiveStateReducer(
  {
    container: 1170,
    desktop: 1279,
    extraSmall: 480,
    large: 1439,
    medium: 1023,
    small: 768,
  },
  {
    extraFields: () => ({
      width: window.innerWidth,
    }),
  }
)

const { routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() })

export default () =>
  combineReducers({
    analytics,
    form: formReducer,
    accounting: accountingReducer,
    adAccounts: adAccountsReducer,
    antiban: antibanReducer,
    addTelegram,
    app: appReducer,
    audiences: audiencesReducer,
    balance: balanceReducer,
    balances: budgetReducer,
    browser: browserReducer,
    budget: budgetRootReducer,
    documents: documentsReducer,
    invoices: invoicesReducer,
    myCashback: myCashbackReducer,
    notifications: notificationsReducer,
    onboarding: onboardingReducer,
    promo: promoReducer,
    promocode: promocodeReducer,
    referral: referralReducer,
    requests: requestsReducer,
    router: routerReducer,
    transactions: transactionReducer,
    user: userReducer,
    facebookPartner: facebookPartnerReducer,
  })
