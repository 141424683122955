import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { ThunkResult } from '../store'
import { IAccountRequest, RequestsActionTypes } from './types'

import { API_URL } from '@/const'
import { AdAccountTypes } from '@/store/adaccounts/types'
import { customAxios } from '@/utils'

export function getRequests(network: AdAccountTypes): ThunkResult<Promise<any>> {
  const urls: { [key in AdAccountTypes]: string } = {
    facebook: `${API_URL}/additional_accounts_requests/requests_list/`,
    tiktok: `${API_URL}/tiktok/accounts_requests/`,
  }

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(urls[network], 'GET', null, true)
      .then((response: AxiosResponse<IAccountRequest[]>) => {
        dispatch({ type: RequestsActionTypes.SET_REQUESTS, data: response.data, network })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
