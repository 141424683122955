import React from 'react'

import { SlateGreyLighten34 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten34 } = props
  const defaultStyle = { width: 28, height: 30 }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...defaultStyle, ...style }}
      fill="none"
      viewBox="0 0 28 30"
    >
      <path
        d="M14.0003 25.2115C13.7864 25.2115 13.5873 25.1969 13.403 25.1676C13.2179 25.1392 13.0475 25.0961 12.892 25.0384C10.4809 24.1538 8.50727 22.5769 6.97116 20.3076C5.43505 18.0384 4.66699 15.5384 4.66699 12.8076V7.35571C4.66699 6.87494 4.80816 6.44225 5.09049 6.05764C5.37205 5.67302 5.73644 5.39417 6.18366 5.2211L13.1837 2.62494C13.4559 2.52879 13.7281 2.48071 14.0003 2.48071C14.2725 2.48071 14.5448 2.52879 14.817 2.62494L21.817 5.2211C22.2642 5.39417 22.629 5.67302 22.9113 6.05764C23.1929 6.44225 23.3337 6.87494 23.3337 7.35571V12.8076C23.3337 15.5384 22.5656 18.0384 21.0295 20.3076C19.4934 22.5769 17.5198 24.1538 15.1087 25.0384C14.9531 25.0961 14.7828 25.1392 14.5977 25.1676C14.4133 25.1969 14.2142 25.2115 14.0003 25.2115ZM14.0003 22.9615C15.8864 22.3846 17.4614 21.2453 18.7253 19.5438C19.9892 17.8415 20.7281 15.9423 20.942 13.8461H14.0003V4.75956L7.00033 7.35571V13.3269C7.00033 13.4615 7.01977 13.6346 7.05866 13.8461H14.0003V22.9615Z"
        fill={fill}
      />
    </svg>
  )
}
