import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 156, height: 30 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 156 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52.7681 0C52.7681 0 52.7681 9.98443 52.7681 29.9533C52.7681 29.9533 51.1226 29.9533 47.8315 29.9533C47.8315 29.9533 47.8315 28.4954 47.8315 26.5553C46.7389 27.7695 45.6134 28.6472 44.4549 29.1883C43.2965 29.7294 41.9603 30 40.4464 30C38.3533 30 36.4445 29.4853 34.72 28.4558C33.0086 27.4264 31.6593 26.0075 30.672 24.1994C29.6846 22.3912 29.191 20.3917 29.191 18.2008C29.191 15.9967 29.6846 13.9708 30.672 12.1231C31.6724 10.2753 33.0284 8.82351 34.7397 7.76766C36.4642 6.7118 38.3533 6.18388 40.4069 6.18388C41.8155 6.18388 43.1319 6.43464 44.3562 6.93617C45.5936 7.43771 46.6073 8.12401 47.3971 8.99509V0H52.7681ZM41.0783 11.1332C39.2616 11.1332 37.728 11.7931 36.4774 13.1129C35.2399 14.4327 34.6212 16.0693 34.6212 18.0226C34.6212 19.9892 35.2663 21.6521 36.5564 23.0116C37.8596 24.371 39.4393 25.0507 41.2955 25.0507C43.1122 25.0507 44.6458 24.3842 45.8964 23.0512C47.1602 21.7181 47.792 20.0882 47.792 18.1612C47.792 16.1287 47.1602 14.4525 45.8964 13.1327C44.6326 11.7997 43.0266 11.1332 41.0783 11.1332Z"
        fill={fill}
      />
      <path
        d="M57.8232 29.9533C57.8232 29.9533 57.8232 19.9689 57.8232 0C57.8232 0 59.6728 0 63.3719 0V20.7317L74.0547 18.2927V0C74.0547 0 75.8977 0 79.5837 0C79.5837 0 79.5837 9.98443 79.5837 29.9533C79.5837 29.9533 77.7407 29.9533 74.0547 29.9533V23.1707L63.3719 25.6098V29.9533C63.3719 29.9533 61.5224 29.9533 57.8232 29.9533Z"
        fill={fill}
      />
      <path
        d="M106.253 7.48337C106.253 7.48337 106.253 14.9733 106.253 29.9533C106.253 29.9533 104.614 29.9533 101.336 29.9533C101.336 29.9533 101.336 28.4954 101.336 26.5553C100.257 27.7827 99.1377 28.667 97.9793 29.2081C96.834 29.736 95.4715 30 93.8918 30C91.7328 30 89.7911 29.5051 88.0666 28.5152C86.3552 27.5121 85.0125 26.1197 84.0383 24.338C83.0773 22.5562 82.5968 20.5501 82.5968 18.3196C82.5968 16.0627 83.0839 14.0368 84.0581 12.2418C85.0322 10.4337 86.3881 9.02149 88.1258 8.00523C89.8635 6.98897 91.825 6.48084 94.0102 6.48084C95.6294 6.48084 97.0248 6.7646 98.1965 7.33212C99.3681 7.88644 100.415 8.77732 101.336 10.0048V7.48337H106.253ZM94.4842 11.4302C92.6148 11.4302 91.068 12.0901 89.8438 13.4099C88.6326 14.7297 88.0271 16.4059 88.0271 18.4384C88.0271 20.4049 88.6392 22.0019 89.8635 23.2293C91.1009 24.4436 92.7004 25.0507 94.6619 25.0507C96.6102 25.0507 98.1767 24.4304 99.3615 23.1897C100.559 21.9491 101.158 20.3257 101.158 18.3196C101.158 16.2871 100.533 14.6307 99.2825 13.3505C98.0451 12.0703 96.4456 11.4302 94.4842 11.4302Z"
        fill={fill}
      />
      <path
        d="M109.905 29.9533C109.905 29.9533 109.905 22.4633 109.905 7.48337C109.905 7.48337 111.557 7.48337 114.861 7.48337C114.861 7.48337 114.861 8.20267 114.861 9.64127C115.849 8.67781 116.823 7.9915 117.784 7.58236C118.745 7.17321 119.877 6.96864 121.18 6.96864C124.445 6.96864 126.828 8.11688 128.328 10.4134C128.868 11.2844 129.243 12.2677 129.454 13.3632C129.678 14.4586 129.79 15.9764 129.79 17.9165V29.9533H124.419C124.419 29.9533 124.419 26.2908 124.419 18.9658C124.419 16.0622 124.037 14.1551 123.273 13.2444C122.549 12.3601 121.503 11.918 120.134 11.918C118.291 11.918 116.954 12.6439 116.125 14.0957C115.796 14.6104 115.572 15.2505 115.454 16.016C115.335 16.7815 115.276 17.8967 115.276 19.3617V29.9533C115.276 29.9533 113.486 29.9533 109.905 29.9533Z"
        fill={fill}
      />
      <path
        d="M155.854 0C155.854 0 155.854 9.98443 155.854 29.9533C155.854 29.9533 154.208 29.9533 150.917 29.9533C150.917 29.9533 150.917 28.4954 150.917 26.5553C149.824 27.7695 148.699 28.6472 147.54 29.1883C146.382 29.7294 145.046 30 143.532 30C141.439 30 139.53 29.4853 137.805 28.4558C136.094 27.4264 134.745 26.0075 133.757 24.1994C132.77 22.3912 132.277 20.3917 132.277 18.2008C132.277 15.9967 132.77 13.9708 133.757 12.1231C134.758 10.2753 136.114 8.82351 137.825 7.76766C139.55 6.7118 141.439 6.18388 143.492 6.18388C144.901 6.18388 146.217 6.43464 147.442 6.93617C148.679 7.43771 149.693 8.12401 150.483 8.99509V0H155.854ZM144.164 11.1332C142.347 11.1332 140.814 11.7931 139.563 13.1129C138.325 14.4327 137.707 16.0693 137.707 18.0226C137.707 19.9892 138.352 21.6521 139.642 23.0116C140.945 24.371 142.525 25.0507 144.381 25.0507C146.198 25.0507 147.731 24.3842 148.982 23.0512C150.246 21.7181 150.878 20.0882 150.878 18.1612C150.878 16.1287 150.246 14.4525 148.982 13.1327C147.718 11.7997 146.112 11.1332 144.164 11.1332Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 29.9533L12.46 0H16.8239L29.3628 29.9533H22.8758L21.2619 25.6098H8.26012L6.69954 29.9533H0ZM14.6518 7.81992L19.4494 20.7317H10.0127L14.6518 7.81992Z"
        fill={fill}
      />
    </svg>
  )
}
