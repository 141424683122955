import { createUseStyles } from 'react-jss'

import {
  BackgroundWhite,
  PurpleBase,
  PurpleLighten46,
  SlateGreyBase,
  SlateGreyLighten34,
  SlateGreyLighten43,
  SlateGreyLighten46,
  SlateGreyLighten49,
  SlateGreyLighten50,
  TextGreyBase,
  TextSlateGreyBase,
  TextSlateGreyLighten8,
} from '../../const/colors'
import { FontBody1, FontInput, FontInteractive } from '../../const/fontStyles'

export default createUseStyles(
  {
    container: {
      '& $input.form-control': {
        width: '100%',
        borderRadius: 6,
        border: `1px solid ${SlateGreyLighten43}`,
        backgroundColor: BackgroundWhite,
        padding: '13px 15px 13px 82px',
        color: TextGreyBase,
        ...FontInput,

        '&:hover': {
          borderColor: SlateGreyLighten34,
        },

        '&:focus, &:active': {
          borderColor: PurpleBase,
          boxShadow: 'none',
        },
      },

      '& $button.flag-dropdown': {
        borderRight: `1px solid ${SlateGreyLighten43}`,
        backgroundColor: SlateGreyLighten50,
        borderRadius: '6px 0 0 6px',
        left: '1px',
        top: '1px',
        bottom: '1px',

        '&:hover, &:focus': {
          backgroundColor: SlateGreyLighten49,
        },

        '& .selected-flag': {
          width: 67,
          padding: '0 0 0 15px',
        },

        '& .selected-flag .arrow': {
          left: 32,
          borderTopColor: SlateGreyBase,
          borderBottomColor: SlateGreyBase,
        },

        '& .selected-flag:focus .arrow:not(.up)': {
          borderLeftWidth: 3,
          borderRightWidth: 3,
          borderTop: `4px solid ${SlateGreyBase}`,
        },

        '& .selected-flag:focus .arrow.up': {
          borderLeftWidth: 3,
          borderRightWidth: 3,
          borderBottom: `4px solid ${SlateGreyBase}`,
        },
      },

      '& $dropdown.country-list': {
        padding: 6,
        boxShadow:
          '0px 16px 24px rgba(16, 23, 41, 0.04), 0px 2px 6px rgba(16, 23, 41, 0.04), 0px 0px 1px rgba(16, 23, 41, 0.04)',
        border: `1px solid ${SlateGreyLighten46}`,
        backgroundColor: BackgroundWhite,
        margin: '8px 0 8px -1px',
        width: 315,

        '& .country': {
          borderRadius: 4,
          padding: '12px 9px 12px 44px',
          ...FontInteractive,

          '& .dial-code': {
            color: TextSlateGreyLighten8,
          },

          '& .country-name': {
            color: TextGreyBase,
          },

          '& .flag': {
            marginTop: 0,
          },
        },

        '& .country:not(.highlight):hover': {
          backgroundColor: SlateGreyLighten50,

          '& .country-name': {
            color: PurpleBase,
          },
        },

        '& .country.highlight, & .country.highlight:hover': {
          backgroundColor: PurpleLighten46,

          '& .country-name': {
            color: PurpleBase,
          },
        },
      },
    },
    input: {},
    button: {},
    dropdown: {},
    buttonPhoneFormatter: { display: 'none' },
    containerFormatter: {
      '& .special-label': {
        display: 'none !important',
      },
    },
    inputFormatter: {
      ...FontBody1,
      borderWidth: '0 !important',
      padding: '0 !important',
      cursor: 'initial !important',
      width: '100% !important',
      fontSize: '14px !important',
      color: TextSlateGreyBase,
    },
  },
  { name: 'InputPhone' }
)
