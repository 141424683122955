import { createUseStyles } from 'react-jss'

import {
  BackgroundWhite,
  getColorLighten,
  PurpleBase,
  PurpleLighten12,
  PurpleLighten43,
  PurpleLighten46,
  RedDarken8,
  RedLighten30,
  SlateGreyLighten13,
  SlateGreyLighten34,
  SlateGreyLighten43,
  SlateGreyLighten46,
  SlateGreyLighten49,
  SlateGreyLighten50,
  TextGreyBase,
  TextSlateGreyDarken12,
  TextSlateGreyLighten8,
  TextSlateGreyLighten16,
  White,
} from '../../const/colors'
import { FontInput, FontInteractive, FontSmall } from '../../const/fontStyles'
import { COMBOBOX_SIZE } from './types'

export default createUseStyles(
  {
    comboBoxContainer: {},
    comboBox: {
      '$comboBoxContainer &__control': {
        border: `1px solid ${SlateGreyLighten43}`,
        borderRadius: 6,

        '&--is-focused': {
          boxShadow: 'none',
          borderColor: PurpleLighten12,

          '&:hover': {
            borderColor: PurpleLighten12,
          },
        },

        '&--is-disabled': {
          background: SlateGreyLighten50,
        },

        '&:hover': {
          borderColor: SlateGreyLighten34,
        },
      },
      '$comboBoxContainer &__multi-value': {
        background: SlateGreyLighten49,
        borderRadius: 4,
        padding: '4px 4px 4px 8px',
        margin: 0,
      },
      '$comboBoxContainer &__value-container--is-multi': {
        display: 'flex',
        gap: 6,
        padding: '6px 2px 6px 15px !important',
      },
      '$comboBoxContainer &__multi-value__label': {
        ...FontInteractive,
        color: TextSlateGreyDarken12,
      },
      '$comboBoxContainer &__multi-value__remove': {
        color: TextSlateGreyDarken12,

        '&:hover': {
          background: RedLighten30,
          color: RedDarken8,
        },
      },
      [`$${COMBOBOX_SIZE.small} &__control`]: {
        minHeight: 40,
      },
      [`$${COMBOBOX_SIZE.big} &__control`]: {
        minHeight: 48,
      },
      [`$${COMBOBOX_SIZE.small} &__indicators`]: {
        padding: '4px 6px',
      },
      [`$${COMBOBOX_SIZE.big} &__indicators`]: {
        padding: '8px 10px',
      },
      '$comboBoxContainer &__indicator': {
        background: SlateGreyLighten50,
        borderRadius: 6,
        padding: 5,
        color: SlateGreyLighten13,
        cursor: 'pointer',

        '&:hover': {
          color: PurpleBase,
          background: PurpleLighten46,
        },

        '& svg': {
          width: 18,
          height: 18,
        },
      },
      '$comboBoxContainer &__control--is-focused &__indicator': {
        color: PurpleBase,
        background: PurpleLighten43,

        '&:hover': {
          background: PurpleLighten43,
        },
      },
      '$comboBoxContainer &__control--is-disabled &__indicator': {
        color: SlateGreyLighten34,
      },
      '$comboBoxContainer &__value-container': {
        padding: '0 12px 0 15px',
        cursor: 'text',
      },
      '$comboBoxContainer &__placeholder': {
        color: TextSlateGreyLighten8,
        ...FontInput,
        whiteSpace: 'nowrap',
      },
      '$comboBoxContainer &__control--is-disabled &__placeholder': {
        color: TextSlateGreyLighten16,
      },
      '$comboBoxContainer &__input-container': {
        color: TextGreyBase,
        ...FontInput,
      },
      '$comboBoxContainer &__menu': {
        margin: '6px 0 0',
        boxShadow: '0 16px 24px rgba(16, 23, 41, 0.06), 0 2px 6px rgba(16, 23, 41, 0.04)',
        borderRadius: 6,
        background: BackgroundWhite,
        border: `1px solid ${SlateGreyLighten46}`,
      },
      '$comboBoxContainer &__menu-list': {
        padding: 6,
        overflow: 'overlay',
      },
      '$comboBoxContainer &__clear-indicator': {
        display: 'none',
      },
      '$comboBoxContainer &__option': {
        borderRadius: 4,
        background: BackgroundWhite,
        padding: '10px 14px',
        cursor: 'pointer',

        '&:active': {
          background: PurpleLighten46,
        },

        '&--is-focused': {
          background: SlateGreyLighten50,

          '& $optionLabel': {
            color: PurpleBase,
          },
        },

        '&--is-selected': {
          background: PurpleLighten46,

          '& $optionLabel': {
            color: PurpleBase,
          },

          '& $optionSubLabel': {
            color: PurpleLighten12,
          },
        },
      },
      '$comboBoxContainer &__control--is-disabled': {
        '& $itemLabel': {
          color: TextSlateGreyLighten16,
        },

        '& $itemSubLabel': {
          color: TextSlateGreyLighten16,
        },
      },
    },
    [COMBOBOX_SIZE.small]: {
      '& $optionIcon': {
        marginRight: 8,

        '& svg': {
          maxWidth: 20,
          maxHeight: 20,
        },
      },

      '& $itemIcon': {
        '& svg': {
          maxWidth: 22,
          maxHeight: 22,
        },
      },
    },
    [COMBOBOX_SIZE.big]: {
      '& $optionIcon': {
        marginRight: 12,

        '& svg': {
          maxWidth: 28,
          maxHeight: 28,
        },
      },

      '& $itemIcon': {
        '& svg': {
          maxWidth: 28,
          maxHeight: 28,
        },
      },
    },
    multiValueWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    multiValueHidden: {
      display: 'none',
    },
    multiOptionWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    optionWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    optionIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    optionLabel: {
      color: TextGreyBase,
      ...FontInteractive,
    },
    optionSubLabel: {
      marginTop: 2,
      color: TextSlateGreyLighten8,
      ...FontSmall,
    },
    itemWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    itemIcon: {
      marginRight: 12,
      display: 'flex',
      alignItems: 'center',
    },
    itemLabel: {
      color: TextGreyBase,
      ...FontInput,
    },
    itemSubLabel: {
      color: TextSlateGreyLighten8,
      ...FontSmall,
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 12,
    },
    controlLabel: {
      marginBottom: 4,
    },
    controlHelpText: {
      marginTop: 4,
    },
    fullWidth: {
      width: '100%',
    },
    checkboxRoot: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      marginRight: 4,
    },
    input: {
      margin: 0,
      visibility: 'hidden',
      opacity: 0,
      position: 'absolute',
      zIndex: -1,

      '&:checked + $checkbox': {
        backgroundColor: getColorLighten(PurpleBase, 6),
        borderColor: getColorLighten(PurpleBase, 6),

        '& $checkedIcon': {
          display: 'flex',
        },

        '&:hover': {
          borderColor: PurpleBase,
          backgroundColor: PurpleBase,
        },
      },
    },
    inputWrapper: {
      display: 'flex',
    },
    checkbox: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 18,
      height: 18,
      borderRadius: 4,
      border: `1px solid ${SlateGreyLighten34}`,
      boxSizing: 'border-box',

      '&:hover': {
        borderColor: PurpleBase,
      },
    },
    checked: {
      backgroundColor: getColorLighten(PurpleBase, 6),
      borderColor: getColorLighten(PurpleBase, 6),

      '&:hover': {
        backgroundColor: PurpleBase,
      },

      '& $checkedIcon': {
        display: 'flex',
      },
    },
    checkedIcon: {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',

      '& path': {
        stroke: White,
      },
    },
  },
  { name: 'ComboBox' }
)
