import { Reducer } from 'redux'

import { reducerName } from './actions'
import { AppActionTypes, IAppState } from './types'

export const initialState: IAppState = {
  isAnalyticsMenuOpened: false,
  isFinanceMenuOpened: false,
  isMenuOpened: true,
  isShowedModal: false,
  isToolMenuOpened: false,
  loaded: false,
  modals: null,
  hasSuccessBusinessProfile: false,
  hasSuccessCurrentBusinessProfile: false,
  countries: [],
  isCountriesLoading: false,
}

const reducer: Reducer<IAppState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AppActionTypes.SET_LOADED: {
      return { ...state, loaded: action.data }
    }
    case AppActionTypes.TOGGLE_MENU: {
      return { ...state, isMenuOpened: action.data }
    }
    case AppActionTypes.SET_MODAL: {
      return { ...state, isShowedModal: action.data }
    }
    case AppActionTypes.TOGGLE_FINANCE_MENU: {
      return { ...state, isFinanceMenuOpened: action.data }
    }
    case AppActionTypes.TOGGLE_TOOL_MENU: {
      return { ...state, isToolMenuOpened: action.data }
    }
    case AppActionTypes.TOGGLE_ANALYTICS_MENU: {
      return { ...state, isAnalyticsMenuOpened: action.data }
    }
    case AppActionTypes.SET_BUSINESS_PROFILE: {
      return { ...state, hasSuccessBusinessProfile: true }
    }
    case AppActionTypes.SET_CURRENT_BUSINESS_PROFILE: {
      return { ...state, hasSuccessCurrentBusinessProfile: true }
    }
    case AppActionTypes.SET_COUNTRIES: {
      return { ...state, countries: action.result, isCountriesLoading: false }
    }
    case AppActionTypes.SET_LOADING_COUNTRIES: {
      return { ...state, isCountriesLoading: true }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as appReducer }
