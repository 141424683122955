import { createSelector } from 'reselect'

import { getApp, getProfile, getUser } from '../../common/selectors'
import { ProfileType, UserProfileStatuses } from '../../store/user/types'

export default createSelector([getUser, getApp, getProfile], (user, app, profile) => ({
  user,
  app,
  shouldShowBusinessModal:
    profile.paying_customer &&
    profile.profile_type === ProfileType.INDIVIDUAL &&
    !profile.country_is_ready &&
    !profile.business_info &&
    !profile.legal_status?.includes(UserProfileStatuses.REJECTED) &&
    !profile.policy_status?.includes(UserProfileStatuses.REJECTED),
}))
