import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { IAuthticationProps, IPrivateRouteProps } from './types'

import { Routes } from '@/const/routes'
import IApplicationState from '@/types/state'

export const PrivateRoute = (properties: IPrivateRouteProps) => {
  class Authentication extends Component<IAuthticationProps, {}> {
    constructor(props: any) {
      super(props)

      this.handleRender = this.handleRender.bind(this)
    }

    public render() {
      return <Route {...properties} component={this.handleRender} />
    }

    private handleRender(props: any) {
      const ComposedComponent = properties.component
      if (!this.props.authorized) {
        return <Redirect to={{ pathname: Routes.SIGN_IN, state: { from: props.location } }} />
      } else {
        if (this.props.has_profile || props.location.pathname === Routes.PROFILE) {
          return <ComposedComponent {...props} />
        }
        return <Redirect to={{ pathname: Routes.PROFILE, state: { from: props.location } }} />
      }
    }
  }

  const mapStateToProps = (state: IApplicationState) => ({
    authorized: state.user.isAuthorized,
    has_profile: state.user.has_profile,
    router: state.router,
  })

  const AuthenticationContainer = connect(mapStateToProps)(Authentication)

  return <AuthenticationContainer />
}
