import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { getBrowser } from '../../../../common/selectors'
import { LogoIcon } from '../../../../components/Icons'
import { White } from '../../../../const/colors'
import Lightning from './media/Lightning'
import Star from './media/Star'
import useStyles from './styles'

const Aside = () => {
  const classes = useStyles()
  const browser = useSelector(getBrowser)

  return (
    <>
      {browser.greaterThan.medium && (
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Star />
            <LogoIcon style={{ width: 156, height: 30 }} />
            <h5 className={classes.title}>
              <FormattedMessage id="app.aside.title" />
            </h5>
          </div>
          <Lightning />
        </div>
      )}

      {!browser.greaterThan.extraSmall && (
        <div className={classes.mobileWrapper}>
          <div className={classes.mobileHeader}>
            <LogoIcon fill={White} style={{ width: 93, height: 18 }} />
          </div>
        </div>
      )}
    </>
  )
}

export default Aside
