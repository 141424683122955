import { createSelector } from 'reselect'

import { getCountry, getLanguage, getProfile, getUser } from '../../../../common/selectors'

export default createSelector([getLanguage, getProfile, getUser, getCountry], (language, profile, user, country) => ({
  language,
  profile,
  user,
  country,
}))
