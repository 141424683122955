import { Action, applyMiddleware, compose, createStore } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import { responsiveStoreEnhancer } from 'redux-responsive'
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk'

import rootReducer from '../rootReducer'
import IApplicationState from '../types/state'
import { AppActionTypes } from './app/types'
import history from './history'

const { routerMiddleware, createReduxHistory } = createReduxHistoryContext({ history })

const initialState: IApplicationState = (window as any).initialReduxState
const enhancers = []
const middleware = [thunk, routerMiddleware]

// TODO: Remove this hardcode
type BreakPointsDefaultNames = 'desktop' | 'extraSmall' | 'large' | 'medium' | 'small' | 'container'

export type IBreakPoints<BPNames extends string = BreakPointsDefaultNames> = { [k in BPNames]: number | string }

type ExtraArg = undefined
export type ThunkResult<R> = ThunkAction<R, IApplicationState, ExtraArg, Action>
export type MyThunkDispatch = ThunkDispatch<IApplicationState, ExtraArg, Action>

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

export const composedEnhancers = compose(responsiveStoreEnhancer, applyMiddleware(...middleware), ...enhancers)

// @ts-ignore
const store = createStore(rootReducer(history), initialState, composedEnhancers)
const state = store.getState()

export const historyRedux = createReduxHistory(store)
// hide mobile menu
if (!state.browser.greaterThan.desktop) {
  store.dispatch({ type: AppActionTypes.TOGGLE_MENU, data: false })
}

export default store
