import { createUseStyles } from 'react-jss'

import { TextGreyBase } from '../../../../../const/colors'
import { FontH5 } from '../../../../../const/fontStyles'

export default createUseStyles(
  {
    root: {
      marginBottom: 40,
    },
    title: {
      ...FontH5,
      color: TextGreyBase,
      margin: '0 0 24px',
    },
    alert: {
      marginBottom: 24,
    },
    label: {
      marginBottom: 4,
      display: 'flex',
    },
    ruAlert: {
      marginTop: 12,
    },
  },
  { name: 'JurisdictionInfo' }
)
