import { createUseStyles } from 'react-jss'

import { GreyBase, PurpleBase, PurpleLighten46, SlateGreyLighten50, White } from '../../../../const/colors'
import { FontButtonSmall, FontInteractive } from '../../../../const/fontStyles'

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      background: 'rgba(255, 255, 255, 0.1)',
      padding: '7px 7px 7px 12px',
      border: 'none',
      color: White,
      ...FontButtonSmall,
      textTransform: 'uppercase',
      cursor: 'pointer',

      '& span': {
        marginRight: 5,
      },
    },
    item: {
      ...FontInteractive,
      color: GreyBase,
      padding: '12px 14px',
      cursor: 'pointer',
      borderRadius: 4,

      '&:hover': {
        backgroundColor: SlateGreyLighten50,
        color: PurpleBase,
      },

      '&$itemActive': {
        backgroundColor: PurpleLighten46,
        color: PurpleBase,
      },
    },
    itemActive: {},
    popup: {
      minWidth: 128,
    },
  },
  { name: 'LanguageSwitch' }
)
