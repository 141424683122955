import { createUseStyles } from 'react-jss'

import { SlateGreyLighten50, TextSlateGreyDarken12 } from '@/const/colors'
import { FontBody2, FontCaptionAccent } from '@/const/fontStyles'

export default createUseStyles(
  {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textDecoration: 'none',
    },
    text: {
      ...FontBody2,
      color: TextSlateGreyDarken12,
      textDecoration: 'none',
    },
    iconWrapper: {
      height: 24,
      width: 24,
      backgroundColor: SlateGreyLighten50,
      borderRadius: 6,
      padding: 3,
    },
    tooltipAmount: {
      ...FontCaptionAccent,
    },
  },
  { name: 'Cashback' }
)
