import { ReactNode, SyntheticEvent } from 'react'

export enum BUTTON_VARIANT {
  primary = 'primary',
  secondaryPurple = 'secondaryPurple',
  secondaryGray = 'secondaryGray',
  tertiary = 'tertiary',
  red = 'red',
  green = 'green',
  iconSecondaryGray = 'iconSecondaryGray',
  dashed = 'dashed',
  onGreyBackground = 'onGreyBackground',
  tertiaryNegative = 'tertiaryNegative',
  secondaryDarkGray = 'secondaryDarkGray',
  iconSecondaryGrayNegative = 'iconSecondaryGrayNegative',
  negativeFilled = 'negativeFilled',
}

export enum BUTTON_SIZE {
  size32 = 'size32',
  size35 = 'size35',
  size40 = 'size40',
  icon32 = 'icon32',
  icon36 = 'icon36',
}

export enum BUTTON_TYPE {
  button = 'button',
  icon = 'icon',
  text = 'text',
  toggle = 'toggle',
  submit = 'submit',
}

export enum BUTTON_FILL_TYPE {
  fill = 'fill',
  stroke = 'stroke',
}

interface IToProps {
  pathname?: string
  search?: string
  hash?: string
  state?: any
}

export interface IButtonSMBProps {
  children?: ReactNode
  label?: string | ReactNode
  iconLeft?: ReactNode
  size?: keyof typeof BUTTON_SIZE
  variant?: keyof typeof BUTTON_VARIANT
  type?: keyof typeof BUTTON_TYPE
  fillIconType?: keyof typeof BUTTON_FILL_TYPE
  disabled?: boolean
  minWidth?: number
  fullWidth?: boolean
  className?: string
  to?: string | IToProps
  href?: string
  onClick?: (e: SyntheticEvent) => void
  isLoading?: boolean

  [prop: string]: any
}
