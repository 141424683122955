import { createUseStyles } from 'react-jss'

import { PurpleBase, White } from '../../../../const/colors'
import { FontH5 } from '../../../../const/fontStyles'
import bg from './media/bg.png'
import bgMobile from './media/bg-mobile.png'

export default createUseStyles(
  {
    mobileWrapper: {
      display: 'block',
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      zIndex: 1000,
      height: 50,
      backgroundColor: PurpleBase,
    },
    wrapper: {
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',

      '& > * + svg': {
        position: 'absolute',
        right: 0,
        bottom: 0,
      },
    },
    header: {
      position: 'relative',
      padding: 40,

      '& > svg:first-child': {
        position: 'absolute',
        top: 0,
        left: 211,
      },

      '& > svg + svg': {
        marginBottom: 16,
      },
    },
    mobileHeader: {
      padding: '0 24px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${bgMobile})`,
      backgroundSize: 'cover',
      backgroundPosition: 'right',
    },
    title: {
      ...FontH5,
      margin: 0,
      color: White,
    },
  },
  { name: 'Aside' }
)
