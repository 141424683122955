import { createUseStyles } from 'react-jss'

import {
  GreenBase,
  GreenDarken4,
  GreenDarken9,
  GreenLighten25,
  PurpleBase,
  PurpleDarken4,
  PurpleDarken8,
  PurpleLighten25,
  PurpleLighten40,
  PurpleLighten43,
  PurpleLighten46,
  RedBase,
  RedDarken4,
  RedDarken8,
  RedLighten20,
  RedLighten33,
  RedLighten34,
  SlateGreyBase,
  SlateGreyLighten13,
  SlateGreyLighten22,
  SlateGreyLighten34,
  SlateGreyLighten39,
  SlateGreyLighten43,
  SlateGreyLighten46,
  SlateGreyLighten49,
  SlateGreyLighten50,
  TextGreyBase,
  TextSlateGreyBase,
  TextSlateGreyDarken12,
  TextSlateGreyLighten16,
  Transparent,
  White,
} from '../../const/colors'
import { WidthBPs } from '../../const/commonStyles'
import { FontButtonBig } from '../../const/fontStyles'
import { BUTTON_FILL_TYPE, BUTTON_SIZE, BUTTON_TYPE, BUTTON_VARIANT, IButtonSMBProps } from './types'

export default createUseStyles(
  {
    root: {
      position: 'relative',
      flex: '1 0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...FontButtonBig,
      cursor: 'pointer',
      overflow: 'hidden',
      border: '1px solid transparent',
      borderRadius: 6,
      userSelect: 'none',
      outline: 'none',
      textDecoration: 'none',
      minWidth: (props: IButtonSMBProps) => (props.minWidth ? props.minWidth : 'initial'),

      '&:hover': {
        textDecoration: 'none',
      },

      '&:disabled': {
        pointerEvents: 'none',
      },

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        flexGrow: 'initial',
        display: 'inline-flex',
      },
    },
    content: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > * + *': {
        marginLeft: 6,
      },
    },
    iconWrapper: {
      '& svg': {
        display: 'block',
      },
    },
    label: {},

    [BUTTON_VARIANT.primary]: {
      color: White,
      backgroundColor: PurpleBase,
      borderColor: PurpleBase,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) =>
          props.isLoading ? White : props.fillIconType === BUTTON_FILL_TYPE.fill ? White : 'none',
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) =>
          props.isLoading ? White : props.fillIconType === BUTTON_FILL_TYPE.stroke ? White : 'none',
      },

      '&:hover': {
        backgroundColor: PurpleDarken4,
        borderColor: PurpleDarken4,
      },
      '&:active': {
        backgroundColor: PurpleDarken8,
        borderColor: PurpleDarken8,
      },
      '&:disabled': {
        backgroundColor: (props: IButtonSMBProps) => (props.isLoading ? PurpleBase : PurpleLighten25),
        borderColor: (props: IButtonSMBProps) => (props.isLoading ? PurpleBase : PurpleLighten25),
      },
    },
    [BUTTON_VARIANT.secondaryPurple]: {
      color: PurpleBase,
      backgroundColor: PurpleLighten46,
      borderColor: PurpleLighten46,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? PurpleBase : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? PurpleBase : 'none'),
      },

      '&:hover': {
        backgroundColor: PurpleLighten43,
        borderColor: PurpleLighten43,
      },
      '&:active': {
        backgroundColor: PurpleLighten40,
        borderColor: PurpleLighten40,
      },
      '&:disabled': {
        color: PurpleLighten25,
        backgroundColor: PurpleLighten46,
        borderColor: PurpleLighten46,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? PurpleLighten25 : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? PurpleLighten25 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.secondaryGray]: {
      color: TextGreyBase,
      backgroundColor: SlateGreyLighten49,
      borderColor: SlateGreyLighten49,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? TextGreyBase : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextGreyBase : 'none'),
      },

      '&:hover': {
        color: PurpleBase,
        backgroundColor: PurpleLighten46,
        borderColor: PurpleLighten46,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? PurpleBase : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? PurpleBase : 'none'),
        },
      },
      '&:active': {
        backgroundColor: PurpleLighten43,
        borderColor: PurpleLighten43,
      },
      '&:disabled': {
        color: TextSlateGreyLighten16,
        backgroundColor: SlateGreyLighten50,
        borderColor: SlateGreyLighten50,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? TextSlateGreyLighten16 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextSlateGreyLighten16 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.secondaryDarkGray]: {
      color: TextSlateGreyBase,
      backgroundColor: SlateGreyLighten43,
      borderColor: SlateGreyLighten43,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? TextSlateGreyBase : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) =>
          props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextSlateGreyBase : 'none',
      },

      '&:hover': {
        color: TextSlateGreyDarken12,
        backgroundColor: SlateGreyLighten39,
        borderColor: SlateGreyLighten39,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? TextSlateGreyDarken12 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextSlateGreyDarken12 : 'none',
        },
      },
      '&:active': {
        backgroundColor: SlateGreyLighten34,
        borderColor: SlateGreyLighten34,
      },
      '&:disabled': {
        color: TextSlateGreyLighten16,
        backgroundColor: SlateGreyLighten46,
        borderColor: SlateGreyLighten46,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? TextSlateGreyLighten16 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextSlateGreyLighten16 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.tertiary]: {
      color: TextGreyBase,
      backgroundColor: White,
      borderColor: White,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? TextGreyBase : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextGreyBase : 'none'),
      },

      '&:hover': {
        color: PurpleBase,
        backgroundColor: PurpleLighten46,
        borderColor: PurpleLighten46,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? PurpleBase : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? PurpleBase : 'none'),
        },
      },
      '&:active': {
        backgroundColor: PurpleLighten43,
        borderColor: PurpleLighten43,
      },
      '&:disabled': {
        color: TextSlateGreyLighten16,
        backgroundColor: White,
        borderColor: White,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? TextSlateGreyLighten16 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextSlateGreyLighten16 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.tertiaryNegative]: {
      color: TextGreyBase,
      backgroundColor: White,
      borderColor: White,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? TextGreyBase : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextGreyBase : 'none'),
      },

      '&:hover': {
        color: RedBase,
        backgroundColor: RedLighten34,
        borderColor: RedLighten34,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? RedBase : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? RedBase : 'none'),
        },
      },
      '&:active': {
        backgroundColor: RedLighten33,
        borderColor: RedLighten33,
      },
      '&:disabled': {
        color: SlateGreyLighten34,
        backgroundColor: White,
        borderColor: White,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten34 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyLighten34 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.negativeFilled]: {
      color: SlateGreyLighten13,
      backgroundColor: SlateGreyLighten50,
      borderColor: SlateGreyLighten50,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) =>
          props.isLoading ? TextGreyBase : props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten13 : 'none',
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) =>
          props.isLoading ? TextGreyBase : props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyLighten13 : 'none',
      },

      '&:hover': {
        color: RedBase,
        backgroundColor: RedLighten34,
        borderColor: RedLighten34,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.isLoading ? TextGreyBase : props.fillIconType === BUTTON_FILL_TYPE.fill ? RedBase : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.isLoading ? TextGreyBase : props.fillIconType === BUTTON_FILL_TYPE.stroke ? RedBase : 'none',
        },
      },
      '&:active': {
        backgroundColor: RedLighten33,
        borderColor: RedLighten33,
      },
      '&:disabled': {
        color: SlateGreyLighten34,
        backgroundColor: SlateGreyLighten50,
        borderColor: SlateGreyLighten50,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.isLoading ? TextGreyBase : props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten34 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.isLoading
              ? TextGreyBase
              : props.fillIconType === BUTTON_FILL_TYPE.stroke
              ? SlateGreyLighten34
              : 'none',
        },
      },
    },
    [BUTTON_VARIANT.red]: {
      color: White,
      backgroundColor: RedBase,
      borderColor: RedBase,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? White : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? White : 'none'),
      },

      '&:hover': {
        backgroundColor: RedDarken4,
        borderColor: RedDarken4,
      },
      '&:active': {
        backgroundColor: RedDarken8,
        borderColor: RedDarken8,
      },
      '&:disabled': {
        backgroundColor: RedLighten20,
        borderColor: RedLighten20,
      },
    },
    [BUTTON_VARIANT.green]: {
      color: White,
      backgroundColor: GreenBase,
      borderColor: GreenBase,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? White : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? White : 'none'),
      },

      '&:hover': {
        backgroundColor: GreenDarken4,
        borderColor: GreenDarken4,
      },
      '&:active': {
        backgroundColor: GreenDarken9,
        borderColor: GreenDarken9,
      },
      '&:disabled': {
        backgroundColor: GreenLighten25,
        borderColor: GreenLighten25,
      },
    },
    [BUTTON_VARIANT.iconSecondaryGray]: {
      backgroundColor: SlateGreyLighten50,
      borderColor: SlateGreyLighten50,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten13 : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) =>
          props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyLighten13 : 'none',
      },

      '&:hover': {
        backgroundColor: PurpleLighten46,
        borderColor: PurpleLighten46,
        color: PurpleBase,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? PurpleBase : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? PurpleBase : 'none'),
        },
      },
      '&:active': {
        backgroundColor: PurpleLighten43,
        borderColor: PurpleLighten43,
        color: PurpleBase,
      },
      '&:disabled': {
        backgroundColor: SlateGreyLighten50,
        borderColor: SlateGreyLighten50,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten34 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyLighten34 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.iconSecondaryGrayNegative]: {
      backgroundColor: SlateGreyLighten50,
      borderColor: SlateGreyLighten50,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten13 : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) =>
          props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyLighten13 : 'none',
      },

      '&:hover': {
        color: RedBase,
        backgroundColor: RedLighten34,
        borderColor: RedLighten34,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? RedBase : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? RedBase : 'none'),
        },
      },
      '&:active': {
        backgroundColor: RedLighten33,
        borderColor: RedLighten33,
      },
      '&:disabled': {
        backgroundColor: SlateGreyLighten50,
        borderColor: SlateGreyLighten50,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten34 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyLighten34 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.dashed]: {
      backgroundColor: Transparent,
      border: `1px dashed ${SlateGreyLighten22}`,
      color: TextSlateGreyBase,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? TextSlateGreyBase : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) =>
          props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextSlateGreyBase : 'none',
      },

      '&:hover': {
        backgroundColor: PurpleLighten46,
        borderColor: PurpleLighten25,
        color: PurpleBase,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? PurpleBase : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? PurpleBase : 'none'),
        },
      },

      '&:active': {
        backgroundColor: PurpleLighten43,
        borderColor: PurpleLighten25,
      },

      '&:disabled': {
        backgroundColor: Transparent,
        borderColor: SlateGreyLighten34,
        color: TextSlateGreyLighten16,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? TextSlateGreyLighten16 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? TextSlateGreyLighten16 : 'none',
        },
      },
    },
    [BUTTON_VARIANT.onGreyBackground]: {
      backgroundColor: SlateGreyLighten43,
      borderColor: SlateGreyLighten43,

      '& svg, & path': {
        fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyBase : 'none'),
      },

      '& path, & circle': {
        stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyBase : 'none'),
      },

      '&:hover, &:focus': {
        backgroundColor: SlateGreyLighten39,
        borderColor: SlateGreyLighten39,
        color: SlateGreyBase,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyBase : 'none'),
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) => (props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyBase : 'none'),
        },
      },
      '&:active, &:active:focus': {
        backgroundColor: SlateGreyLighten34,
        borderColor: SlateGreyLighten34,
        color: SlateGreyBase,
      },
      '&:disabled': {
        backgroundColor: SlateGreyLighten46,
        borderColor: SlateGreyLighten46,

        '& svg, & path': {
          fill: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.fill ? SlateGreyLighten34 : 'none',
        },

        '& path, & circle': {
          stroke: (props: IButtonSMBProps) =>
            props.fillIconType === BUTTON_FILL_TYPE.stroke ? SlateGreyLighten34 : 'none',
        },
      },
    },

    [BUTTON_SIZE.size32]: {
      minHeight: 32,
      padding: '7px 20px',
    },
    [BUTTON_SIZE.size35]: {
      minHeight: 35,
      padding: '7.5px 20px',
    },
    [BUTTON_SIZE.size40]: {
      minHeight: 40,
      padding: '11px 20px',
    },
    [BUTTON_SIZE.icon32]: {
      minHeight: 32,
      padding: 6,
    },
    [BUTTON_SIZE.icon36]: {
      minHeight: 36,
      padding: 8,
    },
    [BUTTON_TYPE.button]: {},
    [BUTTON_TYPE.icon]: {
      padding: 10,
    },
    [BUTTON_TYPE.text]: {
      padding: 0,
      border: 'none',
      minHeight: 'initial',
      backgroundColor: 'transparent',

      '&:hover, &:active, &:disabled': {
        backgroundColor: 'transparent',
      },
    },
    [BUTTON_TYPE.toggle]: {
      padding: '6px 12px',
    },
    [BUTTON_TYPE.submit]: {},

    fullWidth: {
      width: '100%',
    },

    disabledLink: {
      pointerEvents: 'none',
    },
    isLoading: {
      visibility: 'hidden',
      position: 'absolute',
      pointerEvents: 'none',
    },
    loaderWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'translate(0px, -4px)',
      minWidth: 64,
      maxHeight: 16,
    },
  },
  { name: 'ButtonSMB' }
)
