import React from 'react'
import { IBreakPointResults } from 'redux-responsive'

import { IBreakPoints } from '@/store/store'

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type IEventFunc = (event: React.FormEvent<HTMLInputElement>) => void

export type IChangeFunc = (event: React.ChangeEvent<HTMLInputElement>) => void

export enum AgencyDocsReceivedStatus {
  EMPTY = 'empty',
  SENT = 'sent',
  APPROVED = 'approved',
}

export interface IDecodedJWT {
  exp: number
  jti: string
  token_type: string
  user_id: number
}

export interface IFormField {
  hasError: boolean
  errorText: string
  touched: boolean
  value: string
}

export interface ISingleCheckboxField {
  hasError: boolean
  errorText: string
  touched: boolean
  value: boolean
}

export interface IPromocode {
  code: string
  terms: string
}

export type NetworkTypes = 'facebook' | 'tiktok'

export type NetworkExtraTypes = NetworkTypes | 'telegram'

export enum Pages {
  ALL = 'all',
  FIRST = 1,
}

export enum GroupingTabValues {
  PLATFORM = 'platform',
  CLIENT = 'client',
}

export enum Platforms {
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  ARCHIVED_ACCOUNTS = 'archived_accounts',
  TELEGRAM = 'telegram',
}

export enum DashlyQuestEvents {
  AUTH = '$authorized',
  INVOICE = 'Get invoice',
}

export enum DashlyQuestProps {
  FIRST_ACCOUNT = 'Date creation first account',
  TELEGRAM_ADS_START_FILL_REQUEST = 'Начал заполнять заявку Telegram Ads',
  TELEGRAM_ADS_END_FILL_REQUEST = 'Завершил заполнение формы Telegram Ads',
}

export interface IBrowserInterface<BP = IBreakPoints> {
  _responsiveState: boolean
  mediaType: string
  orientation: string
  lessThan: IBreakPointResults<BP>
  greaterThan: IBreakPointResults<BP>
  is: IBreakPointResults<BP>
  breakpoints: any
}

export enum ContractTypes {
  CONTRACT = 'contract',
  POSTPAYMENT = 'postpayment',
  OFERTA = 'oferta',
}

export type DelimitersType = SpaceDelimiterDot | SpaceDelimiterComma
export type SpaceDelimiterComma = [' ', ',']
export type SpaceDelimiterDot = [' ', '.']

export enum Industries {
  OTHERS = 'others',
  EDUCATION = 'education',
  CRYPTOCURRENCIES = 'cryptocurrencies',
}
