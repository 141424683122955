export enum RequestsActionTypes {
  SET_REQUESTS = 'SET_REQUESTS',
  SET_REQUEST = 'SET_REQUEST',
}

export interface IRequestsState {
  facebook: IAccountRequest[]
  tiktok: IAccountRequest[]
}

export interface IAccountRequest {
  website: string
  industry: number
  subindustry: number
}
