export const enum MIXPANEL_TYPE {
  ADD_PLATFORM = 'AccountCreationStart',
}

export enum GA4_ACTIONS {
  ACCOUNT_START_TO_CREATE = 'AccountStartToCreate',
  ACCOUNT_CREATING_BUTTON_CLICK = 'AccountCreatingButtonClick',
  ACCOUNT_PARTNER_CONNECT_FACEBOOK = 'AccountPartnerConnectFacebook',
  ACCOUNT_PARTNER_CHOOSE_BM = 'AccountPartnerChooseBM',
  ACCOUNT_PARTNER_HOW_TO_CREATE_BM = 'AccountPartnerHowToCreateBM',
  ACCOUNT_PARTNER_HOW_TO_FIND_BM = 'AccountPartnerHowToFindBM',
  ACCOUNT_PARTNER_SEND_REQUEST = 'AccountPartnerSendRequest',
  ACCOUNT_PARTNER_DELETE_REQUEST = 'AccountPartnerDeleteRequest',
  ACCOUNT_PARTNER_ADD_REQUEST = 'AccountPartnerAddRequest',
  ACCOUNT_PARTNER_INFO_REQUEST = 'AccountPartnerInfoRequest',
  SETTINGS_CONNECT_FB = 'SettingsConnectFB',
  ACCOUNT_STATUS_CLICK = 'AccountStatusClick',
  SIGNUP_VISIT = 'SignupVisit',
  SIGNUP_COUNTRY_CLICK = 'SignupCountryclick',
  SIGNUP_POLITICS_CLICK = 'SignupPoliticsClick',
  SIGNUP_ENTER_CLICK = 'SignupEntrenceClick',
  SIGNIN_ENTER_CLICK = 'SiginpEntrenceClick',
  SIGNUP_CHAT_CLICK = 'SignupChatClick',
  SIGNUP_TRY_TO_FINISH = 'SignupTrytofinish',
  SIGNUP_SUCCESS = 'SignupSuccess',
  SIGNUP_WARNING = 'SignupWarning',
  SIGNUP_PLATFORM = 'SignupPlatformClick',
  SIGNUP_BUDGET = 'SignupBudgetClick',
  SIGNUP_BUDGET_PLATFORM_CLICK = 'SignupStepPlatformBudgetClick',
  SIGNUP_PHONE_CLICK = 'SignupPhonecodeClick',
  SIGNUP_STEP_PHONE_CLICK = 'SignupStepPhoneClick',
  SIGNUP_RECO_CLICK = 'SignupRecoClick',
  TELEGRAM_LANDING_BUTTON_TYPE_CLICK = 'TelegramLandingButtonTypeClick',
  TELEGRAM_LANDING_BUTTON_INDUSTRY_CLICK = 'TelegramLandingButtonIndustryClick',
  ACCOUNT_TELEGRAM_CONNECT = 'AccountTelegramConnect',
  ACCOUNT_TELEGRAM_CONNECT_CANCEL = 'AccountTelegramConnectCancel',
  ACCOUNT_TELEGRAM_REQUEST_SUCCESS = 'AccountTelegramRequestSuccess',
  TELEGRAM_ADS_BLOG = 'TelegramadsGoToBlog',
}

export enum GA4_PLACES {
  SIGNUP = 'Signup',
  SIGNIN = 'Signin',
}

export enum GA4_KEYS {
  PLATFORM = 'platform',
  PLACE = 'place',
  COUNTRY = 'country',
  PROFILE_ID = 'profile_id',
  TYPE = 'type',
  COUNTER = 'counter',
  CNT_FILL_ACCOUNTS = 'cnt_fill_accounts',
  MAX_ACCOUNTS = 'max_accounts',
  WORKFLOW = 'workflow',
  STATUS = 'status',
  PAGE = 'page',
  TEXT = 'text',
  PLATFORM_SINGUP = 'Platform',
  BUDGET = 'Budget',
  INDUSTRY = 'industry',
}

export type GA4EventParamTypes = {
  [key in GA4_KEYS]?: string | number | null
}

export interface IGA4EventProps extends GA4EventParamTypes {
  action: GA4_ACTIONS
}
