import { createSelector } from 'reselect'

import { getApp, getBrowser, getProfile, getReferral, getUser } from '@/common/selectors'
import { PAYS_TO_TYPES } from '@/store/user/types'
import { ContractTypes } from '@/types'

export const IsNotViewableFeature = 'isNotViewableFeature'
export const IsNotViewableReportsFeature = 'isNotViewableReportsFeature'
export const IsNotViewableInviteFriend = 'isNotViewableInviteFriend'
export const IsNotViewableTrackerFeature = 'isNotViewableTrackerFeature'

const getIsNotViewable = (viewableValueName: string) => {
  const viewableValue = window.localStorage.getItem(viewableValueName)

  return (viewableValue && JSON.parse(viewableValue)) ?? true
}

const getIsNotViewableFeature = () => getIsNotViewable(IsNotViewableFeature)
const getIsNotViewableReportsFeature = () => getIsNotViewable(IsNotViewableReportsFeature)
const getIsNotViewableInviteFriend = () => getIsNotViewable(IsNotViewableInviteFriend)
const getIsNotViewableTrackerFeature = () => getIsNotViewable(IsNotViewableTrackerFeature)

export default createSelector(
  [
    getApp,
    getBrowser,
    getProfile,
    getReferral,
    getUser,
    getIsNotViewableFeature,
    getIsNotViewableReportsFeature,
    getIsNotViewableInviteFriend,
    getIsNotViewableTrackerFeature,
  ],
  (
    app,
    browser,
    profile,
    referral,
    user,
    isNotViewableFeature,
    isNotViewableReportsFeature,
    isNotViewableInviteFriend,
    isNotViewableTrackerFeature
  ) => ({
    isDesktop: browser.greaterThan.medium,
    isFinanceMenuOpened: app.isFinanceMenuOpened,
    paymentMethod: profile.payment_method,
    isToolMenuOpened: app.isToolMenuOpened,
    isMenuOpened: app.isMenuOpened,
    isAnalyticsMenuOpened: app.isAnalyticsMenuOpened,
    closedoc_type: profile.closedoc_type,
    referral,
    profile_type: user.profile.profile_type,
    payment_method: user.profile.payment_method,
    isNotViewableFeature,
    isNotViewableReportsFeature,
    isNotViewableInviteFriend,
    isNotViewableTrackerFeature,
    isPostPayment: profile.contract_type === ContractTypes.POSTPAYMENT,
    isHiddenMenu: [
      PAYS_TO_TYPES.KZ_ADHAND,
      PAYS_TO_TYPES.GE_ADHAND,
      PAYS_TO_TYPES.AM_ADHAND,
      PAYS_TO_TYPES.CY_ADHAND,
      PAYS_TO_TYPES.AE_ADHAND,
    ].includes(profile.pays_to),
  })
)
