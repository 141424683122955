import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { PromocodeActionTypes } from './types'

export function setPromocode(code: string): AxiosPromise<any> {
  return customAxios(
    `${API_URL}/promocodes/`,
    'PATCH',
    {
      code,
    },
    true
  )
    .then((response: AxiosResponse<any>) => response)
    .catch((error: AxiosError) => Promise.reject(error))
}

export const getUsedPromocode = (): ThunkResult<Promise<any>> => {
  return async (dispatch: Dispatch<AnyAction>): Promise<any> => {
    try {
      const response = await customAxios(`${API_URL}/promocodes/used/`, 'GET', null, true)

      if (response.status === 200) {
        dispatch({ type: PromocodeActionTypes.SET_PROMOCODE, data: response.data })
        return response
      } else {
        return Promise.reject(response)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
