import { Reducer } from 'redux'

import { IPromoState, PromoActionTypes } from './types'

export const initialState: IPromoState = {
  campaign_slug: '',
  promo_url: null,
}

const reducer: Reducer<IPromoState> = (state = initialState, action: any) => {
  switch (action.type) {
    case PromoActionTypes.SET_PROMO: {
      return { ...action.data }
    }
    default: {
      return state
    }
  }
}

export { reducer as promoReducer }
