import styled from 'styled-components'

import Button from '../../../../components/Button'
import {
  greyClrEmpty,
  PinkBase,
  pinkClrLightest,
  pinkFocusClr,
  pinkHoverClr,
  scrollbarClr,
  White,
} from '../../../../const/colors'

export const Wrapper = styled.div<{ isOpened: boolean; isDesktop: boolean }>`
  width: ${(props: { isOpened: boolean; isDesktop: boolean }) =>
    !props.isOpened && props.isDesktop ? '65px' : '240px'};
  background-color: ${White};
  flex-shrink: 0;
  border-right: 1px solid ${greyClrEmpty};
  overflow: hidden;
  padding: 6px 0 0;

  ${(props: { isDesktop: boolean; isOpened: boolean }) =>
    props.isDesktop
      ? `
    position: relative;
  `
      : `
    position: fixed;
    z-index: 3;
    top: 50px;
    bottom: 0;
    left: 0;
    transform: translateX(${props.isOpened ? '0' : '-240px'});
  `}
`

export const Header = styled.div`
  display: grid;
  grid-gap: 13px;
  padding: 14px 20px 18px;
  border-bottom: 1px solid ${greyClrEmpty};
`

export const StyledBalanceButton = styled(Button)`
  position: relative;
  height: 32px;
  font-size: 12px;
  background-color: ${pinkClrLightest};
  color: ${PinkBase};
  border-color: ${pinkClrLightest};
  padding: 0;
  width: 199px;
  box-shadow: none;
  display: flex;
  flex-shrink: 0;

  &:hover:not(:disabled) {
    background-color: ${pinkHoverClr};
    color: ${PinkBase};
    border-color: ${pinkHoverClr};
  }

  &:focus:not(:disabled) {
    background-color: ${pinkFocusClr};
    color: ${PinkBase};
    border-color: ${pinkFocusClr};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: none;
  }
`

export const Toggle = styled.button<{ isMenuOpened: boolean }>`
  background-color: #dedede;
  border-radius: 16px 0 0 16px;
  width: 33px;
  height: 30px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 20px;
  z-index: 2;

  & svg {
    transform: rotate(${(props: { isMenuOpened: boolean }) => (props.isMenuOpened ? '0deg' : '180deg')});
    flex-shrink: 0;
  }
`

export const SubMenuWrapper = styled.div<{ isMenuOpened: boolean }>`
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${scrollbarClr};
    border-radius: 17px;
  }

  max-height: calc(
    var(--app-height) - 50px - 6px ${(props: { isMenuOpened: boolean }) => (props.isMenuOpened ? ' - 127px' : '')}
  );
  overflow: hidden auto;
  padding-bottom: 20px;
`
