import React from 'react'

import { PurpleBase, SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.0435 18.4285C22.0435 19.623 21.107 20.5807 19.9391 20.5807H3.10435C2.54624 20.5807 2.01099 20.3539 1.61635 19.9503C1.22171 19.5467 1 18.9993 1 18.4285V15.0807C2.16791 15.0807 3.86957 14.0719 3.86957 11.972C3.86957 9.87209 2.16616 8.86328 1 8.86328V5.51546C1 4.94466 1.22171 4.39725 1.61635 3.99364C2.01099 3.59003 2.54624 3.36328 3.10435 3.36328H9.72886C9.64957 3.67379 9.6087 3.99508 9.6087 4.3198C9.6087 4.72869 9.67351 5.13213 9.7982 5.51546C9.97882 6.07072 10.2851 6.5838 10.702 7.01012C11.3496 7.6723 12.2165 8.07513 13.1406 8.13741L10.8247 10.5154C10.5432 10.7923 10.3193 11.1202 10.1634 11.4796C10.002 11.8516 9.91592 12.2512 9.90898 12.6551C9.90204 13.059 9.97431 13.4613 10.1227 13.8384C10.271 14.2157 10.4932 14.5617 10.7785 14.8546C11.0638 15.1476 11.4066 15.3815 11.7879 15.5396C12.1692 15.6978 12.5795 15.7761 12.9938 15.7686C13.408 15.7611 13.8151 15.668 14.1903 15.4963C14.5525 15.3306 14.8764 15.0957 15.1456 14.8072L17.1676 12.7309C17.3121 13.4528 17.6614 14.1261 18.1858 14.6623C18.6581 15.1453 19.2472 15.4904 19.8901 15.6659C20.2082 15.7527 20.5395 15.7981 20.8764 15.7981C21.2776 15.7981 21.6709 15.7338 22.0435 15.6116V18.4285Z"
        fill={SlateGreyLighten22}
      />
      <path
        d="M23 3.506C23.0051 3.80337 22.8976 4.09116 22.7002 4.30875L13.7574 13.4914C13.6551 13.6042 13.5317 13.6946 13.3945 13.7574C13.2574 13.8201 13.1094 13.8538 12.9593 13.8566C12.8092 13.8593 12.6601 13.8309 12.5209 13.7732C12.3817 13.7155 12.2553 13.6295 12.1491 13.5205C12.043 13.4115 11.9593 13.2817 11.9031 13.1388C11.8469 12.9959 11.8193 12.8428 11.8219 12.6887C11.8246 12.5345 11.8574 12.3825 11.9185 12.2417C11.9796 12.1009 12.0677 11.9742 12.1775 11.8691L21.1203 2.68648C21.3976 2.42669 21.7004 2.42669 21.91 2.42669C22.2768 2.42669 22.5099 2.54741 22.6728 2.71461C22.8356 2.88181 22.9949 3.20864 23 3.506Z"
        fill={PurpleBase}
      />
      <path
        d="M19.4895 10.3748C19.1038 10.7605 18.8871 11.2836 18.8871 11.829C18.8871 12.3744 19.1038 12.8975 19.4895 13.2832C19.8751 13.6689 20.3982 13.8855 20.9436 13.8855C21.4891 13.8855 22.0122 13.6689 22.3978 13.2832C22.7835 12.8975 23.0002 12.3744 23.0002 11.829C23.0002 11.2836 22.7835 10.7605 22.3978 10.3748C22.0122 9.98915 21.4891 9.77248 20.9436 9.77248C20.3982 9.77248 19.8751 9.98915 19.4895 10.3748Z"
        fill={PurpleBase}
      />
      <path
        d="M12.2195 2.72246C11.8339 3.10813 11.6172 3.63121 11.6172 4.17664C11.6172 4.72206 11.8339 5.24515 12.2195 5.63082C12.6052 6.01649 13.1283 6.23316 13.6737 6.23316C14.2191 6.23316 14.7422 6.01649 15.1279 5.63082C15.5136 5.24515 15.7302 4.72206 15.7302 4.17664C15.7302 3.63121 15.5136 3.10813 15.1279 2.72246C14.7422 2.33679 14.2191 2.12012 13.6737 2.12012C13.1283 2.12012 12.6052 2.33679 12.2195 2.72246Z"
        fill={PurpleBase}
      />
    </svg>
  )
}
