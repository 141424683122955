import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import * as Sentry from '@sentry/browser'

import { ErrorBlock } from './styled'
import { IErrorBoundaryProps, IErrorBoundaryState } from './types'

/**
 * Wrapper for components to catch their errors and show red error block.
 */
class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  public componentDidCatch(error: Error) {
    this.setState({ error })
    Sentry.captureException(error)
  }

  public onClick = () => {
    return Sentry.lastEventId() && Sentry.showReportDialog()
  }

  public render() {
    if (this.state.error) {
      return (
        <ErrorBlock onClick={this.onClick}>
          <p>
            <FormattedMessage id="components.errorBoundary.title" />
          </p>
          <p>
            <FormattedMessage id="components.errorBoundary.text" />
          </p>
        </ErrorBlock>
      )
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
