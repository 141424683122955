import { createUseStyles } from 'react-jss'

import {
  PurpleLighten6,
  PurpleLighten46,
  SlateGreyLighten13,
  SlateGreyLighten50,
  TextGreyBase,
  TextSlateGreyDarken12,
} from '@/const/colors'
import { WidthBPs } from '@/const/commonStyles'
import { FontBody1, FontBody2, FontH5 } from '@/const/fontStyles'

export default createUseStyles(
  {
    root: {
      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        marginTop: 16,
      },
    },
    stepWrapper: {
      marginBottom: 24,
      display: 'block',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        display: 'inline-flex',
        alignItems: 'center',
      },
    },
    title: {
      ...FontH5,
      margin: 0,
      color: TextGreyBase,
    },
    step: {
      ...FontBody1,
      fontWeight: 500,
      height: 31,
      padding: '0 16px',
      borderRadius: 36,
      backgroundColor: PurpleLighten46,
      color: PurpleLighten6,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 0 16px',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        margin: '0 16px 0 0',
      },
    },
    closeButton: {
      position: 'absolute',
      top: 28,
      right: 0,

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        top: 18,
        right: 18,
      },
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    error: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,

      '& svg': {
        marginRight: 4,
      },

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        marginBottom: 0,
      },
    },
    buttonGroup: {
      display: 'flex',
      '& button': {
        width: '100%',

        '&:first-child': {
          marginRight: 10,
          flexShrink: 2,
        },

        '&:last-child': {
          flexShrink: 1,
        },

        [`@media (min-width: ${WidthBPs.SMALL})`]: {
          width: 'inherit',
        },
      },
    },
    buttonFlexGroup: {
      display: 'flex',
    },
    alert: {
      borderRadius: 6,
      backgroundColor: SlateGreyLighten50,
      padding: '8px 12px',
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 24,
      ...FontBody2,
      color: TextSlateGreyDarken12,
    },
    alertIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: SlateGreyLighten13,
      flexShrink: 0,
      margin: '1px 11px 0 0',

      '& svg': {
        flexShrink: 0,
      },
    },
    skipButton: {
      marginRight: 0,
      gridArea: 'skip',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        marginRight: 10,
      },
    },
    buttonTwoColumnsGrid: {
      display: 'grid',
      gridTemplateColumns: '40px 1fr',
      gridTemplateAreas: `'prev next' 'skip skip'`,
      gridGap: 10,

      '& button': {
        width: '100%',

        '&:first-child': {
          gridArea: 'prev',
          marginRight: 0,
          flexShrink: 0,
        },

        '&:last-child': {
          flexShrink: 0,
          gridArea: 'next',
        },
      },
    },
    buttonSingleColumnGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `'next' 'skip'`,
      gridGap: 10,

      '& button': {
        width: '100%',

        '&:last-child': {
          flexShrink: 0,
          gridArea: 'next',
        },
      },
    },
    textButton: {
      position: 'absolute',
      top: 30,
      right: 43,
    },
  },
  { name: 'BusinessProfileModal' }
)
