import { createSelector } from 'reselect'

import { getApp, getBrowser, getProfile } from '@/common/selectors'
import { PAYS_TO_TYPES } from '@/store/user/types'
import { ContractTypes } from '@/types'

export default createSelector([getApp, getBrowser, getProfile], (app, browser, profile) => ({
  isDesktop: browser.greaterThan.desktop,
  isMenuOpened: app.isMenuOpened,
  isProfileClient: profile.is_profile_client,
  isPostPayment: profile.contract_type === ContractTypes.POSTPAYMENT,
  isHiddenBalance: [
    PAYS_TO_TYPES.KZ_ADHAND,
    PAYS_TO_TYPES.GE_ADHAND,
    PAYS_TO_TYPES.AM_ADHAND,
    PAYS_TO_TYPES.CY_ADHAND,
    PAYS_TO_TYPES.AE_ADHAND,
  ].includes(profile.pays_to),
}))
