import { Reducer } from 'redux'

import { reducerName } from './modals/actions'
import { IInvoicesState, InvoicesActionTypes } from './types'

export const initialState: IInvoicesState = {
  modals: null,
  invoicesList: {
    count: 0,
    current: 0,
    next: null,
    page_count: 0,
    previous: null,
    results: [],
    loading: false,
  },
  invoiceId: null,
  loadingInvoices: [],
}

const invoicesReducer: Reducer<IInvoicesState> = (state = initialState, action: any) => {
  switch (action.type) {
    case InvoicesActionTypes.SET_INVOICES: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        invoicesList: {
          ...listParams,
          loading: false,
          results: [...state.invoicesList.results, ...results],
        },
      }
    }
    case InvoicesActionTypes.UPDATE_INVOICES: {
      return { ...state, invoicesList: { ...action.data, loading: false } }
    }
    case InvoicesActionTypes.SET_INVOICE_LOADING: {
      return { ...state, loadingInvoices: [...state.loadingInvoices, action.id] }
    }
    case InvoicesActionTypes.REMOVE_INVOICE_LOADING: {
      return { ...state, loadingInvoices: [...state.loadingInvoices.filter((item) => item !== action.id)] }
    }
    case InvoicesActionTypes.SET_INVOICES_LOADING: {
      return { ...state, invoicesList: { ...state.invoicesList, loading: true } }
    }
    case InvoicesActionTypes.SET_INVOICE_ID: {
      return { ...state, invoiceId: action.data }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default invoicesReducer
