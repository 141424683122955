import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import BusinessProfileModal from './BusinessProfileModal'
import RedirectModal from './RedirectEvidoModal'
import selector from './selector'

import { closeAppModal, openAppModal } from '@/store/app'
import { MODALS } from '@/store/app/types'

export interface IModalProps {
  onClose: () => void
}

const AppModals = () => {
  const { modals } = useSelector(selector)
  const dispatch = useDispatch()

  const search = useLocation().search

  useEffect(() => {
    const isRedirect = new URLSearchParams(search).get('from_to')

    if (isRedirect) {
      dispatch(openAppModal({ name: MODALS.REDIRECT_EVIDO }))
    }
  }, [])

  const onClose = () => dispatch(closeAppModal())

  switch (modals?.name) {
    case MODALS.BUSINESS_PROFILE: {
      return <BusinessProfileModal onClose={onClose} />
    }
    case MODALS.REDIRECT_EVIDO: {
      return <RedirectModal onClose={onClose} />
    }
    default:
      return null
  }
}

export default AppModals
