export enum OnboardingActionTypes {
  SET_ACCOUNTS_ONBOARDING = 'SET_ACCOUNTS_ONBOARDING',
}

export interface IOnboardingObject {
  account_type: 'facebook' | 'tiktok'
  description: string
  slug: string
  title: string
  urls: {
    title: string
    url: string
  }
}

export interface IOnboardingState {
  readonly facebook: IOnboardingObject[]
  readonly tiktok: IOnboardingObject[]
  readonly telegram: IOnboardingObject[]
}
