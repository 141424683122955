import { createUseStyles } from 'react-jss'

import { RedDarken16, TextSlateGreyBase } from '../../const/colors'
import { FontCaption } from '../../const/fontStyles'

export default createUseStyles(
  {
    helpText: {
      color: TextSlateGreyBase,
      ...FontCaption,
    },
    error: {
      color: RedDarken16,
    },
  },
  { name: 'FieldHelpText' }
)
