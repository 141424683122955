import styled from 'styled-components'

import { White } from '../../const/colors'
import { outMainSpace, WidthBPs } from '../../const/commonStyles'
import Header from '../Header'

export const PageWrapper = styled.div<any>`
  display: grid;

  grid-template-rows: auto 1fr;
  grid-template-columns: auto;
  grid-template-areas: 'header' 'content';

  min-height: var(--app-height);
`

export const PreviewWrapper = styled.div`
  display: grid;

  background-color: ${White};

  grid-template-columns: 1fr;
  grid-template-areas: 'content';

  @media (min-width: ${WidthBPs.MEDIUM}) {
    grid-template-columns: 511px auto;
    grid-template-areas: 'aside content';
  }

  min-height: var(--app-height);
`

export const ContentBlock = styled.main<{ hasProfile: boolean; isAuthorized: boolean; isWhite: boolean }>`
  width: 100%;
  grid-area: content;
  justify-self: center;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: ${WidthBPs.MEDIUM}) {
    display: initial;
  }

  ${(props: { hasProfile: boolean; isWhite: boolean }) =>
    props.hasProfile && !props.isWhite ? null : `background-color: ${White};`}
  ${(props: { hasProfile: boolean; isAuthorized: boolean }) =>
    !props.hasProfile ? `padding: 0;` : `padding: 28px 0 ${outMainSpace};`}
`

export const StyledHeader = styled(Header)`
  grid-area: header;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
`

export const ContentWrapper = styled.div<{ isAuthorized: boolean }>`
  display: flex;
  height: ${(props: { isAuthorized: boolean }) =>
    props.isAuthorized ? 'calc(var(--app-height) - 50px)' : 'var(--app-height)'};
  position: relative;
  top: ${(props: { isAuthorized: boolean }) => (props.isAuthorized ? '50px' : '0')};
  overflow: hidden;
`
