import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import selector from './selector'
import useStyles from './styles'

import { Mixpanel } from '@/analytics'
import { getNumWithDelimiter } from '@/common/utils'
import { EyeIcon } from '@/components/Icons'
import { Tooltip } from '@/components/TooltipSMB'
import { Delimiters, NetworkNames } from '@/const'
import { SlateGreyLighten13 } from '@/const/colors'
import { Routes } from '@/const/routes'
import { CurrencySign } from '@/store/user/types'

const Cashback = () => {
  const { cashback, isAgency } = useSelector(selector)

  const classes = useStyles()

  const onMyCashbackClick = () => {
    Mixpanel.track('HeaderGoToMyCashback')
  }

  const tooltipContent = (
    <div>
      <div>
        &mdash;&nbsp;
        <span className={classes.tooltipAmount}>
          {CurrencySign.USD}&nbsp;{getNumWithDelimiter(cashback?.facebook_amount ?? 0, Delimiters.SPACE_COMMA)}
        </span>{' '}
        <FormattedMessage id="containers.cashback.for" />
        &nbsp;{NetworkNames.facebook}
      </div>
      {isAgency && (
        <div>
          &mdash;&nbsp;
          <span className={classes.tooltipAmount}>
            {CurrencySign.EUR}&nbsp;{getNumWithDelimiter(cashback?.telegram_amount ?? 0, Delimiters.SPACE_COMMA)}
          </span>{' '}
          <FormattedMessage id="containers.cashback.for" />
          &nbsp;{NetworkNames.telegram}
        </div>
      )}
    </div>
  )

  return cashback ? (
    <Link className={classes.root} to={Routes.MY_CASHBACK} onClick={onMyCashbackClick}>
      <div className={classes.text}>
        <FormattedMessage id="containers.cashback.text" />
        &nbsp;{cashback.month}
      </div>
      <Tooltip id="cashback" content={tooltipContent} place="left">
        <div className={classes.iconWrapper}>
          <EyeIcon fill={SlateGreyLighten13} />
        </div>
      </Tooltip>
    </Link>
  ) : null
}

export default Cashback
