import { createSelector } from 'reselect'

import { getApp, getBrowser, getCountry, getProfile } from '@/common/selectors'
import { PaymentMethod, ProfileType } from '@/store/user/types'

export default createSelector([getBrowser, getApp, getCountry, getProfile], (browser, app, country, profile) => {
  return {
    browserMedia: browser.greaterThan,
    hasSuccessBusinessProfile: app.hasSuccessBusinessProfile,
    hasSuccessCurrentBusinessProfile: app.hasSuccessCurrentBusinessProfile,
    country: country.code,
    isPayingCustomer: profile.paying_customer,
    paymentMethod: profile.payment_method,
    profileType: profile.profile_type,
    countries: app.countries,
    isCountriesLoading: app.isCountriesLoading,
    isCountryConfirm: profile.is_country_confirmed,
    isCountryReady: profile.country_is_ready,
    isCanChangeProfileType:
      profile.profile_type === ProfileType.INDIVIDUAL &&
      !profile.paying_customer &&
      profile.payment_method === PaymentMethod.CARD,
  }
})
