import { Reducer } from 'redux'

import { AdAccountExtraTypes, AdAccountsActionTypes, IAdAccount, IAdAccountsState, IPlatformState } from './types'

import { Pages } from '@/types'

const initialPlatformState: IPlatformState = {
  accounts: [],
  error: null,
  isLoading: false,
}

const initialState: IAdAccountsState = {
  archived_accounts: initialPlatformState,
  facebook: initialPlatformState,
  pagination_object: {
    count: 0,
    current: 0,
    next: null,
    page_count: 0,
    previous: null,
    results: [],
  },
  tiktok: initialPlatformState,
  telegram: initialPlatformState,
}

const reducer: Reducer<IAdAccountsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AdAccountsActionTypes.SET_AD_ACCOUNTS: {
      const data = action.data
        .slice()
        .sort((a: IAdAccount, b: IAdAccount) => a.id - b.id)
        .sort((a: IAdAccount, b: IAdAccount): number => {
          if (b.account_type > a.account_type) {
            return -1
          }
          if (b.account_type < a.account_type) {
            return 1
          }

          return 0
        })

      return {
        ...state,
        [action.network]: {
          accounts:
            action.hasPagination && action.page !== Pages.FIRST
              ? [...state[action.network as AdAccountExtraTypes].accounts, ...action.data]
              : [...data],
          error: null,
          isLoading: false,
        },
      }
    }
    case AdAccountsActionTypes.SET_AD_ACCOUNT: {
      return {
        ...state,
        [action.network]: {
          accounts: [...state[action.network as AdAccountExtraTypes].accounts, action.data],
          error: null,
          isLoading: false,
        },
      }
    }
    case AdAccountsActionTypes.UPDATE_AD_ACCOUNT: {
      const index = state[action.network as AdAccountExtraTypes].accounts.findIndex(
        (account: IAdAccount) => account.id === action.data.id
      )

      return {
        ...state,
        [action.network]: {
          accounts: [
            ...state[action.network as AdAccountExtraTypes].accounts.slice(0, index),
            action.data,
            ...state[action.network as AdAccountExtraTypes].accounts.slice(index + 1),
          ],
          error: null,
          isLoading: false,
        },
      }
    }
    case AdAccountsActionTypes.UPDATE_AD_ACCOUNT_ONBOARDING: {
      const index = state[action.network as AdAccountExtraTypes].accounts.findIndex(
        (account: IAdAccount) => account.id === action.data.accountId
      )

      return {
        ...state,
        [action.network]: {
          accounts: [
            ...state[action.network as AdAccountExtraTypes].accounts.slice(0, index),
            {
              ...state[action.network as AdAccountExtraTypes].accounts[index],
              account_hints: [
                ...state[action.network as AdAccountExtraTypes].accounts[index].account_hints,
                action.data.setupHint,
              ],
            },
            ...state[action.network as AdAccountExtraTypes].accounts.slice(index + 1),
          ],
          error: null,
          isLoading: false,
        },
      }
    }
    case AdAccountsActionTypes.SET_AD_ACCOUNT_AUTOPAYMENT: {
      const index = state[action.network as AdAccountExtraTypes].accounts.findIndex(
        (account: IAdAccount) => account.id === action.data.accountId
      )

      return {
        ...state,
        [action.network]: {
          accounts: [
            ...state[action.network as AdAccountExtraTypes].accounts.slice(0, index),
            {
              ...state[action.network as AdAccountExtraTypes].accounts[index],
              autopayment: action.data.autopayment,
            },
            ...state[action.network as AdAccountExtraTypes].accounts.slice(index + 1),
          ],
          error: null,
          isLoading: false,
        },
      }
    }
    case AdAccountsActionTypes.SET_PAGINATION_OBJECT: {
      return {
        ...state,
        pagination_object: action.data,
      }
    }
    case AdAccountsActionTypes.SET_ACCOUNTS_ERROR: {
      return {
        ...state,
        [action.network]: {
          ...state[action.network as AdAccountExtraTypes],
          error: action.data,
          isLoading: false,
        },
      }
    }
    case AdAccountsActionTypes.SET_ACCOUNTS_LOADING: {
      return {
        ...state,
        [action.network]: {
          ...state[action.network as AdAccountExtraTypes],
          isLoading: action.data,
        },
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as adAccountsReducer }
