import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import { NetworkExtraTypes, SpaceDelimiterComma, SpaceDelimiterDot } from '@/types'

export const API_URL = process.env.NODE_ENV === 'development' ? 'https://adhand-api-master.adhand.app/api' : '/api'

export const URL_REGEX =
  '^(http://www.|https://www.|http://|https://)?[-a-zA-Z0-9а-яА-Я]+([-.]{1}[-a-zA-Z0-9а-яА-Я]+)*[.][-a-zA-Zа-яА-Я]{2,15}(:[0-9]{1,15})?(/.*)?$'

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const START_URL_REGEX = /https?:?\/?\/?(www\.)?/

export const INSTAGRAM_REGEX = '^[a-zA-Z0-9-_@./:%]+$'

export const REGEX_BUSINESS_CENTER_ID = /^\d{19}$/

export const CurrencyReg = new RegExp('^[0-9]+([.,])?([0-9]{1,2})?$')

export const CurrencyWithoutFloatReg = new RegExp('^[0-9]+$')

export const ProfileSources: { [key: string]: ReactNode | string } = {
  recommendation: <FormattedMessage id="consts.recommendations" />,
  mass_media: <FormattedMessage id="consts.massMedia" />,
  social_networks: <FormattedMessage id="consts.networks" />,
  yandex: <FormattedMessage id="consts.yandex" />,
  google: 'Google',
  webinar: <FormattedMessage id="consts.webinars" />,
  other: <FormattedMessage id="consts.other" />,
}

export const MailProviders: any = {
  'bk.ru': 'https://e.mail.ru/',
  'gmail.com': 'https://mail.google.com',
  'icloud.com': 'https://www.icloud.com/',
  'inbox.ru': 'https://e.mail.ru/',
  'list.ru': 'https://e.mail.ru/',
  'mail.ru': 'https://e.mail.ru/',
  'outlook.com': 'https://outlook.live.com/mail/',
  'rambler.ru': 'https://mail.rambler.ru',
  'ya.ru': 'https://mail.yandex.ru/',
  'yahoo.com': 'https://mail.yahoo.com',
  'yandex.com': 'https://mail.yandex.com/',
  'yandex.kz': 'https://mail.yandex.kz/',
  'yandex.ru': 'https://mail.yandex.ru/',
}

export const NetworkNames: { [key in NetworkExtraTypes]: string } = {
  facebook: 'Facebook',
  tiktok: 'TikTok',
  telegram: 'Telegram',
}

export const NetworkExtraNames = {
  instagram: 'Instagram',
}

export const ContractPostType = 'contract_post'

export const AGENCY_ID = 1 // id of agency industry on prod

export enum SUBSCRIPTIONS {
  DISAPPROVE = 'disapprove',
  FINANCE = 'finance',
}

export enum GLOBAL_COUNTRIES {
  RU = 'RU',
  GB = 'GB',
  AZ = 'AZ',
}

export const SPACE_COMMA: SpaceDelimiterComma = [' ', ',']
export const SPACE_DOT: SpaceDelimiterDot = [' ', '.']

export const Delimiters = {
  SPACE_COMMA,
  SPACE_DOT,
}
