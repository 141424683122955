import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { customAxios, getFullStringDateFromDateString } from '../../utils'
import { ThunkResult } from '../store'
import { ITransactionAccount, ITransactionReason, ITransactionsState, TransactionsActionTypes } from './types'

import { API_URL } from '@/const'
import { IDatepickerObject } from '@/pages/History/components/Datepicker/types'
import { IFilterObject } from '@/pages/History/types'

export const NetworkTypes: { [key: string]: string } = {
  facebook: 'fb',
  tiktok: 'tiktok',
  telegram: 'telegram',
}

export const NetworkQueries: { [key: string]: string } = {
  facebook: 'facebook_account',
  tiktok: 'tiktok_account',
  telegram: 'telegram_account',
}

export function getTransactions(
  page?: number,
  datepickerObject?: IDatepickerObject,
  filterObject?: IFilterObject
): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    let url = ''

    if ((!datepickerObject && !filterObject) || (datepickerObject && datepickerObject.value === 'all_time')) {
      url = page ? `${API_URL}/transactions/?page=${page}` : `${API_URL}/transactions/`
    }

    if (datepickerObject && datepickerObject.value !== 'all_time') {
      if (['this_month', 'last_month'].includes(datepickerObject.value)) {
        url = page
          ? `${API_URL}/transactions/?page=${page}&created_at_period=${datepickerObject.value}`
          : `${API_URL}/transactions/?created_at_period=${datepickerObject.value}`
      }

      if (datepickerObject.value === 'period') {
        if (datepickerObject.startDate) {
          url = page
            ? `${API_URL}/transactions/?page=${page}&created_at_range_after=${getFullStringDateFromDateString(
                datepickerObject.startDate.toString(),
                false
              )}`
            : `${API_URL}/transactions/?created_at_range_after=${getFullStringDateFromDateString(
                datepickerObject.startDate.toString(),
                false
              )}`

          if (!datepickerObject.endDate) {
            url = `${url}&created_at_range_before=${getFullStringDateFromDateString(
              datepickerObject.startDate.toString(),
              false
            )}`
          }
        }

        if (datepickerObject.endDate) {
          url = `${url}&created_at_range_before=${getFullStringDateFromDateString(
            datepickerObject.endDate.toString(),
            false
          )}`
        }
      }
    }

    if (filterObject) {
      const reasons = filterObject.reasons
        .map((reason, index) => {
          return index === 0 && !page && datepickerObject && datepickerObject.value === 'all_time'
            ? `?transaction_reason=${reason}`
            : `&transaction_reason=${reason}`
        })
        .join('')

      if (reasons) {
        url = url + reasons
      }

      const accountIDs = filterObject.accountsIDs
        .map((accountID, index) => {
          return index === 0 && !page && datepickerObject && datepickerObject.value === 'all_time' && !reasons
            ? `?${NetworkQueries[accountID.split(',')[0]]}=${accountID.split(',')[1]}`
            : `&${NetworkQueries[accountID.split(',')[0]]}=${accountID.split(',')[1]}`
        })
        .join('')

      if (accountIDs) {
        url = url + accountIDs
      }

      const networks = filterObject.networks
        .map((network, index) => {
          return index === 0 &&
            !page &&
            datepickerObject &&
            datepickerObject.value === 'all_time' &&
            !accountIDs &&
            !reasons
            ? `?ad_platform=${NetworkTypes[network]}`
            : `&ad_platform=${NetworkTypes[network]}`
        })
        .join('')

      if (networks) {
        url = url + networks
      }
    }

    return customAxios(url, 'GET', false)
      .then((response: AxiosResponse<ITransactionsState>) => {
        if (response.status === 200) {
          dispatch({ type: TransactionsActionTypes.SET_TRANSACTIONS, data: response.data })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getTransactionReasons(): AxiosPromise<any> {
  return customAxios(`${API_URL}/transactions/reasons/`, 'GET', null, true)
    .then((response: AxiosResponse<ITransactionReason[]>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getTransactionAccounts(): AxiosPromise<any> {
  return customAxios(`${API_URL}/transactions/accounts/`, 'GET', null, true)
    .then((response: AxiosResponse<ITransactionAccount[]>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getTransactionXLSX(
  datepickerObject: IDatepickerObject,
  filterObject: IFilterObject
): AxiosPromise<any> {
  let url = ''

  if (datepickerObject.value === 'all_time') {
    url = `${API_URL}/transactions/xlsx/`
  }

  if (datepickerObject.value !== 'all_time') {
    if (['this_month', 'last_month'].includes(datepickerObject.value)) {
      url = `${API_URL}/transactions/xlsx/?created_at_period=${datepickerObject.value}`
    }

    if (datepickerObject.value === 'period') {
      if (datepickerObject.startDate) {
        url = `${API_URL}/transactions/xlsx/?created_at_range_after=${getFullStringDateFromDateString(
          datepickerObject.startDate.toString(),
          false
        )}`

        if (!datepickerObject.endDate) {
          url = `${url}&created_at_range_before=${getFullStringDateFromDateString(
            datepickerObject.startDate.toString(),
            false
          )}`
        }
      }

      if (datepickerObject.endDate) {
        url = `${url}&created_at_range_before=${getFullStringDateFromDateString(
          datepickerObject.endDate.toString(),
          false
        )}`
      }
    }
  }

  const reasons = filterObject.reasons
    .map((reason, index) => {
      return index === 0 && datepickerObject && datepickerObject.value === 'all_time'
        ? `?transaction_reason=${reason}`
        : `&transaction_reason=${reason}`
    })
    .join('')

  if (reasons) {
    url = url + reasons
  }

  const accountIDs = filterObject.accountsIDs
    .map((accountID, index) => {
      return index === 0 && datepickerObject.value === 'all_time' && !reasons
        ? `?${NetworkQueries[accountID.split(',')[0]]}=${accountID.split(',')[1]}`
        : `&${NetworkQueries[accountID.split(',')[0]]}=${accountID.split(',')[1]}`
    })
    .join('')

  if (accountIDs) {
    url = url + accountIDs
  }

  const networks = filterObject.networks
    .map((network, index) => {
      return index === 0 && datepickerObject.value === 'all_time' && !accountIDs && !reasons
        ? `?ad_platform=${NetworkTypes[network]}`
        : `&ad_platform=${NetworkTypes[network]}`
    })
    .join('')

  if (networks) {
    url = url + networks
  }

  return customAxios(
    url,
    'GET',
    null,
    true,
    {
      'Content-type': 'application/vnd.ms-excel',
    },
    'arraybuffer'
  )
    .then((response: AxiosResponse<any>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}
