import { ReactNode } from 'react'

export interface IAlertInlineSMBProps {
  isOpen?: boolean
  title?: string
  description?: string | ReactNode
  content?: ReactNode
  className?: string
  hasCloseButton?: boolean
  isCloseCallback?: () => void
  variant?: ALERT_VARIANT
  isFullWidth?: boolean
  hasBorder?: boolean
}

export enum ALERT_VARIANT {
  neutral = 'neutral',
  neutralColor = 'neutralColor',
  success = 'success',
  successColor = 'successColor',
  warning = 'warning',
  warningColor = 'warningColor',
  negative = 'negative',
  negativeColor = 'negativeColor',
  grey = 'grey',
}
