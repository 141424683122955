import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { AppActionTypes } from '../app/types'
import { MyThunkDispatch, ThunkResult } from '../store'
import {
  IAuthorizeUserData,
  IChangePasswordData,
  ICountry,
  ICreateProfileData,
  IIntercomProfile,
  ISigninResponse,
  ISignupResponse,
  ISignupUserData,
  ITokenRefreshResponse,
  IUpdateProfileData,
  IUpdateUserData,
  IUserAvailablePlugin,
  IUserInfoResponse,
  IUserPlugin,
  IUserProfile,
  Language,
  PluginTypes,
  UserActionTypes,
} from './types'

import { IComboBoxOption } from '@/components/ComboBox'
import { API_URL } from '@/const'
import { customAxios, jwt } from '@/utils'

export function setAuthorized(authorized: boolean): AnyAction {
  return { type: UserActionTypes.SET_AUTHORIZED, data: authorized }
}

export function setHasProfile(hasProfile: boolean): AnyAction {
  return { type: UserActionTypes.SET_HAS_PROFILE, data: hasProfile }
}

export function setMailProvider(mailProvider: string): AnyAction {
  return { type: UserActionTypes.SET_MAIL_PROVIDER, data: mailProvider }
}

export function logoutUser() {
  return function logoutDispatch(dispatch: MyThunkDispatch) {
    jwt.clear()

    dispatch({ type: UserActionTypes.SET_EMAIL, data: '' })
    dispatch({ type: UserActionTypes.CLEAR_PROFILE, data: null })
    dispatch({ type: UserActionTypes.SET_HAS_PROFILE, data: false })
    dispatch({ type: UserActionTypes.SET_ATOKEN, data: null })
    dispatch({ type: UserActionTypes.SET_RTOKEN, data: null })
    dispatch({ type: UserActionTypes.SET_AUTHORIZED, data: false })
  }
}

export function refreshToken(refresh: string | null): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch) => {
    return customAxios(`${API_URL}/token-refresh/`, 'POST', { refresh }, false)
      .then((response: AxiosResponse<ITokenRefreshResponse>): Promise<any> | AxiosResponse<ITokenRefreshResponse> => {
        if (response.status === 200) {
          jwt.set(response.data.access, response.data.refresh)
          dispatch(setAuthorized(true))

          dispatch({ type: UserActionTypes.SET_ATOKEN, data: response.data.access })
          dispatch({ type: UserActionTypes.SET_RTOKEN, data: response.data.refresh })
        } else {
          jwt.clear()
          dispatch(setAuthorized(false))

          dispatch({ type: UserActionTypes.SET_ATOKEN, data: null })
          dispatch({ type: UserActionTypes.SET_RTOKEN, data: null })

          return Promise.reject(response)
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getUserInfo(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: MyThunkDispatch): AxiosPromise<any> => {
    return customAxios(`${API_URL}/me/`, 'GET', null, true)
      .then((response: AxiosResponse<IUserInfoResponse>) => {
        if (response.status === 200) {
          dispatch({ type: UserActionTypes.SET_USER, data: response.data })
          if (!response.data.has_profile) {
            dispatch(setAuthorized(true))
          }
        } else {
          jwt.clear()

          dispatch({ type: UserActionTypes.SET_ATOKEN, data: null })
          dispatch({ type: UserActionTypes.SET_RTOKEN, data: null })
          dispatch({ type: UserActionTypes.SET_SUBSCRIBE, data: false })
        }
        return response
      })
      .catch((error: AxiosError) => {
        jwt.clear()

        dispatch({ type: UserActionTypes.SET_ATOKEN, data: null })
        dispatch({ type: UserActionTypes.SET_RTOKEN, data: null })

        return Promise.reject(error)
      })
  }
}

export function updateUserData(data: IUpdateUserData): ThunkResult<AxiosPromise<any>> {
  return (dispatch: MyThunkDispatch): AxiosPromise<any> => {
    return customAxios(`${API_URL}/me/`, 'PATCH', data, true)
      .then((response: AxiosResponse<IUpdateUserData>) => {
        if (response.status === 200) {
          dispatch({ type: UserActionTypes.SET_USER, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function registerUser(data: ISignupUserData): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/signup/`, 'POST', data, false).then((response: AxiosResponse<ISignupResponse>) => {
      if (response.status === 201) {
        dispatch({ type: UserActionTypes.SET_GA_USER_ID, data: response.data.ga_user_id })
      }
      return response
    })
  }
}

export function addUser(body: ISignupUserData): AxiosPromise<IUserInfoResponse> {
  return customAxios(`${API_URL}/signup/`, 'POST', body, false)
    .then((response: AxiosResponse<ISignupResponse>): any => {
      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function authorizeUser(data: IAuthorizeUserData): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/login/`, 'POST', data, false)
      .then((response: AxiosResponse<ISigninResponse>) => {
        if (response.status === 200) {
          jwt.set(response.data.access, response.data.refresh)

          dispatch({ type: UserActionTypes.SET_ATOKEN, data: response.data.access })
          dispatch({ type: UserActionTypes.SET_RTOKEN, data: response.data.refresh })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createProfile(data: ICreateProfileData): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile/`, 'POST', data, true)
      .then((response: AxiosResponse<IUserProfile>) => {
        if (response.status === 201) {
          dispatch({ type: UserActionTypes.SET_PROFILE, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function addProfile(data: ICreateProfileData): AxiosPromise<any> {
  return customAxios(`${API_URL}/profile/`, 'POST', data, true)
    .then((response: AxiosResponse<IUserProfile>) => {
      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function updateProfile(data: IUpdateProfileData): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile/`, 'PATCH', data, true)
      .then((response: AxiosResponse<IUserProfile>) => {
        if (response.status === 200) {
          dispatch({ type: UserActionTypes.SET_PROFILE, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getProfile(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>, getState): AxiosPromise<any> => {
    return customAxios(`${API_URL}/profile/`, 'GET', null, true)
      .then((response: AxiosResponse<IUserProfile>) => {
        if (response.status === 200) {
          const state = getState()
          dispatch({ type: UserActionTypes.SET_PROFILE, data: response.data })
          dispatch(setAuthorized(true))
          // @ts-ignore
          dispatch(getCountries(state.user.language))
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function changePassword(data: IChangePasswordData): ThunkResult<AxiosPromise<any>> {
  return (): AxiosPromise<any> => {
    return customAxios(`${API_URL}/change-password/`, 'PUT', data, true)
      .then((response: AxiosResponse<IChangePasswordData>) => response)
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getAllowCreateAccounts(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    return customAxios(`${API_URL}/profile/allow-create-adaccount/`, 'GET', null, true)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch({ type: UserActionTypes.SET_ALLOW_CREATE_ACCOUNTS, data: response.data })
          return response.data
        }
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getResetPassword(email: string): AxiosPromise<AxiosResponse<any>> {
  return customAxios(
    `${API_URL}/password_reset/`,
    'POST',
    {
      email,
    },
    false
  )
    .then((response: AxiosResponse<any>) => response)
    .catch((error: AxiosError) => Promise.reject(error))
}

export function setResetedPassword(password: string, token: string): AxiosPromise<AxiosResponse<any>> {
  return customAxios(
    `${API_URL}/password_reset/confirm/`,
    'POST',
    {
      password,
      token,
    },
    false
  )
    .then((response: AxiosResponse<any>) => response)
    .catch((error: AxiosError) => Promise.reject(error))
}

export function setUserTokens(access: string, refresh: string) {
  return (dispatch: Dispatch<AnyAction>): any => {
    jwt.set(access, refresh)

    dispatch({ type: UserActionTypes.SET_ATOKEN, data: access })
    dispatch({ type: UserActionTypes.SET_RTOKEN, data: refresh })
  }
}

export function getUserPlugins(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch): AxiosPromise<any> => {
    return customAxios(`${API_URL}/profile/plugins/`, 'GET', null, true)
      .then((response: AxiosResponse<IUserPlugin[]>) => {
        if (response.status === 200) {
          const data = response.data.reduce((acc: { [key in PluginTypes]?: boolean }, plugin: IUserPlugin) => {
            return {
              ...acc,
              [plugin.plugin]: true,
            }
          }, {})
          dispatch({ type: UserActionTypes.SET_PROFILE, data: { ...data, connected_plugins: response.data } })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setUserPlugin(plugin: PluginTypes): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile/plugins/`, 'POST', { plugin }, true)
      .then((response: AxiosResponse<IUserPlugin>) => {
        if (response.status === 201) {
          dispatch({ type: UserActionTypes.SET_PROFILE, data: { [response.data.plugin]: true } })
          dispatch({ type: UserActionTypes.REMOVE_AVAILABLE_PLUGIN, data: response.data.plugin })
          dispatch({ type: UserActionTypes.SET_CONNECTED_PLUGIN, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function removeUserPlugin(plugin: IUserPlugin): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile/plugins/${plugin.id}`, 'DELETE', null, true)
      .then((response: AxiosResponse<IUserPlugin>) => {
        if (response.status === 204) {
          dispatch({ type: UserActionTypes.SET_PROFILE, data: { [plugin.plugin]: false } })
          dispatch({ type: UserActionTypes.REMOVE_CONNECTED_PLUGIN, data: plugin.id })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getUserAvailablePlugins(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile/not_connected_plugins/`, 'GET', null, true)
      .then((response: AxiosResponse<IUserAvailablePlugin[]>) => {
        if (response.status === 200) {
          dispatch({ type: UserActionTypes.SET_AVAILABLE_PLUGINS, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getTikTokPartner(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch): AxiosPromise<any> => {
    return customAxios(`${API_URL}/tiktok/partner/`, 'GET', null, true)
      .then((response: AxiosResponse<Array<{ partner_id: number }>>) => {
        if (response.status === 200) {
          const data = Boolean(response.data.length)
          dispatch({ type: UserActionTypes.SET_TIKTOK_MANAGER, data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setTikTokPartner(partnerId: string): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/tiktok/partner/`, 'POST', { partner_id: partnerId }, true)
      .then((response: AxiosResponse<IUserPlugin>) => {
        if (response.status === 201) {
          dispatch({ type: UserActionTypes.SET_TIKTOK_MANAGER, data: true })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getCountries(language: Language): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: AppActionTypes.SET_LOADING_COUNTRIES })
    const lang = language || Language.English

    return customAxios(`${API_URL}/me/countries/?lang=${lang}`, 'GET', null, false)
      .then((response: AxiosResponse<ICountry[]>): any => {
        if (response.status === 200) {
          const countriesResponse = response.data
            .map((country: ICountry) => ({
              label: country.display_name,
              value: country.value,
            }))
            .sort((a: IComboBoxOption, b: IComboBoxOption) =>
              new Intl.Collator(language).compare(a.label?.toString() || '', b.label?.toString() || '')
            )
          dispatch({ type: AppActionTypes.SET_COUNTRIES, result: countriesResponse })
        }
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getIntercomProfile(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile/intercom/`, 'GET', null, true)
      .then((response: AxiosResponse<IIntercomProfile>) => {
        if (response.status === 200) {
          dispatch({ type: UserActionTypes.SET_INTERCOM_PROFILE, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setUserLanguage(language: Language): AnyAction {
  return { type: UserActionTypes.SET_USER_LANGUAGE, data: language }
}
