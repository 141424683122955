import { Currency } from '@/store/user/types'
import { Platforms } from '@/types'

export enum MyCashbackActionTypes {
  SET_SPENDS = 'SET_SPENDS',
  SET_PROGRESS = 'SET_PROGRESS',
  SET_HISTORY = 'SET_HISTORY',
  SET_PLATFORMS = 'SET_PLATFORMS',
}

export interface IMyCashbackSpends {
  current_cashback: {
    telegram: number
    facebook: number
    total: number
  }
  total_cashbacks: {
    facebook: number
    telegram: number
  }
}

export interface IMyCashbackProgress {
  conditions: IMyCashbackCondition[]
  current_spend: string
  current_cashback: string
  current_percent: string
  current_condition_id: number | null
  to_new_condition: string | null
}

export interface IMyCashbackCondition {
  id: number
  start_range: number
  end_range: number | null
  value: number
}

export interface IMyCashbackHistory {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  results: IMyCashbackTransaction[]
}

export interface IMyCashbackTransaction {
  advertising_period: string
  payout_period: string
  total_cashback: string
  total_percent: number | null
  total_spends: number | null
  currency: Currency
}

export interface IMyCashbackPlatforms {
  cashback_condition_fb: boolean
  cashback_condition_tg: boolean
}

export type MyCashbackPlatformTypes = 'cashback_condition_fb' | 'cashback_condition_tg'

export interface IMyCashbackState {
  spends: IMyCashbackSpends
  progress: IMyCashbackProgress
  history: IMyCashbackHistory
  platforms: IMyCashbackPlatforms
}

export type MyCashbackProgressType = Platforms.FACEBOOK | Platforms.TELEGRAM
