import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import { ALERT_VARIANT, AlertInline } from '../../../../../components/AlertInlineSMB'
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '../../../../../components/ButtonSMB'
import useStyles from './styles'

import { closeAppModal } from '@/store/app'
import { MODALS } from '@/store/app/types'
import { setUserPlugin, updateProfile } from '@/store/user'
import { ProfileType, UserProfileStatuses } from '@/store/user/types'

interface IIndividualInfoProps {
  onBack: () => void
}

const IndividualInfo = (props: IIndividualInfoProps) => {
  const { onBack } = props

  const { show } = useIntercom()
  const intl = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()

  const openChatHandler = useCallback(() => {
    if (show) {
      show()
    }
  }, [show])

  const backHandler = useCallback(() => {
    onBack()
  }, [onBack])

  const skipDetailsHandler = useCallback(async () => {
    await dispatch(updateProfile({ profile_type: ProfileType.INDIVIDUAL, legal_status: UserProfileStatuses.APPROVED }))
    await dispatch(setUserPlugin('country_is_ready'))
    dispatch(closeAppModal({ name: MODALS.BUSINESS_PROFILE }))
  }, [])

  return (
    <div className={classes.root}>
      <AlertInline
        variant={ALERT_VARIANT.warningColor}
        title={intl.formatMessage({ id: 'detailsPayment.modal.alertTitle' })}
        description={intl.formatMessage(
          { id: 'detailsPayment.modal.alertText' },
          {
            chat: (
              <button onClick={openChatHandler} className={classes.chatButton}>
                <FormattedMessage id="detailsPayment.modal.alertTextChat" />
              </button>
            ),
          }
        )}
        isFullWidth={true}
        className={classes.alert}
      />
      <Button
        label={intl.formatMessage({ id: 'detailsPayment.modal.backToForm' })}
        size={BUTTON_SIZE.size40}
        className={classes.button}
        fullWidth={true}
        onClick={backHandler}
      />
      <Button
        label={intl.formatMessage({ id: 'detailsPayment.modal.skipAnyway' })}
        size={BUTTON_SIZE.size40}
        fullWidth={true}
        className={classes.button}
        variant={BUTTON_VARIANT.secondaryPurple}
        onClick={skipDetailsHandler}
      />
    </div>
  )
}

export default IndividualInfo
