import { Reducer } from 'redux'

import { IOnboardingState, OnboardingActionTypes } from './types'

export const initialState: IOnboardingState = {
  facebook: [],
  tiktok: [],
  telegram: [],
}

const reducer: Reducer<IOnboardingState> = (state = initialState, action: any) => {
  switch (action.type) {
    case OnboardingActionTypes.SET_ACCOUNTS_ONBOARDING: {
      return {
        ...action.data,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as onboardingReducer }
