import styled from 'styled-components'

import Button from '../../../../components/Button'
import { BackgroundGrey, GreyBase } from '../../../../const/colors'
import { baseFontFamily, baseTransDur, smallestFont } from '../../../../const/commonStyles'

export const PopupButton = styled(Button)`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font: 400 ${smallestFont} ${baseFontFamily};
  letter-spacing: 0.2px;
  color: ${GreyBase};

  background-color: transparent;
  padding: 8px 14px;
  border: 0;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${GreyBase};
    transform: none;
  }
`

export const PopupDivider = styled.div`
  border-bottom: 1px solid #dedede;
  width: 100%;
`

export const PopupButtonWrapper = styled.div`
  &:hover {
    background-color: ${BackgroundGrey};
    transition-duration: ${baseTransDur};
  }
`

export const PopupEmail = styled.div`
  font: 400 ${smallestFont} ${baseFontFamily};
  color: ${GreyBase};
  text-align: center;
  letter-spacing: 0.2px;
  margin-bottom: 18px;
`

export const PopupMenuWrapper = styled.div`
  z-index: 100;
  position: absolute;
  min-width: 195px;

  top: calc(100% + 5px);
  right: 0;

  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 16px 0 14px;
`

export const PopupIcon = styled.div`
  width: 53px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;

  & svg {
    width: 40px;
    height: 40px;

    & path {
      fill: #a6a6a6;
    }
  }
`

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 14px;
  margin-bottom: 8px;
`

export const PopupFooter = styled.div`
  padding: 0 14px;
  margin-top: 8px;
`

export const PopupFooterButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font: 500 ${smallestFont} ${baseFontFamily};
  letter-spacing: 0.2px;
  color: ${GreyBase};
  height: 32px;
  background-color: #f3f3f3;
  padding: 0;
  border-color: #f3f3f3;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${GreyBase};
    background-color: #f3f3f3;
    border-color: #f3f3f3;
  }
`
