import { IFacebookProfile } from '@/components/FacebookConnect/types'
import { IAdAccount } from '@/store/adaccounts/types'

export enum FacebookPartnerActionTypes {
  SET_STEP = 'SET_STEP',
  SET_FACEBOOK_PROFILE = 'SET_FACEBOOK_PROFILE',
  SET_BUSINESS_MANAGERS = 'SET_BUSINESS_MANAGERS',
  SET_BUSINESS_MANAGER_ERROR = 'SET_BUSINESS_MANAGER_ERROR',
  SELECT_BUSINESS_MANAGER = 'SELECT_BUSINESS_MANAGER',
  SET_BUSINESS_PAGES = 'SET_BUSINESS_PAGES',
  SET_BUSINESS_PAGES_LOADING = 'SET_BUSINESS_PAGES_LOADING',
  SET_APPLICATIONS = 'SET_APPLICATIONS',
  SET_BUSINESS_INFO = 'SET_BUSINESS_INFO',
  SET_BUSINESS_MANAGER_LOADING = 'SET_BUSINESS_MANAGER_LOADING',
  SET_APPLICATIONS_SENT = 'SET_APPLICATIONS_SENT',
  SET_APPLICATIONS_LOADING = 'SET_APPLICATIONS_LOADING',
  SET_APPLICATIONS_ERRORS = 'SET_APPLICATIONS_ERRORS',
  CLOSE_APPLICATION = 'CLOSE_APPLICATION',
}

export interface IBusinessManager {
  id: string
  name: string
}

export interface IBusinessInfo {
  business_type: BusinessTypes | null
  count: number | null
  selected_business_type: BusinessTypes | null
}

export interface IBusinessPage {
  id: string
  name: string
}

export interface IBusinessManagerState {
  list: IBusinessManager[]
  errorMessage: string
  selectedBusinessManager: IBusinessManager | null
  info: IBusinessInfo
  isLoading: boolean
}

export interface IBusinessPageState {
  list: IBusinessPage[]
  isLoading: boolean
}

export interface IFacebookApplication {
  account_name: string
  website_url: string
  facebook_business_page_id: string
  facebook_business_page_name: string
  timezone: string
  facebook_app_id?: string
  business_manager_id: string
  business_manager_type: BusinessTypes
}

export interface IRequestBody {
  applications: IFacebookApplication[]
}

export interface IFacebookPartnerState {
  step: number
  profile: IFacebookProfile
  businessManager: IBusinessManagerState
  businessPage: IBusinessPageState
  applications: IAdAccount[]
  isSentForm: boolean
  isFormLoading: boolean
  errors: any
}

export enum BusinessTypes {
  GAMES_AND_APPLICATIONS = 'GAMES_AND_APPLICATIONS',
  OTHER = 'OTHER',
}

export enum ApplicationRequestStatuses {
  ON_REVIEW = 'ON_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PAGE_ERROR = 'PAGE_ERROR',
}

export enum FacebookPartnerStatuses {
  USER_NOT_ASSIGNED = 'USER_NOT_ASSIGNED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}
