import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { IModalProps } from '../AppModals'
import RedirectBG from './media/redirectEvidoBackground.png'
import useStyles from './styles'

import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { Modal } from '@/components/ModalSMB'

const RedirectEvidoModal = (props: IModalProps) => {
  const { onClose } = props
  const intl = useIntl()

  const classes = useStyles()

  const cancelHandler = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal
      isOpen={true}
      bodyContent={
        <div>
          <div className={classes.header}>
            <img src={RedirectBG} alt="" className={classes.bgImage} />
          </div>
          <h4 className={classes.title}>
            <FormattedMessage id="redirect.modal.title" />
          </h4>
          <div className={classes.description}>
            <FormattedMessage id="redirect.modal.description" />
          </div>
        </div>
      }
      footerContent={
        <Button
          label={intl.formatMessage({ id: 'redirect.modal.successBtn' })}
          variant={BUTTON_VARIANT.primary}
          size={BUTTON_SIZE.size40}
          onClick={cancelHandler}
        />
      }
      maxWidth={'460px'}
      hasCloseButton={false}
    />
  )
}

export default RedirectEvidoModal
