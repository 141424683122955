import { Language } from '@/store/user/types'

export const links = {
  [Language.Russian]: {
    evidoPolicyPdf: 'http://files.adhand.app/privacy_policy.pdf',
    evidoTermsPdf: 'http://files.adhand.app/term_of_use.pdf',
    evidoReferralProgramPdf: 'https://files.adhand.app/referral_programm_adhand.pdf',
    evidoTermsPdfAz: 'https://files.adhand.app/term_of_use_az.pdf',
    evidoPresentationPdf: 'https://files.adhand.app/financial_terms.pdf',
    evidoFindLinkBusinessCenterTikTok: 'https://adhand.notion.site/ID-TikTok-b7f5ee0c92284564a7a496349613f672',
    evidoCreateBusinessCenterTikTok: 'https://adhand.notion.site/TikTok-692098fa677749d98ae4b94d211e3446',
    evidoCreateBusinessCenterFacebook: 'https://adhand.notion.site/Business-Manager-23dbe8ae05aa40d79d007bdf36851678',
    evidoFindLinkBusinessCenterFacebook: 'https://adhand.notion.site/Business-Manager-8b4a03150d484552b8935bbcd95b30f4',
    facebookAccountQuality: 'https://business.facebook.com/accountquality',
    facebookSettingsApplications: 'https://www.facebook.com/settings?tab=applications',
    facebookSettingsSecurity: 'https://www.facebook.com/settings?tab=security',
    facebookSettings: 'https://business.facebook.com/settings',
    facebookAccessRecoverySupport: 'https://www.facebook.com/help/contact/logout?id=',
    facebookSettingsBussinessAccount: 'https://business.facebook.com/settings/ad-accounts/?business_id=',
    facebookSupportBussinessManager: 'https://business.facebook.com/settings/info?business_id=',
    facebookSupport: 'https://www.facebook.com/business/help/support',
    trackerRequest:
      'https://docs.google.com/forms/d/e/1FAIpQLSeE19CLURaThnL3d-CcWK6sHYzyeitRoE2H0yyMYHHOH6Fb3g/viewform',
    analyticsIdea:
      'https://docs.google.com/forms/d/e/1FAIpQLSfed867NF85YkOUFejeCbUvtQvaYTpiDgrcc-EdwsKEyIo-mg/viewform',
    telegramBot: 'https://t.me/adhand_bot',
    facebookHowToAddAccountUser: 'https://adhand.notion.site/Business-Manager-79ce840831da41409740a0339a5be17e',
    facebookHowToGetAccountAccess: 'https://adhand.notion.site/Facebook-Page-917380d115f34b87aecbd6417eeebff9',
    telegramVariations: 'https://adhand.notion.site/Telegram-Ads-4b5234ca25a54671a11bb3f791430bde',
    telegramStart: 'https://adhand.notion.site/Telegram-Ads-AdHand-1f03f4e78c1b4c28a5e4a97f0320bd95',
    balanceTaxAz: 'https://www.e-taxes.gov.az/dispatcher?menu=edvdep&submenu=2&nav=edvdep',
  },
  [Language.English]: {
    evidoPolicyPdf: 'http://files.adhand.app/privacy_policy.pdf',
    evidoTermsPdf: 'http://files.adhand.app/term_of_use.pdf',
    evidoTermsPdfAz: 'https://files.adhand.app/term_of_use_az.pdf',
    evidoReferralProgramPdf: 'https://files.adhand.app/referral_programm_adhand_eng.pdf',
    evidoPresentationPdf: 'https://files.adhand.app/financial_terms.pdf',
    evidoFindLinkBusinessCenterTikTok:
      'https://adhand.notion.site/How-to-find-an-ID-in-a-TikTok-Business-Center-e2157b06fef041d9800305af67ea0ba6',
    evidoCreateBusinessCenterTikTok:
      'https://adhand.notion.site/How-to-create-a-Business-Center-in-TikTok-8e3a1be16c7944038910f312745a08e0',
    evidoCreateBusinessCenterFacebook:
      'https://adhand.notion.site/How-to-create-a-Business-Manager-17e53ecd75df4b2a8de90eed82cc50cd',
    evidoFindLinkBusinessCenterFacebook:
      'https://adhand.notion.site/Where-to-find-an-ID-or-a-link-to-a-Business-Manager-5ab8041f03a2444796daf1c5c405937b',
    facebookAccountQuality: 'https://business.facebook.com/accountquality',
    facebookSettingsApplications: 'https://www.facebook.com/settings?tab=applications',
    facebookSettingsSecurity: 'https://www.facebook.com/settings?tab=security',
    facebookSettings: 'https://business.facebook.com/settings',
    facebookAccessRecoverySupport: 'https://www.facebook.com/help/contact/logout?id=',
    facebookSettingsBussinessAccount: 'https://business.facebook.com/settings/ad-accounts/?business_id=',
    facebookSupportBussinessManager: 'https://business.facebook.com/settings/info?business_id=',
    facebookSupport: 'https://www.facebook.com/business/help/support',
    trackerRequest: 'https://forms.gle/fEbuCorfjaRoHS8u9',
    analyticsIdea: 'https://forms.gle/WVT9viEGx1cvnnwL8',
    telegramBot: 'https://t.me/adhand_bot',
    facebookHowToAddAccountUser:
      'https://adhand.notion.site/Getting-access-to-your-account-adding-managers-and-linking-pages-in-Business-Manager-f974320c48084b75b560c66491da48f9',
    facebookHowToGetAccountAccess:
      'https://adhand.notion.site/How-to-share-access-to-Facebook-Page-4b60d91074c34a698261a8fedc97c5b9',
    telegramVariations: 'https://adhand.notion.site/Telegram-ads-cabinet-options-9179d3b249a14604b1d0f688646b0176',
    telegramStart: 'https://adhand.notion.site/Telegram-Ads-AdHand-1f03f4e78c1b4c28a5e4a97f0320bd95',
    balanceTaxAz: 'https://www.e-taxes.gov.az/dispatcher?menu=edvdep&submenu=2&nav=edvdep',
  },
}
