import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { IBankDetailsProps } from '../../types'
import useStyles from './styles'

import { FieldHelpText } from '@/components/FieldHelpText'
import { FieldLabel } from '@/components/FieldLabel'
import { Asterisk } from '@/components/Icons'
import { Input, INPUT_SIZE } from '@/components/InputSMB'
import { PurpleBase } from '@/const/colors'

const BankDetails = (props: IBankDetailsProps) => {
  const { bankDetailsForm, blurHandler, changeHandler } = props
  const { bank, bank_address, account_number_usd, sort_code, swift } = bankDetailsForm

  const intl = useIntl()
  const classes = useStyles()

  return (
    <form className={classes.root}>
      <div className={classes.formGroup}>
        <div>
          <FieldLabel className={classes.label}>
            <FormattedMessage id="detailsPayment.modal.formBankLabel" />
            <span className={classes.requiredIcon}>
              <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
            </span>
          </FieldLabel>
          <Input
            name="bank"
            id="bank"
            placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formBankPlaceholder' })}
            size={INPUT_SIZE.size48}
            fullWidth={true}
            onChange={changeHandler}
            onBlur={blurHandler}
            value={bank.value}
            error={bank.hasError}
          />
        </div>
        <div>
          <FieldLabel className={classes.label}>
            <FormattedMessage id="detailsPayment.modal.formLegalAddressBankLabel" />
          </FieldLabel>
          <Input
            name="bank_address"
            id="bank_address"
            placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formLegalAddressBankPlaceholder' })}
            size={INPUT_SIZE.size48}
            fullWidth={true}
            onChange={changeHandler}
            onBlur={blurHandler}
            value={bank_address.value}
            error={bank_address.hasError}
          />
        </div>
      </div>
      <div>
        <FieldLabel className={classes.label}>
          <FormattedMessage id="detailsPayment.modal.formAccountNumberLabel" />
          <span className={classes.requiredIcon}>
            <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
          </span>
        </FieldLabel>
        <Input
          name="account_number_usd"
          id="account_number_usd"
          placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formAccountNumberPlaceholder' })}
          size={INPUT_SIZE.size48}
          fullWidth={true}
          onChange={changeHandler}
          onBlur={blurHandler}
          value={account_number_usd.value}
          error={account_number_usd.hasError}
        />
      </div>
      <div className={classes.formGroup}>
        <div>
          <FieldLabel className={classes.label}>
            <FormattedMessage id="detailsPayment.modal.formSortCodeLabel" />
          </FieldLabel>
          <Input
            name="sort_code"
            id="sort_code"
            placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formSortCodePlaceholder' })}
            size={INPUT_SIZE.size48}
            fullWidth={true}
            onChange={changeHandler}
            onBlur={blurHandler}
            value={sort_code.value}
            error={sort_code.hasError}
          />
          <FieldHelpText className={classes.helperText}>
            <FormattedMessage id="detailsPayment.modal.formSortCodeHelper" />
          </FieldHelpText>
        </div>
        <div>
          <FieldLabel className={classes.label}>
            <FormattedMessage id="detailsPayment.modal.formSwiftLabel" />
            <span className={classes.requiredIcon}>
              <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
            </span>
          </FieldLabel>
          <Input
            name="swift"
            id="swift"
            placeholder={intl.formatMessage({ id: 'detailsPayment.modal.formSwiftPlaceholder' })}
            size={INPUT_SIZE.size48}
            fullWidth={true}
            onChange={changeHandler}
            onBlur={blurHandler}
            value={swift.value}
            error={swift.hasError}
          />
        </div>
      </div>
    </form>
  )
}

export default BankDetails
