import { createUseStyles } from 'react-jss'

import {
  BackgroundWhite,
  PurpleLighten25,
  PurpleLighten40,
  SlateGreyLighten34,
  SlateGreyLighten39,
  SlateGreyLighten50,
  TextGreyBase,
  TextSlateGreyBase,
} from '@/const/colors'
import { FontBody1, FontBody2 } from '@/const/fontStyles'

export default createUseStyles(
  {
    root: {
      position: 'relative',
      padding: (props: { isActive: boolean }) => (props.isActive ? 19 : 20),
      background: BackgroundWhite,
      borderRadius: 8,
      cursor: 'pointer',
      border: (props: { isActive: boolean }) =>
        props.isActive ? `2px solid ${PurpleLighten25}` : `1px solid ${SlateGreyLighten39}`,

      '&:hover': {
        border: (props: { isActive: boolean }) =>
          props.isActive ? `2px solid ${PurpleLighten25}` : `1px solid ${SlateGreyLighten34}`,
        backgroundColor: (props: { isActive: boolean }) => (props.isActive ? BackgroundWhite : SlateGreyLighten50),
      },
    },
    title: {
      ...FontBody1,
      color: TextGreyBase,
      fontWeight: 500,
      marginBottom: 16,
    },
    contentWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: 10,
    },
    contentItem: {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        flexShrink: 0,
        alignSelf: 'flex-start',
      },
    },
    contentText: {
      ...FontBody2,
      color: TextSlateGreyBase,
      marginLeft: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    checkedIcon: {
      display: (props: { isActive: boolean }) => (props.isActive ? 'flex' : 'none'),
      height: 20,
      width: 20,
      background: PurpleLighten40,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      position: 'absolute',
      top: 10,
      right: 10,
    },
  },
  { name: 'PaymentMethodCard' }
)
