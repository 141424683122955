import { Reducer } from 'redux'

import { IMyCashbackState, MyCashbackActionTypes } from './types'

export const initialState: IMyCashbackState = {
  history: {
    count: 0,
    current: 1,
    next: null,
    page_count: 1,
    previous: null,
    results: [],
  },
  platforms: {
    cashback_condition_fb: false,
    cashback_condition_tg: false,
  },
  progress: {
    conditions: [],
    current_cashback: '0.00',
    current_condition_id: null,
    current_percent: '0.00',
    current_spend: '0.00',
    to_new_condition: null,
  },
  spends: {
    current_cashback: {
      facebook: 0,
      telegram: 0,
      total: 0,
    },
    total_cashbacks: {
      facebook: 0,
      telegram: 0,
    },
  },
}

const reducer: Reducer<IMyCashbackState> = (state = initialState, action: any) => {
  switch (action.type) {
    case MyCashbackActionTypes.SET_SPENDS: {
      return {
        ...state,
        spends: action.data,
      }
    }
    case MyCashbackActionTypes.SET_PROGRESS: {
      return {
        ...state,
        progress: action.data,
      }
    }
    case MyCashbackActionTypes.SET_HISTORY: {
      return {
        ...state,
        history: action.data,
      }
    }
    case MyCashbackActionTypes.SET_PLATFORMS: {
      return {
        ...state,
        platforms: action.data,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as myCashbackReducer }
