import { ApplicationRequestStatuses, FacebookPartnerStatuses } from '@/store/facebook-partner'

export enum AdAccountsActionTypes {
  SET_AD_ACCOUNTS = 'SET_AD_ACCOUNTS',
  SET_AD_ACCOUNT = 'SET_AD_ACCOUNT',
  UPDATE_AD_ACCOUNT = 'UPDATE_AD_ACCOUNT',
  UPDATE_AD_ACCOUNT_ONBOARDING = 'UPDATE_AD_ACCOUNT_ONBOARDING',
  SET_AD_ACCOUNT_AUTOPAYMENT = 'SET_AD_ACCOUNT_AUTOPAYMENT',
  SET_PAGINATION_OBJECT = 'SET_PAGINATION_OBJECT',
  SET_ACCOUNTS_ERROR = 'SET_ACCOUNTS_ERROR',
  SET_ACCOUNTS_LOADING = 'SET_ACCOUNTS_LOADING',
}

export type AdAccountTypes = 'facebook' | 'tiktok'

export type AdAccountExtraTypes = AdAccountTypes | 'telegram' | 'archived_accounts'

export enum AdAccountStatus {
  CREATING = 'creating',
  READY = 'ready',
}

export enum TelegramAccountStatus {
  READY = 'yes',
  DENIED = 'no',
  NOT_SET = 'not_set',
  CORRECT = 'to_correct',
}

export enum TelegramTransactionStatus {
  DRAFT = 'draft',
  PROCESSED = 'processed',
}

export enum AccountCurrencies {
  EUR = 'EUR',
  TON = 'TON',
  USD = 'USD',
  AZN = 'AZN',
}

export interface IAdAccount {
  readonly id: number
  readonly account_balance?: number
  readonly account_type: AdAccountTypes
  readonly customer_id: string
  readonly business_name: string
  readonly adaccount_id_fb: string
  readonly name: string
  readonly status: AdAccountStatus
  readonly ads_manager_link: string
  readonly account_id: number
  readonly optimizer_counts: null | number
  readonly remaining_days_spend: string
  readonly is_archived: boolean
  readonly fb_account_status: string
  readonly website: string | null
  readonly available_for_transfer: boolean
  readonly tiktok_account_status: string
  readonly account_hints: string[]
  readonly login: string | null
  readonly autopayment: IAdAccountAutoPayment | null
  readonly profile_id: number
  readonly inn: string
  readonly kpp: string | null
  readonly has_transactions: boolean
  readonly request_status: TelegramAccountStatus
  readonly first_transaction_status: TelegramTransactionStatus | null
  readonly is_notify_closed: boolean
  readonly min_refill_amount: number | null
  readonly telegram_commission_percent: number
  readonly application_status: ApplicationRequestStatuses
  readonly facebook_business_page_name: string
  readonly is_partner_workflow: boolean
  readonly workflow_status: {
    status: FacebookPartnerStatuses
    text: string
  }
  readonly currency: AccountCurrencies
}

export interface IAdAccountObject {
  page_count: number
  count: number
  current: number
  next: number | null
  previous: number | null
  results: IAdAccount[]
}

export interface IAdAccountsState {
  facebook: IPlatformState
  archived_accounts: IPlatformState
  tiktok: IPlatformState
  telegram: IPlatformState
  pagination_object: IAdAccountObject
}

export interface ISubindustry {
  id: number
  name: string
}

export interface IIndustry {
  id: number
  name: string
  subindustries: ISubindustry[]
}

export interface IAccountBody {
  industry: string
  website: string
  business_manager_id?: string
  subindustry?: string
  advertise_system?: string
}

export interface IAdAccountUpdateBody {
  is_archived?: boolean
  client_id?: string
  is_notify_closed?: boolean
}

export interface IAccountOnboardingBody {
  setup_hint: string
  hint_status: 'done' | 'not done'
  facebook_account?: number
  tiktok_account?: number
}

export interface IAutoPaymentBody {
  is_enabled: boolean
  minimal_balance?: number
  amount?: number
  max_amount?: number
  max_amount_period?: string
  facebook_account?: number
  tiktok_account?: number
  id?: number
}

export interface IAdAccountAutoPayment {
  id: number
  is_enabled: boolean
}

export type AdAccountsPageType = number | 'all'

export interface IPlatformState {
  accounts: IAdAccount[]
  error: any
  isLoading: boolean
}
