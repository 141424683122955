import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase, fillBg = '#C4C4C4' } = props
  const defaultStyle = { width: 51, height: 50 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 24.9999L22.1667 31.6666L35.5 18.3333" stroke={fill} strokeWidth="2" />
      <circle cx="25.5" cy="25" r="24.5" stroke={fillBg} />
    </svg>
  )
}
