import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import Select, {
  components,
  MenuListProps,
  MultiValueProps,
  NoticeProps,
  OptionProps,
  Props,
  SingleValue,
  SingleValueProps,
} from 'react-select'
import cn from 'classnames'

import useGlobalStyles from '../../const/globalStyles'
import CircleLoader from '../CircleLoader'
import { FieldHelpText } from '../FieldHelpText'
import { FieldLabel } from '../FieldLabel'
import { CheckIcon } from '../Icons'
import useStyles from './styles'
import { COMBOBOX_SIZE, CustomInputAction, IComboBoxOption, IProps, SearchFieldsType } from './types'

const ComboBox = (props: IProps) => {
  const {
    placeholder,
    size = COMBOBOX_SIZE.small,
    noOptionsMessage,
    options,
    defaultMenuIsOpen = false,
    closeMenuOnSelect = true,
    isDisabled = false,
    defaultValue,
    searchFields,
    inputId,
    containerId,
    onChange,
    onMultiChange,
    loadingMessage,
    isLoading,
    getRef,
    label,
    helpText,
    errorText,
    fullWidth,
    isMultiSelect = false,
    hasCheckbox = false,
    multiValueText,
    className,
    value = '',
    multiValue = [],
    isChip = false,
    isHideSelected = false,
    isHiddenInputWrite = true,
  } = props

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const intl = useIntl()
  let ref: any = null

  const [menuIsOpen, openMenu] = useState(false)
  const [inputIsTouched, touchInput] = useState(false)

  const optionTemplate = (optionProps: OptionProps) => {
    const data = optionProps.data as IComboBoxOption

    const checkboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      optionProps.selectOption(event.target.checked)
    }

    return (
      <components.Option {...optionProps}>
        <div className={cn({ [classes.multiOptionWrapper]: isMultiSelect })}>
          <div className={classes.optionWrapper}>
            {!!data.icon && <div className={classes.optionIcon}>{data.icon}</div>}
            <div>
              <div className={classes.optionLabel}>{data.label}</div>
              {!!data.subLabel && <div className={classes.optionSubLabel}>{data.subLabel}</div>}
            </div>
          </div>
          {hasCheckbox && (
            <div className={classes.checkboxRoot}>
              <input
                type="checkbox"
                onChange={checkboxChangeHandler}
                checked={optionProps.isSelected}
                className={classes.input}
              />
              <div className={classes.inputWrapper}>
                <div
                  className={cn(classes.checkbox, {
                    [classes.checked]: optionProps.isSelected,
                  })}
                >
                  <div className={classes.checkedIcon}>
                    <CheckIcon />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </components.Option>
    )
  }

  const singleValueTemplate = (singleValueProps: SingleValueProps) => {
    const data = singleValueProps.data as IComboBoxOption

    return (
      <components.SingleValue {...singleValueProps}>
        <div className={classes.itemWrapper}>
          {!!data.icon && <div className={classes.itemIcon}>{data.icon}</div>}
          <div>
            <div className={classes.itemLabel}>{data.label}</div>
            {!!data.subLabel && <div className={classes.itemSubLabel}>{data.subLabel}</div>}
          </div>
        </div>
      </components.SingleValue>
    )
  }

  const noOptionsMessageTemplate = (noticeProps: NoticeProps) => (
    <components.NoOptionsMessage {...noticeProps}>
      {noOptionsMessage || noticeProps.children}
    </components.NoOptionsMessage>
  )

  const loadingIndicatorTemplate = () => {
    return (
      <div className={classes.loader}>
        <CircleLoader height={20} width={20} />
      </div>
    )
  }

  const menuListTemplate = useCallback(
    (menuListProps: MenuListProps) => {
      return (
        <components.MenuList {...menuListProps} className={globalClasses.scrollbar}>
          {menuListProps.children}
        </components.MenuList>
      )
    },
    [options]
  )

  const multiValueTemplate = (props: MultiValueProps) => {
    const { index, getValue } = props
    const firstElement: any = getValue()[0]
    const hidden = getValue().length > 1

    if (index === 0) {
      return (
        <div className={cn(classes.multiValueWrapper, { [classes.multiValueHidden]: hidden })}>
          {!!firstElement.icon && <div className={classes.itemIcon}>{firstElement.icon}</div>}
          <div>
            <div className={classes.itemLabel}>{firstElement.label}</div>
            {!!firstElement.subLabel && <div className={classes.itemSubLabel}>{firstElement.subLabel}</div>}
          </div>
        </div>
      )
    } else if (index === 1) {
      return (
        <div>{`${intl.formatMessage({ id: 'combobox.multiSelected' })} ${getValue().length} ${
          multiValueText || ''
        }`}</div>
      )
    } else {
      return null
    }
  }

  const loadingMessageTemplate = (noticeProps: NoticeProps) => (
    <components.LoadingMessage {...noticeProps}>{loadingMessage || noticeProps.children}</components.LoadingMessage>
  )

  const onMenuOpen = (value: boolean) => {
    return () => {
      if (value && ref?.commonProps?.hasValue && searchFields?.length) {
        const selectValue = ref.getValue()
        ref.props?.onInputChange(selectValue[0]?.[searchFields[0]] || '', { action: CustomInputAction })
      }

      if (!value) {
        touchInput(false)
      }

      openMenu(value)
    }
  }

  const onInputChange = (newValue: string, action: { action: string }) => {
    if (action.action !== CustomInputAction) {
      touchInput(true)
    }
  }

  const setRef = (selectRef: any) => {
    ref = selectRef

    if (getRef) {
      getRef(selectRef)
    }
  }

  const filterOption = (candidate: { value: string; label: string; data: IComboBoxOption }, term: string) => {
    if (term && inputIsTouched) {
      const lowerCaseTerm = term.toLowerCase()
      return searchFields?.some(
        (field: SearchFieldsType) => !!(candidate.data[field] as string)?.toLowerCase().includes(lowerCaseTerm)
      )
    }

    return true
  }

  const defaultOptions: Props = {}

  if (searchFields?.length) {
    defaultOptions.filterOption = filterOption as any
  }

  if (value) {
    defaultOptions.value = value
  }

  if (multiValue.length) {
    defaultOptions.value = multiValue
  }

  if (isHiddenInputWrite) {
    defaultOptions.controlShouldRenderValue = !menuIsOpen
  }

  return (
    <>
      {!!label && <FieldLabel className={classes.controlLabel}>{label}</FieldLabel>}
      <Select
        options={options}
        className={cn(classes.comboBoxContainer, classes[size], fullWidth && classes.fullWidth, className)}
        classNamePrefix={classes.comboBox}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: null,
          Option: optionTemplate,
          NoOptionsMessage: noOptionsMessageTemplate,
          SingleValue: singleValueTemplate,
          LoadingIndicator: loadingIndicatorTemplate,
          LoadingMessage: loadingMessageTemplate,
          MenuList: menuListTemplate,
          MultiValue: isChip ? components.MultiValue : multiValueTemplate,
        }}
        defaultMenuIsOpen={defaultMenuIsOpen}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        defaultValue={(defaultValue as SingleValue<any>) || null}
        inputId={inputId}
        id={containerId}
        onChange={isMultiSelect ? onMultiChange : onChange}
        onInputChange={onInputChange}
        isLoading={isLoading}
        isMulti={isMultiSelect}
        hideSelectedOptions={isHideSelected}
        ref={setRef}
        onMenuOpen={onMenuOpen(true)}
        onMenuClose={onMenuOpen(false)}
        {...defaultOptions}
      />
      {!!errorText && (
        <FieldHelpText isError={true} className={classes.controlHelpText}>
          {errorText}
        </FieldHelpText>
      )}
      {!!helpText && !errorText && <FieldHelpText className={classes.controlHelpText}>{helpText}</FieldHelpText>}
    </>
  )
}

export { ComboBox }
