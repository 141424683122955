import React, { ReactNode } from 'react'
import cn from 'classnames'

import useStyles from './styles'

export interface IFieldLabelProps {
  children: string | ReactNode
  htmlFor?: string
  className?: string
}

const FieldLabel = (props: IFieldLabelProps) => {
  const { children, htmlFor, className } = props

  const classes = useStyles()

  return (
    <label className={cn(classes.label, className)} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

export { FieldLabel }
