import { createSelector } from 'reselect'

import IApplicationState from '../types/state'

export const getApp = (state: IApplicationState) => state.app
export const getBrowser = (state: IApplicationState) => state.browser
export const getUser = (state: IApplicationState) => state.user
export const getPromo = (state: IApplicationState) => state.promo
export const getProfile = (state: IApplicationState) => state.user.profile
export const getReferral = (state: IApplicationState) => state.referral
export const getAudiences = (state: IApplicationState) => state.audiences
export const getPromocode = (state: IApplicationState) => state.promocode

export const profileSelector = createSelector([getProfile], (profile) => profile)

export const getAnalytics = (state: IApplicationState) => state.analytics

export const getLanguage = (state: IApplicationState) => state.user.language

export const getAccounts = (state: IApplicationState) => state.adAccounts

export const getBalance = (state: IApplicationState) => state.balance

export const getInvoices = (state: IApplicationState) => state.invoices

export const getCountry = (state: IApplicationState) => state.user.country

export const getAddTelegram = (state: IApplicationState) => state.addTelegram

export const getBudget = (state: IApplicationState) => state.budget

export const getFacebookPartner = (state: IApplicationState) => state.facebookPartner
