import { AccountCurrencies } from '@/store/adaccounts/types'
import { UserWorkflow } from '@/store/user/types'

export enum BudgetActionTypes {
  SET_ALL_BALANCES = 'SET_ALL_BALANCES',
}

export interface IBalance {
  readonly id: string
  readonly amount?: number
  readonly name: string
  readonly workflow?: UserWorkflow
  readonly currency?: AccountCurrencies
}

export type IBalancesState = IBalance[]

export interface IChargeInfo {
  commission_percent: ICommissionPercent[]
  commission_text: ICommissionText[]
  amount_helptext: string
  amount_helptext_extended: string
  conversion_rate: number
}

export interface ICommissionText {
  text: string
  title: string
}

export interface ICommissionPercent {
  type: 'commission' | 'tax'
  value: number
}

export enum PlatformStatusChecks {
  facebook = 'facebook',
  tiktok = 'tiktok',
}

export interface IStatusCheck {
  status: {
    is_active: boolean
    status_name: string
  }
}
