import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2244_250153)">
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#FAFAFA" stroke="#C4C4C4" />
        <path
          d="M12.8883 15.0661C13.0971 15.0661 13.3005 15.0499 13.4985 15.0175C13.6965 14.9851 13.8856 14.9401 14.0656 14.8825L14.1736 15.7466C13.972 15.797 13.7613 15.8366 13.5417 15.8654C13.3221 15.8942 13.1007 15.9086 12.8775 15.9086C12.4491 15.9086 12.0566 15.8438 11.7002 15.7142C11.3438 15.581 11.0359 15.3847 10.7767 15.1255C10.5175 14.8627 10.3159 14.5369 10.1719 14.148C10.0315 13.7556 9.96127 13.2984 9.96127 12.7763V10.978C9.96127 10.4524 10.0315 9.99335 10.1719 9.60092C10.3123 9.20849 10.5121 8.88087 10.7713 8.61805C11.0305 8.35523 11.3366 8.15901 11.6894 8.0294C12.0458 7.89619 12.44 7.82959 12.8721 7.82959C13.0989 7.82959 13.3167 7.84579 13.5255 7.87819C13.7379 7.907 13.954 7.9466 14.1736 7.997L14.0656 8.86646C13.8892 8.80886 13.7001 8.76386 13.4985 8.73146C13.2969 8.69545 13.0917 8.67745 12.8829 8.67745C12.5949 8.67745 12.3338 8.72425 12.0998 8.81786C11.8694 8.91147 11.6714 9.05188 11.5058 9.23909C11.3438 9.42631 11.2196 9.66392 11.1332 9.95194C11.0467 10.24 11.0035 10.5784 11.0035 10.9672V12.7763C11.0035 13.1652 11.0467 13.5036 11.1332 13.7916C11.2232 14.0796 11.351 14.3173 11.5166 14.5045C11.6822 14.6917 11.8802 14.8321 12.1106 14.9257C12.3446 15.0193 12.6039 15.0661 12.8883 15.0661ZM13.0773 10.7944V11.4695H9V10.7944H13.0773ZM13.0773 12.2039V12.8736H9V12.2039H13.0773Z"
          fill="#2E2E2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2244_250153">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
