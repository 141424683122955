import { createUseStyles } from 'react-jss'

import { TextGreyBase } from 'src/const/colors'

import { FontBody1, FontH4 } from '../../../const/fontStyles'

export default createUseStyles({
  bgImage: {
    width: '100%',
  },
  header: {
    marginBottom: 24,
  },
  title: {
    ...FontH4,
    margin: '0 0 24px',
    color: TextGreyBase,
  },
  description: {
    ...FontBody1,
    color: TextGreyBase,
  },
})
