import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = PurpleBase } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5199_5004)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 31.0463 31.0463 40 20 40C8.95375 40 0 31.0463 0 20C0 8.95375 8.95375 0 20 0C31.0463 0 40 8.95375 40 20ZM17.9315 25.8323C19.0131 26.4801 20.2547 26.8221 21.5204 26.821C22.8619 26.821 24.0969 26.4537 25.1509 25.8031C25.6833 25.4673 26.3753 25.5197 26.8225 25.9605C27.44 26.5691 27.3016 27.5765 26.5669 28.0278C25.0977 28.9302 23.3729 29.4444 21.5204 29.4444C17.3468 29.4444 13.8121 26.8105 12.4919 23.1481H9.40437C8.60586 23.1481 8.09482 22.3296 8.45681 21.6265C8.62716 21.2697 8.99979 21.0494 9.40437 21.0494H12.0022C11.916 20.3524 11.916 19.6476 12.0022 18.9506H9.40437C8.60586 18.9506 8.09482 18.1321 8.44616 17.429C8.62716 17.0722 8.99979 16.8518 9.40437 16.8518H12.4919C13.8121 13.1895 17.3468 10.5555 21.5204 10.5555C23.3033 10.5576 25.0505 11.0481 26.5669 11.9722C27.3122 12.4234 27.4506 13.4204 26.8331 14.029C26.3859 14.4697 25.6939 14.5222 25.1616 14.1969C24.0969 13.5568 22.8512 13.179 21.5204 13.179C20.255 13.1792 19.0139 13.5217 17.9325 14.1694C16.8512 14.8171 15.9709 15.7449 15.3878 16.8518H20.8603C21.0424 16.8506 21.2218 16.8958 21.3811 16.9829C21.5404 17.07 21.6741 17.1962 21.7694 17.3492C21.8647 17.5023 21.9183 17.6769 21.9251 17.8564C21.9318 18.0358 21.8914 18.2139 21.8078 18.3734C21.6375 18.7302 21.2649 18.9506 20.8603 18.9506H14.6852C14.6319 19.2969 14.6 19.6432 14.6 20C14.6 20.3568 14.6319 20.7031 14.6852 21.0494H20.8603C21.6481 21.0494 22.1592 21.8784 21.8078 22.571C21.6375 22.9278 21.2649 23.1481 20.8603 23.1481H15.3878C15.9699 24.2559 16.8499 25.1845 17.9315 25.8323Z"
          fill={fillBg}
        />
      </g>
      <defs>
        <clipPath id="clip0_5199_5004">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
