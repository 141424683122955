import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1569 15.8612C13.877 15.8612 12.8216 16.8941 12.8216 18.1965C12.8216 19.4764 13.8545 20.5318 15.1569 20.5318C16.4368 20.5318 17.4922 19.4989 17.4922 18.1965C17.4697 16.9166 16.4368 15.8612 15.1569 15.8612Z"
        fill={fill}
      />
      <path
        d="M19.2661 4.72371C19.2212 4.72371 19.1539 4.70125 19.0865 4.70125H4.94004L4.71549 3.19679C4.58076 2.20878 3.72748 1.46777 2.71702 1.46777H0.898188C0.404185 1.46777 0 1.87196 0 2.36596C0 2.85997 0.404185 3.26415 0.898188 3.26415H2.71702C2.82929 3.26415 2.91911 3.35397 2.94157 3.46624L4.33376 12.9421C4.5134 14.1322 5.54631 15.0304 6.75887 15.0304H16.1C17.2677 15.0304 18.2781 14.1996 18.5251 13.0544L19.9847 5.75662C20.0745 5.28507 19.7601 4.81353 19.2661 4.72371Z"
        fill={fill}
      />
      <path
        d="M9.4311 18.0842C9.38619 16.8492 8.35327 15.8612 7.11826 15.8612C5.81589 15.9286 4.82788 17.0064 4.87279 18.2863C4.9177 19.5213 5.92816 20.5094 7.16317 20.5094H7.20808C8.488 20.442 9.49846 19.3642 9.4311 18.0842Z"
        fill={fill}
      />
    </svg>
  )
}
