import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '@/const'
import { ThunkResult } from '@/store/store'
import { customAxios } from '@/utils'

export enum BalanceActionTypes {
  SET_BALANCE_EXCHANGE_RATE = 'SET_BALANCE_EXCHANGE_RATE',
}

export function getExchangeRate(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/exchange_rates/`, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: BalanceActionTypes.SET_BALANCE_EXCHANGE_RATE,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
