import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'

import { API_URL } from '../../../const'
import { AppActionTypes } from '../../../store/app/types'
import { MyThunkDispatch, ThunkResult } from '../../../store/store'
import { updateProfile, updateUserData } from '../../../store/user'
import { CloseDocType, PaymentMethod, ProfileType, UserProfileStatuses } from '../../../store/user/types'
import { customAxios } from '../../../utils'
import { IBankDetailsSet, IBusinessInfoSet } from './types'

export function setBankDetails(data: IBankDetailsSet): AxiosPromise<any> {
  return customAxios(`${API_URL}/profile/bank_details/`, 'POST', data, true)
    .then((response: AxiosResponse<any>): any => {
      return response.data
    })
    .catch((error: AxiosError) => Promise.reject(error))
}
export function setBusinessInfo(data: IBusinessInfoSet): AxiosPromise<any> {
  return customAxios(`${API_URL}/profile/business_info/`, 'POST', data, true)
    .then((response: AxiosResponse<any>): any => {
      return response.data
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function setBusinessProfile(
  payment_method: PaymentMethod,
  dataBusinessInfo: IBusinessInfoSet,
  dataBankDetails: IBankDetailsSet | null,
  isCurrentClient: boolean
): ThunkResult<Promise<any>> {
  return (dispatch: MyThunkDispatch) => {
    return setBusinessInfo(dataBusinessInfo)
      .then((response: AxiosResponse<any>) => {
        if (dataBankDetails) {
          setBankDetails(dataBankDetails).then((response: AxiosResponse<any>) => {
            dispatch(updateProfile({ payment_method, closedoc_type: CloseDocType.spend }))
            dispatch({ type: AppActionTypes.SET_BUSINESS_PROFILE })
            return response
          })
          return
        }
        dispatch(
          updateProfile(
            isCurrentClient
              ? { payment_method, profile_type: ProfileType.BUSINESS, legal_status: UserProfileStatuses.APPROVED }
              : { payment_method }
          )
        )
        dispatch(updateUserData({ country: dataBusinessInfo.business_country }))
        if (isCurrentClient) {
          dispatch({ type: AppActionTypes.SET_CURRENT_BUSINESS_PROFILE })
        } else {
          dispatch({ type: AppActionTypes.SET_BUSINESS_PROFILE })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
