export const bankDetailsInitialState = {
  bank: { hasError: false, touched: false, errorText: '', required: true, value: '' },
  bank_address: { hasError: false, touched: false, errorText: '', required: false, value: '' },
  account_number_usd: { hasError: false, touched: false, errorText: '', required: true, value: '' },
  sort_code: { hasError: false, touched: false, errorText: '', required: false, value: '' },
  swift: { hasError: false, touched: false, errorText: '', required: true, value: '' },
}

export const getBusinessInfoInitialState = ({
  isProformaMethod,
  isCountryRequired = false,
  isPaysToAz = false,
}: {
  isProformaMethod: boolean
  isCountryRequired: boolean
  isPaysToAz: boolean
}) => ({
  business_name: {
    hasError: false,
    touched: false,
    errorText: '',
    required: true,
    value: '',
  },
  business_address: {
    hasError: false,
    touched: false,
    errorText: '',
    required: true,
    value: '',
  },
  business_ceo: {
    hasError: false,
    touched: false,
    errorText: '',
    required: isProformaMethod && !isPaysToAz,
    value: '',
  },
  business_phone: {
    hasError: false,
    touched: false,
    errorText: '',
    required: isProformaMethod,
    value: '',
  },
  vat_registration_number: {
    hasError: false,
    touched: false,
    errorText: '',
    required: isPaysToAz,
    value: '',
  },
  business_country: {
    hasError: false,
    touched: false,
    errorText: '',
    required: isCountryRequired,
    value: '',
  },
})
