import { createUseStyles } from 'react-jss'

import { WidthBPs } from '../../../../../const/commonStyles'

export default createUseStyles(
  {
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: 20,
      marginBottom: 32,

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        marginBottom: 40,
      },
    },
    label: {
      marginBottom: 4,
      display: 'flex !important',
    },
    helperText: {
      marginTop: 4,
    },
    requiredIcon: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 2,
    },
    formGroup: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: 20,

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        gridTemplateColumns: '1fr 1fr',
        gridGap: '20px 40px',
      },
    },
  },
  { name: 'BankDetails' }
)
