import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { FormattedMessage, useIntl } from 'react-intl'
import { CountryData } from 'react-phone-input-2'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import cn from 'classnames'

import { IModalProps } from '../AppModals'
import { setBusinessProfile } from './actions'
import BankDetails from './components/BankDetails'
import BusinessInfo from './components/BusinessInfo'
import IndividualInfo from './components/IndividualInfo'
import JurisdictionInfo from './components/JurisdictionInfo'
import PaymentMethodCards from './components/PaymentMethodCards'
import { bankDetailsInitialState, getBusinessInfoInitialState } from './helpers'
import selector from './selector'
import useStyles from './styles'
import { BankDetailsFields, BusinessInfoFields, IBankDetailsForm, IBusinessInfoForm } from './types'

import { Mixpanel } from '@/analytics'
import { isPhoneNumberValid } from '@/common/utils'
import { Button, BUTTON_FILL_TYPE, BUTTON_SIZE, BUTTON_TYPE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { IComboBoxOption } from '@/components/ComboBox'
import { FieldHelpText } from '@/components/FieldHelpText'
import { ArrowLeftIcon, InfoIcon, PopupCloseIcon, WarningIcon } from '@/components/Icons'
import { Modal } from '@/components/ModalSMB'
import { Toast, TOAST_VARIANT } from '@/components/ToastSMB'
import { GLOBAL_COUNTRIES } from '@/const'
import { White } from '@/const/colors'
import { closeAppModal } from '@/store/app'
import { setUserPlugin, updateProfile, updateUserData } from '@/store/user'
import { PaymentMethod, ProfileType, UserProfileStatuses } from '@/store/user/types'

type IStepState = 1 | 2 | 3

const BusinessProfileModal = (props: IModalProps) => {
  const { onClose } = props
  const location = useLocation()
  const currentPage = useRef({ page: location.pathname })

  const intl = useIntl()
  const {
    browserMedia,
    hasSuccessBusinessProfile,
    country,
    isPayingCustomer,
    paymentMethod,
    profileType,
    countries,
    isCountriesLoading,
    isCountryConfirm,
    isCountryReady,
    hasSuccessCurrentBusinessProfile,
    isCanChangeProfileType,
  } = useSelector(selector)
  const dispatch = useDispatch()
  const classes = useStyles()

  const isRuClient = country === GLOBAL_COUNTRIES.RU
  const isAzClient = country === GLOBAL_COUNTRIES.AZ

  const isCurrentClient = profileType === ProfileType.INDIVIDUAL && isPayingCustomer
  const initStep = isCurrentClient || isCanChangeProfileType || isAzClient ? 2 : 1
  const initPaymentMethod = isCurrentClient || isCanChangeProfileType ? paymentMethod : null

  const [step, setStep] = useState<IStepState>(initStep)
  const [finished, setFinished] = useState(false)
  const [isErrorStep, setIsErrorStep] = useState(false)
  const [cardType, setCardType] = useState<PaymentMethod | null>(initPaymentMethod)
  const [bankDetailsForm, setBankDetailsForm] = useState(bankDetailsInitialState)
  const [businessInfoForm, setBusinessInfoForm] = useState(
    getBusinessInfoInitialState({
      isProformaMethod: false,
      isCountryRequired: isCurrentClient && !isRuClient && !isCountryConfirm,
      isPaysToAz: isAzClient,
    })
  )
  const [isSkippedDetails, skipDetails] = useState(false)
  const [isCountryConfirmed, confirmCountry] = useState(!isRuClient)

  const headerRef = useRef<null | HTMLDivElement>(null)
  const isSkippableStep = step === 2 && cardType === PaymentMethod.CARD

  const isShowCloseButton = isCountryConfirmed && !(!isCountryReady && isCurrentClient)

  useEffect(() => {
    if (currentPage.current.page && currentPage.current.page !== location.pathname) {
      dispatch(closeAppModal())
    }
  }, [location])

  useEffect(() => {
    if (headerRef?.current?.scrollIntoView) {
      headerRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const isError = errorChecked()
    if (isError) {
      setIsErrorStep(true)
    }

    if (cardType === PaymentMethod.CARD && step === 2) {
      setFinished(true)
      return
    }

    if (step === 3) {
      setFinished(true)
    }

    if (isCanChangeProfileType) {
      setFinished(true)
    }

    if (isAzClient) {
      setFinished(true)
      setCardType(PaymentMethod.PROFORMA)
    }
  }, [step])

  useEffect(() => {
    if (hasSuccessBusinessProfile) {
      toast.custom(saveToast)
      dispatch(closeAppModal())
    }

    if (hasSuccessCurrentBusinessProfile) {
      toast.custom(saveCurrentClientToast)
      dispatch(closeAppModal())
    }
  }, [hasSuccessBusinessProfile, hasSuccessCurrentBusinessProfile])

  const saveToast = (t: any) => (
    <Toast
      content={<FormattedMessage id="setting.profileInfo.successToast" />}
      onClose={() => toast.dismiss(t.id)}
      variant={TOAST_VARIANT.success}
    />
  )

  const saveCurrentClientToast = (t: any) => (
    <Toast
      content={<FormattedMessage id="detailsPayment.modal.currentClientToast" values={{ br: <br /> }} />}
      onClose={() => toast.dismiss(t.id)}
      variant={TOAST_VARIANT.success}
    />
  )

  const stepContent = {
    1: {
      title: intl.formatMessage({ id: 'detailsPayment.modal.stepPaymentMethod' }),
      stepText: intl.formatMessage({ id: 'detailsPayment.modal.Stepone' }),
    },
    2: {
      title: intl.formatMessage({ id: 'detailsPayment.modal.stepFillCompanyCard' }),
      stepText:
        cardType === PaymentMethod.CARD
          ? intl.formatMessage({ id: 'detailsPayment.modal.nextStep' }, { current: 2, end: 2 })
          : intl.formatMessage({ id: 'detailsPayment.modal.nextStep' }, { current: 2, end: 3 }),
    },
    3: {
      title: intl.formatMessage({ id: 'detailsPayment.modal.stepFillBankDetails' }),
      stepText: intl.formatMessage({ id: 'detailsPayment.modal.nextStep' }, { current: 3, end: 3 }),
    },
  }

  const errorChecked = () => {
    switch (step) {
      case 2: {
        const hasError = Object.values(businessInfoForm).some((item) => item.hasError)
        return hasError
      }
      case 3: {
        const hasError = Object.values(bankDetailsForm).some((item) => item.hasError)
        return hasError
      }
      default:
        return false
    }
  }

  const validationChecked = () => {
    switch (step) {
      case 2: {
        const businessInfoFormChecked = Object.keys(businessInfoForm).reduce((accum, currentValue) => {
          return {
            ...accum,
            [currentValue]: {
              ...businessInfoForm[currentValue as BusinessInfoFields],
              hasError:
                businessInfoForm[currentValue as BusinessInfoFields].required &&
                !businessInfoForm[currentValue as BusinessInfoFields].value,
            },
          }
        }, {} as IBusinessInfoForm)
        const hasError = Object.values(businessInfoFormChecked).some((item) => item.hasError)
        setBusinessInfoForm(businessInfoFormChecked)

        return hasError
      }
      case 3: {
        const bankDetailsFormChecked = Object.keys(bankDetailsForm).reduce((accum, currentValue) => {
          return {
            ...accum,
            [currentValue]: {
              ...bankDetailsForm[currentValue as BankDetailsFields],
              hasError:
                bankDetailsForm[currentValue as BankDetailsFields].required &&
                !bankDetailsForm[currentValue as BankDetailsFields].value,
            },
          }
        }, {} as IBankDetailsForm)
        const hasError = Object.values(bankDetailsFormChecked).some((item) => item.hasError)

        setBankDetailsForm(bankDetailsFormChecked)

        return hasError
      }
      default:
        return false
    }
  }

  const changeHandlerCardType = useCallback(
    (type: PaymentMethod) => {
      return () => {
        setCardType(type)
        setBusinessInfoForm(
          getBusinessInfoInitialState({
            isProformaMethod: type === PaymentMethod.PROFORMA,
            isCountryRequired: isCurrentClient && !isRuClient && !isCountryConfirm,
            isPaysToAz: isAzClient,
          })
        )
      }
    },
    [cardType]
  )

  const blurHandlerBusinessInfo = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      const formItem = businessInfoForm[name as BusinessInfoFields]

      setBusinessInfoForm((prevState) => ({
        ...prevState,
        [name]: {
          ...formItem,
          touched: true,
          hasError: formItem.required && !value,
        },
      }))
    },
    [businessInfoForm]
  )
  const blurHandlerBankDetail = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      const formItem = bankDetailsForm[name as BankDetailsFields]

      setBankDetailsForm((prevState) => ({
        ...prevState,
        [name]: {
          ...formItem,
          touched: true,
          hasError: formItem.required && !value,
        },
      }))
    },
    [bankDetailsForm]
  )

  const inputChangeHandlerBankDetails = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target
      const formItem = bankDetailsForm[name as BankDetailsFields]

      setBankDetailsForm((prevState) => ({
        ...prevState,
        [name]: {
          ...formItem,
          value,
          touched: true,
          hasError: formItem.required && formItem.touched && !value,
        },
      }))
    },
    [bankDetailsForm]
  )

  const inputChangeHandlerBusinessInfo = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target
      const formItem = businessInfoForm[name as BusinessInfoFields]

      setBusinessInfoForm((prevState) => ({
        ...prevState,
        [name]: {
          ...formItem,
          value,
          touched: true,
          hasError: formItem.required && formItem.touched && !value,
        },
      }))
    },
    [businessInfoForm]
  )

  const onPhoneInputChange = (value: string, country: CountryData): void => {
    setBusinessInfoForm((prevState) => ({
      ...prevState,
      business_phone: {
        ...prevState.business_phone,
        hasError: prevState.business_phone.touched ? !isPhoneNumberValid(value, country) : false,
        value,
      },
    }))
  }

  const onPhoneInputBlur = (event: React.FocusEvent<HTMLInputElement>, country: CountryData): void => {
    setBusinessInfoForm((prevState) => ({
      ...prevState,
      business_phone: {
        ...prevState.business_phone,
        hasError: !isPhoneNumberValid(prevState.business_phone.value, country),
        touched: true,
      },
    }))
  }

  const onCountryInputChange = (value: IComboBoxOption): void => {
    setBusinessInfoForm((prevState) => ({
      ...prevState,
      business_country: {
        ...prevState.business_country,
        hasError: !value.value,
        touched: true,
        value: value.value,
      },
    }))
  }

  const cancelHandler = useCallback(() => {
    onClose()
  }, [onClose])

  const stepNextHandler = () => {
    const isError = validationChecked()

    Mixpanel.track(`RequisitesStep${step}`)

    if (!isError) {
      const nextStep = step + 1
      setStep(nextStep as IStepState)
      setIsErrorStep(false)

      return
    }
    setIsErrorStep(true)
  }

  const stepPrev = () => {
    const prevStep = step - 1
    setStep(prevStep as IStepState)
    setFinished(false)
    setIsErrorStep(false)
  }

  const skipDetailsHandler = useCallback(
    (value: boolean) => {
      return () => {
        skipDetails(value)
      }
    },
    [isSkippedDetails]
  )

  const submitHandler = useCallback(async () => {
    Mixpanel.track('RequisitesSave')

    const isError = validationChecked()

    if (isError || !cardType) {
      setIsErrorStep(true)
      return
    }

    setIsErrorStep(false)

    const { business_name, business_address, business_ceo, business_phone, vat_registration_number, business_country } =
      businessInfoForm

    const businessInfoFormData = {
      business_name: business_name.value,
      business_country: business_country.value || country,
      business_address: business_address.value,
      business_ceo: business_ceo.value,
      business_phone: business_phone.value,
      vat_registration_number: vat_registration_number.value,
    }

    const { bank, bank_address, account_number_usd, sort_code, swift } = bankDetailsForm

    const bankDetailsFormData = {
      bank: bank.value,
      bank_address: bank_address.value,
      account_number_usd: account_number_usd.value,
      sort_code: sort_code.value,
      swift: swift.value,
    }

    if (step === 2) {
      await dispatch(setBusinessProfile(cardType, businessInfoFormData, null, isCurrentClient))
      Mixpanel.track('RequisitesSuccess')

      return
    }

    await dispatch(setBusinessProfile(cardType, businessInfoFormData, bankDetailsFormData, isCurrentClient))

    Mixpanel.track('RequisitesSuccess')
  }, [step, businessInfoForm, bankDetailsForm])

  const confirmCountryHandler = useCallback(async () => {
    const { business_country } = businessInfoForm

    if (business_country.value === GLOBAL_COUNTRIES.RU) {
      await dispatch(updateProfile({ legal_status: UserProfileStatuses.REJECTED }))
      await dispatch(setUserPlugin('country_is_ready'))
      dispatch(closeAppModal())
    } else {
      await dispatch(
        updateUserData({
          country: business_country.value,
        })
      )
      await dispatch(setUserPlugin('is_country_confirmed'))
    }

    confirmCountry(true)
  }, [businessInfoForm])

  return (
    <Modal
      isOpen
      closeCallback={cancelHandler}
      headerContent={
        <div ref={headerRef}>
          {isCanChangeProfileType
            ? intl.formatMessage({ id: 'settings.root.modal.businessInfoIndividualTitle' })
            : intl.formatMessage({
                id: isCurrentClient ? 'detailsPayment.modal.companyTitle' : 'detailsPayment.modal.title',
              })}
        </div>
      }
      bodyContent={
        isSkippedDetails ? (
          <IndividualInfo onBack={skipDetailsHandler(false)} />
        ) : (
          <div className={classes.root}>
            {!isCurrentClient && !isCanChangeProfileType && !isAzClient && (
              <div className={classes.stepWrapper}>
                <div className={classes.step}>{stepContent[step].stepText}</div>
                <h4 className={classes.title}>{stepContent[step].title}</h4>
              </div>
            )}
            {step === 2 && !isCurrentClient && (
              <div className={classes.alert}>
                <div className={classes.alertIcon}>
                  <InfoIcon style={{ height: 16, width: 16 }} fill={White} fillBg={'transparent'} />
                </div>
                {isCanChangeProfileType || isAzClient ? (
                  <>
                    <FormattedMessage id="settings.root.modal.businessInfoIndividualAlert1" /> <br />
                    <FormattedMessage id="settings.root.modal.businessInfoIndividualAlert2" />
                  </>
                ) : (
                  <FormattedMessage
                    id={
                      cardType === PaymentMethod.CARD
                        ? 'detailsPayment.modal.alertCard'
                        : 'detailsPayment.modal.alertProforma'
                    }
                  />
                )}
              </div>
            )}
            <div>
              {step === 1 && <PaymentMethodCards changeHandler={changeHandlerCardType} cardType={cardType} />}
              {step === 2 && (!isRuClient || isCountryConfirmed) && (
                <BusinessInfo
                  businessInfoForm={businessInfoForm}
                  changeHandler={inputChangeHandlerBusinessInfo}
                  blurHandler={blurHandlerBusinessInfo}
                  onPhoneInputChange={onPhoneInputChange}
                  onPhoneInputBlur={onPhoneInputBlur}
                  country={country}
                  cardType={cardType}
                  isCurrentClient={isCurrentClient}
                  isRuClient={isRuClient}
                  countries={countries}
                  isCountriesLoading={isCountriesLoading}
                  onCountryChange={onCountryInputChange}
                  isCountryConfirm={isCountryConfirm}
                  isVatRequired={isAzClient}
                />
              )}
              {step === 2 && isRuClient && !isCountryConfirmed && (
                <JurisdictionInfo
                  countries={countries}
                  isCountriesLoading={isCountriesLoading}
                  onCountryChange={onCountryInputChange}
                  country={businessInfoForm.business_country}
                />
              )}
              {step === 3 && (
                <BankDetails
                  bankDetailsForm={bankDetailsForm}
                  changeHandler={inputChangeHandlerBankDetails}
                  blurHandler={blurHandlerBankDetail}
                />
              )}
            </div>
            <div className={classes.footer}>
              <FieldHelpText isError={true}>
                {isErrorStep && step !== 1 && (
                  <div className={classes.error}>
                    <WarningIcon /> <FormattedMessage id="detailsPayment.modal.formErrorText" />
                  </div>
                )}
              </FieldHelpText>
              <div
                className={cn({
                  [classes.buttonGroup]: isCountryConfirmed,
                  [classes.buttonTwoColumnsGrid]:
                    !browserMedia.small &&
                    isSkippableStep &&
                    isCountryConfirmed &&
                    !isCurrentClient &&
                    !isCanChangeProfileType,
                  [classes.buttonSingleColumnGrid]:
                    !browserMedia.small && isSkippableStep && isCountryConfirmed && isCurrentClient,
                  [classes.buttonFlexGroup]: !isCountryConfirmed,
                })}
              >
                {step > 1 && !isCurrentClient && !isCanChangeProfileType && !isAzClient && (
                  <Button
                    onClick={stepPrev}
                    variant={BUTTON_VARIANT.iconSecondaryGray}
                    size={BUTTON_SIZE.size40}
                    fillIconType={BUTTON_FILL_TYPE.fill}
                    iconLeft={<ArrowLeftIcon />}
                    type={BUTTON_TYPE.icon}
                  />
                )}

                {step === 1 && (
                  <Button
                    label={intl.formatMessage({ id: 'common.cancel' })}
                    variant={BUTTON_VARIANT.secondaryPurple}
                    size={BUTTON_SIZE.size40}
                    onClick={cancelHandler}
                    minWidth={101}
                  />
                )}

                {(isCanChangeProfileType || isAzClient) && (
                  <Button
                    label={intl.formatMessage({ id: 'common.cancel' })}
                    variant={BUTTON_VARIANT.secondaryPurple}
                    size={BUTTON_SIZE.size40}
                    onClick={cancelHandler}
                  />
                )}

                {isSkippableStep && isCountryConfirmed && !isCanChangeProfileType && (
                  <Button
                    onClick={skipDetailsHandler(true)}
                    label={intl.formatMessage({ id: 'detailsPayment.modal.skipButton' })}
                    variant={BUTTON_VARIANT.iconSecondaryGray}
                    size={BUTTON_SIZE.size40}
                    className={classes.skipButton}
                  />
                )}

                {!isCountryConfirmed && (
                  <Button
                    label={intl.formatMessage({
                      id:
                        businessInfoForm.business_country.value === GLOBAL_COUNTRIES.RU
                          ? 'detailsPayment.modal.confirm'
                          : 'detailsPayment.modal.next',
                    })}
                    size={BUTTON_SIZE.size40}
                    onClick={confirmCountryHandler}
                    disabled={!businessInfoForm.business_country.value}
                  />
                )}

                {finished && isCountryConfirmed && (
                  <Button
                    label={intl.formatMessage({ id: 'common.save' })}
                    size={BUTTON_SIZE.size40}
                    onClick={submitHandler}
                    disabled={businessInfoForm.business_country.value === GLOBAL_COUNTRIES.RU}
                  />
                )}

                {!finished && isCountryConfirmed && (
                  <Button
                    label={intl.formatMessage({ id: 'detailsPayment.modal.buttonNextStep' })}
                    size={BUTTON_SIZE.size40}
                    onClick={stepNextHandler}
                    disabled={!cardType}
                  />
                )}
              </div>
            </div>
          </div>
        )
      }
      closeButton={
        isShowCloseButton ? (
          browserMedia.small ? (
            <Button
              onClick={cancelHandler}
              variant={BUTTON_VARIANT.tertiary}
              size={BUTTON_SIZE.icon32}
              fillIconType={BUTTON_FILL_TYPE.stroke}
              iconLeft={<PopupCloseIcon />}
              className={classes.closeButton}
            />
          ) : (
            <Button
              onClick={cancelHandler}
              variant={BUTTON_VARIANT.onGreyBackground}
              size={BUTTON_SIZE.size40}
              fillIconType={BUTTON_FILL_TYPE.stroke}
              iconLeft={<PopupCloseIcon />}
              className={classes.closeButton}
              type={BUTTON_TYPE.icon}
            />
          )
        ) : null
      }
      maxWidth="688px"
      fullHeight
      hasCloseButton={isShowCloseButton || isCanChangeProfileType}
    />
  )
}

export default BusinessProfileModal
