import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CampaignStatusTypes, ISelectItem } from '../../../../store/audiences/types'

export const CAMPAIGN_FILTERS: ISelectItem[] = [
  {
    key: '',
    name: <FormattedMessage id="audiences.campaignFilter.consts.all" />,
  },
  {
    key: CampaignStatusTypes.WITH_ISSUES,
    name: <FormattedMessage id="audiences.campaignFilter.consts.rejected" />,
  },
  {
    key: CampaignStatusTypes.PAUSED,
    name: <FormattedMessage id="audiences.campaignFilter.consts.draft" />,
  },
  {
    key: CampaignStatusTypes.IN_PROCESS,
    name: <FormattedMessage id="audiences.campaignFilter.consts.inProcess" />,
  },
  {
    key: CampaignStatusTypes.ACTIVE,
    name: <FormattedMessage id="audiences.campaignFilter.consts.active" />,
  },
  {
    key: CampaignStatusTypes.LEARNING_PHASE,
    name: <FormattedMessage id="audiences.campaignFilter.consts.learning" />,
  },
]
