import React from 'react'

import { SlateGreyLighten22, SlateGreyLighten49 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22, fillBg = SlateGreyLighten49 } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18" height="18" rx="9" fill={fillBg} />
      <path
        d="M8.53107 4.26725C8.69215 3.83194 9.30785 3.83194 9.46893 4.26725L10.5408 7.16383C10.5914 7.30069 10.6993 7.4086 10.8362 7.45924L13.7327 8.53107C14.1681 8.69215 14.1681 9.30785 13.7327 9.46893L10.8362 10.5408C10.6993 10.5914 10.5914 10.6993 10.5408 10.8362L9.46893 13.7327C9.30785 14.1681 8.69215 14.1681 8.53107 13.7327L7.45924 10.8362C7.4086 10.6993 7.30069 10.5914 7.16383 10.5408L4.26725 9.46893C3.83194 9.30785 3.83194 8.69215 4.26725 8.53107L7.16383 7.45924C7.30069 7.4086 7.4086 7.30069 7.45924 7.16383L8.53107 4.26725Z"
        fill={fill}
      />
    </svg>
  )
}
